import React, { useEffect, useState } from "react";
import { Plugins, Capacitor } from "@capacitor/core";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Pages variables */
import { Login } from "./Login";
import { LandingScreens } from "./LandingScreens/LandingScreens";
import FM from "./users/facilityManager";
import AM from "./users/approvalManager";
import FA from "./users/facilityAdministrator";
import Client from "./users/client";
import MGMT from "./users/management";

import { get, set } from "./services/storage";
import { setupConfig } from "@ionic/react";
import {
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from "@capacitor/core";
import { setInterval } from "timers";
import AFM from "./users/assistantFacilityManger";
import { ForgetPassword } from "./Auth/forgetPassword";
import { ChangePassword } from "./Auth/changePassword";
import './App.css';

const { Network } = Plugins;
const { PushNotifications } = Plugins;


const App: React.FC = () => {
  // const history = useHistory();
  setupConfig({
    hardwareBackButton: false,
    rippleEffect: false,
    mode: "md",
  });

  const [status, setStatus] = useState<any>(true);

  useEffect(() => {
    const checker = () => {
      Network.getStatus().then((res) => {
        if (!res.connected) {
          alert("No internet, try again...?");
        }
      });
    };
    setInterval(checker, 200);
  });

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        if (
          window.location.pathname === "/FM/Home" ||
          window.location.pathname === "/AM/Home" ||
          window.location.pathname === "/CL/Home" ||
          window.location.pathname === "/Client/Home" ||
          window.location.pathname === "/Login" ||
          window.location.pathname === "/login" ||
          window.location.pathname === "/FA/Home" ||
          window.location.pathname === "/MGMT/Home" ||
          window.location.pathname === "/AFM/Home"
        ) {
          // Show A Confirm Box For User to exit app or not
          Plugins.App.exitApp();
        }
      });

      // Register Notifications
      // PushNotifications.register();

      // // On success storing user_token in localstorage
      // PushNotifications.addListener(
      //   "registration",
      //   (token: PushNotificationToken) => {
      //     set("notificationToken", token);
      //   }
      // );

      // // Some issue with your setup and push will not work
      // PushNotifications.addListener("registrationError", (error: any) => {
      //   alert("Error on registration: " + JSON.stringify(error));
      // });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotification) => {
          alert(notification.body);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: PushNotificationActionPerformed) => {
          alert("There has been an update.");
        }
      );
    }
  }, []);

  return (
    <IonApp className={Capacitor.getPlatform() === 'ios' ? "ios-safeArea" : ""}>
      <IonReactRouter>
        <IonRouterOutlet>
            <Switch>
              <Route path="/login" component={Login} exact />
              <Route path="/ForgetPassword" component={ForgetPassword} exact />
              <Route path="/ChangePassword" component={ChangePassword} exact />
              <Route path="/AM/Home" component={AM} exact />
              <Route path="/FM/Home" component={FM} exact />
              <Route path="/AFM/Home" component={AFM} exact />
              <Route path="/FA/Home" component={FA} exact />
              <Route path="/CL/Home" component={Client} exact />
              <Route path="/MGMT/Home" component={MGMT} exact />
              <Route path="/" component={LandingScreens} />
            </Switch>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
