import { useState, useEffect } from "react";
import { useCamera } from "@ionic/react-hooks/camera";
import { useFilesystem, base64FromPath } from "@ionic/react-hooks/filesystem";
import { useStorage } from "@ionic/react-hooks/storage";
import { isPlatform } from "@ionic/react";

import {
  CameraResultType,
  CameraSource,
  CameraPhoto,
  Capacitor,
  FilesystemDirectory,
} from "@capacitor/core";
export interface Photo {
  filepath: string;
  webviewPath?: string;
  fileData: any;
}

export function usePhotoGallery() {
  const { getPhoto } = useCamera();
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [photosBase64, setPhotosBase64] = useState<any>([]);
  const [photoFormat, setPhotoFormat] = useState<string>();

  const { deleteFile, getUri, readFile, writeFile } = useFilesystem();

  const setPhotoArrayEmpty = () => {
    setPhotosBase64([]);
  };

  const takePhoto = async () => {
    const cameraPhoto = await getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,

      quality: 100,
    });
    console.log(cameraPhoto.webPath)
    setPhotosBase64(photosBase64.concat(cameraPhoto.webPath));
    setPhotoFormat(cameraPhoto.format);
    const fileName = new Date().getTime() + cameraPhoto.format;
    const savedFileImage = await savePicture(cameraPhoto, fileName);
    const newPhotos = [savedFileImage, ...photos];
    setPhotos(newPhotos);
  };
  const savePicture = async (
    photo: CameraPhoto,
    fileName: string
  ): Promise<Photo> => {
    const base64Data = await base64FromPath(photo.webPath!);
    console.log(base64Data)

    const savedFile = await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data,
    });
    console.log(savedFile);
    // Use webPath to display the new image instead of base64 since it's
    // already loaded into memory
    return {
      filepath: fileName,
      webviewPath: photo.webPath,
      fileData: base64Data,
    };
  };

  return {
    photos,
    takePhoto,
    photosBase64,
    photoFormat,
    setPhotoArrayEmpty,
  };
}
