import React, { useState, useEffect } from "react";
import { IonContent } from "@ionic/react";
import {
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonCard,
  IonLabel,
  IonList,
  IonItemDivider,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonButton,
} from "@ionic/react";
import {
  walletOutline,
  cashOutline,
  documentLock,
  arrowUpCircle,
} from "ionicons/icons";
import axios from "../../constants/axios-instance";
import { Login } from "../../Login";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import Spinner from "../../constants/spinner";
import { useIonRouter } from "@ionic/react";
import { Plugins, Capacitor } from "@capacitor/core";
import "../../Animations/Animation.css";

export const QuickLinks: React.FC = () => {
  const [name, setName] = useState("");
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [queryData, setqueryData] = useState<[]>([]);
  const [isLoaded, setIsLoaded] = useState<any>(true);
  const [userType, setUserType] = useState("");
  const history = useHistory();

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
        setUserType(profileObject.user_type);
      }
    });
    get("token").then((res) => {
      setToken(res);
    });
    const getData = async () => {
      const res = await axios.get("/api/am/get-recent-queries", {
        headers: {
          "x-access-token": token,
        },
      });
      setqueryData(res?.data?.queries);
      if (res) {
        setTimeout(() => {
          setIsLoaded(false);
        }, 1000);
      }
    };
    if (token) {
      getData();
    }
  }, [profile, token]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customHome__am animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle> Home </IonTitle>
      </IonToolbar>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Hi {name},</IonCardTitle>
          <IonCardSubtitle> Approval Manager </IonCardSubtitle>
          <IonCardSubtitle> How are you doing today? </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>Here are some Quick Actions for you.</IonCardContent>
        <IonItem
          routerLink="/AM/ResidentInvoices"
          className="ion-activated"
          lines="none"
        >
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={documentLock} slot="start" />
            </div>
          </div>

          <IonLabel> Resident Expenses </IonLabel>
        </IonItem>

        <IonItem routerLink="/AM/FacilityInvoices" lines="none">
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={walletOutline} slot="start" />
            </div>
          </div>

          <IonLabel> Facility Expenses </IonLabel>
        </IonItem>

        {/* <IonItem routerLink="#" className="ion-activated" lines="none">
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={cashOutline} slot="start" />
            </div>
          </div>

          <IonLabel> View Home Expense </IonLabel>
        </IonItem> */}

        <IonItem routerLink="/AM/BillUpload" lines="none">
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={arrowUpCircle} slot="start" />
            </div>
          </div>

          <IonLabel> Upload a Bill </IonLabel>
        </IonItem>
      </IonCard>

      <IonCard className="customQueryCard">
        <IonCardHeader>
          <IonCardTitle>Recent Queries</IonCardTitle>
        </IonCardHeader>
        {isLoaded ? (
          <Spinner />
        ) : (
          <div className="customQueryWrapper">
            {queryData?.map((query: any, key) => {
              return (
                <IonItem
                  lines="none"
                  onClick={() => {
                    set("queryId", query?._id).then(() => {});
                    history.push("/Am/QueryDetails");
                  }}
                  className="customQuery fadeIn"
                  key={key}
                  id={"_" + key}
                >
                  New Query from: <span>{query?.name}</span>
                </IonItem>
              );
            })}
          </div>
        )}
      </IonCard>
    </IonContent>
  );
};
