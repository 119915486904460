import React, { createContext, useContext, useReducer } from "react";

interface ContainerProps {
  initialState: any;
  reducer: any;
}
// this is data layer
export const StateContext = createContext<any>(null);

const StateProvider: React.FC<ContainerProps> = ({
  reducer,
  initialState,
  children,
}) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;
export const useStateValue = () => useContext(StateContext);
