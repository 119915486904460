import React, { useState, useEffect } from "react";
import { IonContent, IonItem, IonSlide, IonSlides } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
} from "@ionic/react";
import { documentTextOutline } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import { get, clear } from "../../services/storage";
import { useHistory } from "react-router-dom";
import Loading from "../../constants/loading";
import "./BillDetails.css";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@ionic-native/file-transfer";
import { File } from "@ionic-native/file";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import "../../Animations/Animation.css";

export const BillDetails: React.FC = () => {
  const history = useHistory();
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [billId, setBillId] = useState<any>();
  const [whichRoute, setWhichRoute] = useState<any>();
  const [billImg, setBillImg] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const [file, setFile] = React.useState<string | undefined>();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FA/UploadedBills");
      });
    }

    getTokenFromLocalStorage();
    getBillIdFromLocalStorage();

    if (token && billId) {
      getBillDetails();
    }
  }, [billId, token, isDataLoaded]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      const profile = JSON.parse(res);
      if (profile?.user_type !== "FA") {
        clear().then(() => history.replace("/Login"));
      }
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getBillIdFromLocalStorage = () => {
    get("billId").then((res) => {
      setBillId(res);
    });
  };

  const getBillDetails = async () => {
    const res = await axios.get("/api/fa/bill/" + billId, {
      headers: {
        "x-access-token": token,
      },
    });
    setBillDetails(res?.data);
    setBillImg(res?.data?.image_url);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const checkIfImageIsLoaded = () => {
    var img = document.getElementById("billImg")?.offsetHeight;
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  const getWhichRouteToGoBackFromLocalStorage = () => {
    get("whichRoute").then((res) => {
      setWhichRoute(res);
    });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const deleteBill = async () => {
    setIsDataLoaded(true);
    const res = await axios.get("/api/fa/delete/" + billId, {
      headers: {
        "x-access-token": token,
      },
    });

    if (res?.data?.deleted) {
      setShowErrorAlert(true);
      setIsDataLoaded(false);
      history.replace("/FA/UploadedBills");
    } else {
      setIsDataLoaded(false);
      setShowErrorAlert(true);
      setIsError(true);
    }
  };

  checkIfUserDoesntExist();
  getWhichRouteToGoBackFromLocalStorage();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customBillDetails__fa"
              : "customBillDetails__fa animated"
          }
        >
          <IonAlert
            isOpen={showErrorAlert}
            onDidDismiss={() => setShowErrorAlert(false)}
            cssClass="my-custom-class"
            message={
              isError
                ? "Bill hasn't been deleted Successfully!."
                : "Bill has been deleted Successfully!"
            }
            buttons={["OK"]}
          />
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            message={"<strong>Are you sure?</strong>"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  setShowAlert(false);
                },
              },
              {
                text: "Okay",
                handler: () => {
                  deleteBill();
                },
              },
            ]}
          />
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                onClick={() => history.replace("/FA/UploadedBills")}
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Bill Details </IonTitle>
          </IonToolbar>

          <IonCard className="fadeIn">
            <IonGrid>
              {/* <IonRow>
              <a href={billImg} target="_blank" rel="noopener noreferrer">
                <img
                  id="billImg"
                  className={
                    isImgFullyLoaded
                      ? "dontShowImg customImg"
                      : "showImg customImg fadeIn"
                  }
                  src={billImg}
                  onLoad={checkIfImageIsLoaded}
                  alt="img"
                />
              </a>
            </IonRow> */}
              <IonRow>
                <IonSlides pager={true} options={slideOpts}>
                  {billImg?.map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__am"
                          src={"https://epoch.theflak.in/" + img}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>
              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3> {billDetails?.name} </h3>
                    <p> {billDetails?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <p className="amountTag" id="billdetail-balance">
                        {" "}
                        Rs.{" "}
                        {billDetails?.amount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <div>
                      <p style={{ fontSize: 14 }}>Date</p>
                      <h6
                        style={{ color: "#333333", fontSize: 16 }}
                        id="resident-card-display-in-line"
                      >
                        {moment(billDetails?.bill_date).format("MMM DD,YYYY")}
                      </h6>
                    </div>
                    <div>
                      <p style={{ fontSize: 14 }}>Category</p>
                      <h6 style={{ color: "#333333", fontSize: 16 }}>
                        {" "}
                        {billDetails?.category}{" "}
                      </h6>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    <h6> Bill number </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.billNo}
                    </p>
                    <h6> Description </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.description}{" "}
                    </p>
                    <h6> Status </h6>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p id="zeromargin" className="customBillValue">
                        {" "}
                        {billDetails?.status.charAt(0).toUpperCase() +
                          billDetails?.status.slice(1)}{" "}
                      </p>
                      {billDetails.status === "pending" ? (
                        <button
                          style={{
                            float: "right",
                            padding: 10,
                            background: "#A91D2A",
                            borderRadius: 5,
                            color: "#fff",
                          }}
                          onClick={() => setShowAlert(true)}
                        >
                          Delete
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    {billDetails.status === "rejected" ? (
                      <div>
                        {" "}
                        <h6> Reason for rejection </h6>
                        <p id="zeromargin" className="customBillValue">
                          {" "}
                          {billDetails?.reason}{" "}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
