import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSlide,
  IonSlides,
  IonTextarea,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAlert,
} from "@ionic/react";
import { Login } from "../../Login";
import { trashBin } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import { get, set } from "../../services/storage";
import "./RaiseQuery.css";
import { useHistory } from "react-router-dom";
import { Plugins, Capacitor } from "@capacitor/core";
import { EmailComposer } from "@ionic-native/email-composer";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import Loading from "../../constants/loading";
import "../../Animations/Animation.css"

export const RaiseQuery: React.FC = () => {
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>();
  const [residentId, setResidentId] = useState<any>();
  const [billId, setBillId] = useState<any>();
  const [billImg, setBillImg] = useState<any>();
  const [queryData, setQueryData] = useState<any>();
  const [queryTo, setQueryTo] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<any>(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }
  }, []);

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    get("resident_id").then((res) => {
      setResidentId(res);
    });
    get("billId").then((res) => {
      setBillId(res);
    });

    if (token && billId) {
      const getData = async () => {
        const res = await axios.get("/api/client/petty_bill/" + billId, {
          headers: {
            "x-access-token": token,
          },
        });
        setBillDetails(res.data);
        setBillImg(res?.data?.image_url);

        setTimeout(() => {
          setShowLoading(false);
        }, 1000);
      };
      getData();
    }
  }, [token, residentId, billId, profile]);

  const sendQuery = async (e: any) => {
    setShowLoading(true);
    e.preventDefault();
    const data = {
      _id: billId,
      query: queryData,
      to: queryTo,
    };

    axios
      .post("/api/client/add-query", JSON.stringify(data), {
        headers: {
          "x-access-token": token,
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.done) {
          history.push("/client/Queries");
        }
      });
  };

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        setShowAlert(true);
        history.replace("/Login");
      }
    });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {showLoading ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            showLoading ? "customRaiseQuery__cl" : "customRaiseQuery__cl animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/Client/BillDetails"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
              {/* <IonItem className="customBackBtn" routerLink="/FM/ResidentBills">
              <IonBackButton defaultHref="" text="" />
            </IonItem> */}
            </IonButtons>
            <IonTitle> Raise Query </IonTitle>
          </IonToolbar>

          <IonCard>
            <IonGrid>
              <IonRow>
                {/* <IonImg src={billImg} /> */}
                <IonSlides pager={true} options={slideOpts}>
                  {billImg?.map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__am"
                          // className={
                          //   isImgFullyLoaded
                          //     ? "dontShowImg customImg"
                          //     : "showImg customImg fadeIn"
                          // }
                          src={"https://epoch.theflak.in/" + img}
                          // onLoad={chkImg}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>
              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3> {billDetails?.name} </h3>
                    <p> {billDetails?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <button id="billdetail-balance">
                        {" "}
                        Rs. {billDetails?.amount}{" "}
                      </button>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <div>
                      <p style={{ fontSize: 14 }}>Date</p>
                      <h6
                        style={{ color: "#333333", fontSize: 16 }}
                        id="resident-card-display-in-line"
                      >
                        {moment(billDetails?.bill_date).format("MMM DD,YYYY")}
                      </h6>
                    </div>
                    <div>
                      <p style={{ fontSize: 14 }}>Category</p>
                      <h6 style={{ color: "#333333", fontSize: 16 }}>
                        {" "}
                        {billDetails?.category?.expense}{" "}
                      </h6>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    <h6> Bill number </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.billNo}
                    </p>
                    <h6> Description </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.description}{" "}
                    </p>
                    <h6> Status </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.status}{" "}
                    </p>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow />
              <IonItem>
                <IonLabel>Send Query to</IonLabel>
                <IonSelect
                  value={queryTo}
                  placeholder="Select One"
                  onIonChange={(e: any) => {
                    setQueryTo(e.detail.value);
                    setIsDisabled(false);
                  }}
                >
                  <IonSelectOption value="FM">Facility Manager</IonSelectOption>
                  <IonSelectOption value="AM">Finance Manager</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonRow>
                <IonCol className="raiseQuery__cl" size="12">
                  <div>
                    <IonLabel position="stacked">Description</IonLabel>
                    <IonTextarea
                      value={queryData}
                      onIonChange={(e: any) => {
                        setQueryData(e.detail.value);
                      }}
                      required
                    ></IonTextarea>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow
                className="raiseQueryBtnRow__cl"
                style={{ float: "right" }}
              >
                <IonItem
                  // disabled={isDisabled}
                  disabled={queryData?.length === 0 || isDisabled}
                  lines="none"
                  className="raiseQueryBtn__cl"
                >
                  <button
                    style={{
                      color: "#fff",
                      background: "#c12525",
                      padding: 10,
                      borderRadius: 18,
                    }}
                    onClick={sendQuery}
                  >
                    Send Query
                  </button>
                </IonItem>
                <IonLoading
                  cssClass="my-custom-class"
                  isOpen={showLoading}
                  onDidDismiss={() => setShowLoading(false)}
                  message={"Please wait..."}
                />
                <IonAlert
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  cssClass="my-custom-class"
                  header={"Done!"}
                  // subHeader={''}
                  message={"Your Query has been raised."}
                  buttons={["OK"]}
                />
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
