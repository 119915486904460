import React, { useState, useEffect } from "react";
import {
    IonContent,
    IonDatetime,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
} from "@ionic/react";
import {
    IonBackButton,
    IonButtons,
    IonImg,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import { walletOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./TopUpHistory.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const InvoiceTopups: React.FC = () => {
    const [token, setToken] = useState<any>();
    const [topUps, setTopUps] = useState<any>();
    const [totalPages, setTotalPages] = useState<any>();
    const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
    const [date, setDate] = useState<any>();
    const [month, setMonth] = useState<any>(0);
    const [year, setYear] = useState<any>(0);
    const [todayDate, setTodayDate] = useState<any>(0);
    const [dateChangeCounter, setDateChangeCounter] = useState(0);
    const [centerName, setCenterName] = useState(0);
    const [home, setHome] = useState<any>();
    const [profile, setProfile] = useState<any>();

    const history = useHistory();
    const [page, setPage] = useState(1);
    const handlePages = (updatePage: number) => {
        setIsDataLoaded(true);
        setPage(updatePage);
        getPaginatedExpensesData(updatePage - 1);
    };

    useEffect(() => {
        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e) => {
                history.replace("/AM/Home");
            });
        }

        getProfileFromLocalStorage();
        if (profile) {
            const profileObject = JSON.parse(profile);
            setHome(profileObject?.centers);
        }

        getTokenFromLocalStorage();

        if (token) {
            getExpensesData();
        }
    }, [token, centerName, dateChangeCounter]);

    const getProfileFromLocalStorage = () => {
        get("profile").then((res) => {
            setProfile(res);
        });
    };

    const getTokenFromLocalStorage = () => {
        get("token").then((res) => {
            setToken(res);
        });
    };

    const checkIfUserDoesntExist = () => {
        get("token").then((res) => {
            if (!res) {
                history.replace("/Login");
            }
        });
    };

    const setBillId = (e: any) => {
        set("billId", e).then(() => {});
        set("whichRoute", "FHPC").then(() => {});
    };

    const getExpensesData = async () => {
        const res = await axios.get(
            "/api/am/invoice-top-ups/0/" +
            centerName +
            "/" +
            todayDate +
            "/" +
            month +
            "/" +
            year,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        );
        setTopUps(res?.data?.invoice_topups);
        setTotalPages(res?.data?.pages);
        setPage(1);
        if (res) {
            setTimeout(() => {
                setIsDataLoaded(false);
            }, 1000);
        }
    };

    const getPaginatedExpensesData = async (updatePage: Number) => {
        const res = await axios.get(
            "/api/am/invoice-top-ups/" +
            updatePage + "/" +
            centerName +
            "/" +
            todayDate +
            "/" +
            month +
            "/" +
            year,
            {
                headers: {
                    "x-access-token": token,
                },
            }
        );
        setTopUps(res?.data?.invoice_topups);
        if (res) {
            setTimeout(() => {
                setIsDataLoaded(false);
            }, 1000);
        }
    };

    checkIfUserDoesntExist();

    return (
        <IonContent>
            {isDataLoaded ? (
                <Loading />
            ) : (
                <IonContent
                    scrollEvents={true}
                    onIonScrollStart={() => {}}
                    onIonScroll={() => {}}
                    onIonScrollEnd={() => {}}
                    className={
                        isDataLoaded
                            ? "customTopUpHistory__am"
                            : "customTopUpHistory__am animated"
                    }
                >
                    <IonToolbar className="customHeading">
                        <IonButtons slot="start">
                            <IonItem
                                className="customBackBtn"
                                detail={false}
                                routerLink="/AM/Home"
                                lines="none"
                            >
                                <IonBackButton defaultHref="" text=""></IonBackButton>
                            </IonItem>
                        </IonButtons>
                        <IonTitle> Invoice Top Up History </IonTitle>
                    </IonToolbar>

                    <IonItem lines="none" className="selectItem_am">
                        <IonLabel position="stacked">Select Home</IonLabel>
                        <IonSelect
                            placeholder="Select One"
                            value={centerName}
                            onIonChange={(e: any) => {
                                setCenterName(e.detail.value);
                                setIsDataLoaded(true);
                                // setApiCallCounter(1);
                            }}
                        >
                            {home?.map((home: string, key: number) => {
                                return (
                                    <IonSelectOption key={key} value={home}>
                                        {home}
                                    </IonSelectOption>
                                );
                            })}
                        </IonSelect>
                    </IonItem>

                    <IonCard>
                        {totalPages === 0 ? (
                            <h4 style={{ textAlign: "center" }}>No Data to Show</h4>
                        ) : (
                            topUps
                                ?.slice(0)
                                // .reverse()
                                ?.map((topUp: any, key: any) => {
                                    return (
                                        <IonItem key={key} lines="none">
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol size="3">
                                                        <IonIcon
                                                            style={{ fontSize: 60 }}
                                                            icon={walletOutline}
                                                        />
                                                    </IonCol>
                                                    <IonCol size="6">
                                                        <div className="resident-card">
                                                            <h3> {topUp?.name} </h3>
                                                            <div className="rb-pTag">
                                                                <p className="">
                                                                    {" "}
                                                                    {moment(topUp?.date).format("MMM DD,YYYY")}
                                                                </p>
                                                                {/* <p className=""> {topUp?.epoch_id} </p> */}
                                                            </div>
                                                            <h6>
                                                                {" "}
                                                                Rs.{" "}
                                                                {topUp?.amount
                                                                    ?.toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                                            </h6>
                                                        </div>
                                                    </IonCol>
                                                    {/* <IonCol size="3" className="customResidentView">
                          <div className="resident-card">
                            <div
                              className="view-details-right"
                              onClick={() => {
                                setBillId(expense?._id);
                                history.push("/FM/BillDetails");
                              }}
                            >
                              <IonIcon
                                style={{ fontSize: 36 }}
                                icon={arrowForwardCircleOutline}
                              ></IonIcon>
                              <p>View Details</p>
                            </div>
                          </div>
                        </IonCol> */}
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>
                                    );
                                })
                        )}
                        {totalPages > 1 ? (
                            <div>
                                <Pagination
                                    page={page}
                                    totalPages={totalPages}
                                    handlePagination={handlePages}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </IonCard>
                </IonContent>
            )}
        </IonContent>
    );
};
