import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonLabel,
  IonModal,
  IonRefresher,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonDatetime,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  personCircleOutline,
  add,
} from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./Residents.css";
import { Plugins, Capacitor } from "@capacitor/core";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";
import moment from "moment";

export const Residents: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  const [home, setHome] = useState<any>();
  const [centerName, setCenterName] = useState(0);
  const [residents, setResidents] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [amount, setAmount] = useState<number>(0);
  const [epochId, setEpochId] = useState<any>();
  const [name, setName] = useState<any>();
  const [counter, setCounter] = useState(0);
  // const [selectedResidentId, setSelectedResidentId] = useState<string>(''); todo: use this to fix update
  const [totalBalance, setTotalBalance] = useState(0);
  const [centerTotal, setCenterTotal] = useState(0);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState<any>("");
  const [text, setText] = useState<any>(0);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const [billDate, setBillDate] = useState<any>();

  const handlePages = async (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    await getPaginatedResidentDetails(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }

    getProfileFromLocalStorage();
    if (profile) {
      const profileObject = JSON.parse(profile);
      setHome(profileObject?.centers);
    }
    getTokenFromLocalStorage();

    if (token) {
      getResidentDetails();
    }
  }, [token, centerName, text, refreshData]);

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getProfileFromLocalStorage = () => {
    get("profile").then((res) => {
      setProfile(res);
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentDetails = async () => {
    const res = await axios.get(
      "/api/am/get-residents/" + centerName + "/0/" + text,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setResidents(res?.data?.residents);
    setTotalPages(res?.data?.pages);
    setTotalBalance(res?.data?.totalAll);
    setCenterTotal(res?.data?.centerTotal);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedResidentDetails = async (updatePage: Number) => {
    // if (searchText !== undefined) {
    //   setText(searchText);
    // }
    console.log('getPaginatedResidentDetails', updatePage);
    const res = await axios.get(
      "/api/am/get-residents/" + centerName + "/" + updatePage + "/" + text,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setResidents(res?.data?.residents);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const topUp = async () => {
    setIsDataLoaded(true);
    if (!epochId || !amount) {
      setIsDataLoaded(false);
      return alert("Incorrect value!");
    }
    if (!billDate) {
      setIsDataLoaded(false);
      return alert("Please enter date.");
    }
    const data = {
      epochId,
      amount,
      billDate,
      name,
    };

    const isTopUp = await axios.post("/api/am/topup", data, {
      headers: {
        "x-access-token": token,
      },
    });
    if (isTopUp.data.done === 1) {
      setIsDataLoaded(false);
      setCounter(0);
      setAmount(0);
      setRefreshData(!refreshData);
      alert("Topup done!");
    } else {
      setIsDataLoaded(false);
      setCounter(0);
      setAmount(0);
      alert("Top up can't be done!");
    }
  };
  const updatePendingBalance = async () => {
    setIsDataLoaded(true);
    if (!epochId || !amount) {
      setIsDataLoaded(false);
      return alert("Incorrect value!");
    }
    if (!billDate) {
      setIsDataLoaded(false);
      return alert("Please enter date.");
    }
    const data = {
      epochId,
      amount,
      billDate,
      name,
    };

    const isTopUp = await axios.post("/api/am/update-pending-balance", data, {
      headers: {
        "x-access-token": token,
      },
    });
    if (isTopUp.data.done === 1) {
      setIsDataLoaded(false);
      setCounter(0);
      setAmount(0);
      setRefreshData(!refreshData);
      alert("Amount Updated");
    } else {
      setIsDataLoaded(false);
      setCounter(0);
      setAmount(0);
      alert("Cannot update amount");
    }
  };
  const setResidentDetails = (params: any) => {
    history.push("/AM/ResidentDetails");
    console.log(history);
    set("residentId", params.epoch_id).then((res) => {
      console.log(res);
    });
    set("residentName", params.resident_name).then((res) => {
      console.log(res);
    });
  };

  checkIfUserDoesntExist();

  const handleSearch = (value: any) => {
    setSearchText(value);
    if (value === "") {
      setText(0);
    } else {
      setText(value);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidents__am"
              : "customResidents__am animated"
          }
        >
          <IonModal
            swipeToClose={true}
            showBackdrop={true}
            backdropDismiss={true}
            isOpen={showModal}
            cssClass="customModal"
            onDidDismiss={() => setShowModal(false)}
          >
            <IonItem>
              <IonLabel position="stacked">Enter amount:</IonLabel>
              <IonInput
                min="2"
                type="number"
                value={amount}
                onIonChange={(e: any) => {
                  setAmount(parseInt(e.detail.value!, 10));
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Enter Date</IonLabel>
              <IonDatetime
                placeholder="Select Date"
                value={billDate}
                max={moment().format()}
                onIonChange={(e: any) => {
                  setBillDate(e.detail.value);
                }}
              ></IonDatetime>
            </IonItem>
            <IonItem>
              <IonButton
                className="modalButton1"
                disabled={amount === 0 ? true : false}
                onClick={() => {
                  setCounter(1);
                  topUp();
                  setShowModal(false);
                }}
              >
                Send
              </IonButton>
              <IonButton
                className="modalButton2"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </IonButton>
            </IonItem>
          </IonModal>

          <IonModal
            swipeToClose={true}
            showBackdrop={true}
            backdropDismiss={true}
            isOpen={showModal1}
            cssClass="customModal"
            onDidDismiss={() => setShowModal1(false)}
          >
            <IonItem>
              <IonLabel position="stacked">
                Enter amount Paid by Client
              </IonLabel>
              <IonInput
                min="2"
                type="number"
                value={amount}
                onIonChange={(e: any) => {
                  setAmount(parseInt(e.detail.value!, 10));
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Enter Date of Payment</IonLabel>
              <IonDatetime
                placeholder="Select Date"
                value={billDate}
                max={moment().format()}
                onIonChange={(e: any) => {
                  setBillDate(e.detail.value);
                }}
              ></IonDatetime>
            </IonItem>
            <IonItem>
              <IonButton
                className="modalButton1"
                disabled={amount === 0 ? true : false}
                onClick={() => {
                  setCounter(1);
                  updatePendingBalance();
                  setShowModal1(false);
                }}
              >
                Send
              </IonButton>
              <IonButton
                className="modalButton2"
                onClick={() => {
                  setShowModal1(false);
                }}
              >
                Close
              </IonButton>
            </IonItem>
          </IonModal>
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Residents </IonTitle>
          </IonToolbar>
          <IonSearchbar
            className="search_am"
            value={searchText}
            onIonChange={(e) => handleSearch(e.detail.value!)}
            placeholder="Search Residents"
            debounce={1000}
          ></IonSearchbar>
          <IonItem lines="none" className="selectHome_am">
            <IonLabel position="stacked">Select Home</IonLabel>
            <IonSelect
              value={centerName}
              placeholder="Select One"
              onIonChange={(e: any) => {
                setCenterName(e.detail.value);
                setIsDataLoaded(true);
                // setApiCallCounter(1);
              }}
            >
              {home?.map((home: string, key: number) => {
                return (
                  <IonSelectOption key={key} value={home}>
                    {home}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 5,
            }}
          >
            <p
              className="totalBalance_am"
              style={{
                background: "#c12525",
                color: "#fff",
                padding: "2%",
                fontSize: 14,
                borderRadius: 8,
                textAlign: "center",
              }}
            >
              Total Balance: &#8377;
              {totalBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
            {centerTotal ? (
              <p
                className="totalBalance_am"
                style={{
                  background: "#c12525",
                  color: "#fff",
                  padding: "2%",
                  fontSize: 14,
                  borderRadius: 8,
                }}
              >
                Center Total: &#8377;
                {centerTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
            ) : (
              <></>
            )}
          </div>

          <IonCard>
            {residents?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to show</h4>
            ) : (
              residents?.map((resident: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="9">
                          {/* <IonIcon style={{fontSize: 36}} icon={personCircleOutline}></IonIcon> */}
                          <div className="resident-card">
                            <h3> {resident?.resident_name} </h3>
                            {/* <p> {resident?.epoch_id} </p> */}
                            <div style={{ display: "flex" }}>
                              <div>
                                <h6>
                                  Petty Cash:
                                  <span>
                                    {/* {isSend
                                ? counter === key
                                  ? (
                                      resident?.balance_left +
                                      (amount ? amount : 0)
                                    )
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : resident?.balance_left
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : resident?.balance_left
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}

                                    {resident?.balance_left
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </h6>
                                <button
                                  style={{
                                    background: "#c12525",
                                    color: "#fff",
                                    padding: 5,
                                    paddingLeft: 15,
                                    borderRadius: 15,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                  onClick={() => {
                                    setShowModal(true);
                                    setEpochId(resident?.epoch_id);
                                    setName(resident?.resident_name);
                                  }}
                                >
                                  <p style={{ color: "#fff", fontSize: 12 }}>
                                    Top up
                                  </p>
                                  <IonIcon
                                    style={{
                                      color: "#fff",
                                      marginLeft: 5,
                                      fontSize: 18,
                                    }}
                                    icon={add}
                                  ></IonIcon>
                                </button>
                              </div>
                              <div>
                                <h6>
                                  Invoice:
                                  <span>
                                    {/* {isSend
                                ? counter === key
                                  ? (
                                      resident?.balance_left +
                                      (amount ? amount : 0)
                                    )
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : resident?.balance_left
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : resident?.balance_left
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}

                                    {resident?.pendingInvoiceAmt
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </h6>
                                <button
                                  style={{
                                    background: "#c12525",
                                    color: "#fff",
                                    padding: 5,
                                    paddingLeft: 15,
                                    borderRadius: 15,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                  onClick={() => {
                                    setShowModal1(true);
                                    setEpochId(resident?.epoch_id);
                                    setName(resident?.resident_name);
                                  }}
                                >
                                  <p style={{ color: "#fff", fontSize: 12 }}>
                                    Update
                                  </p>
                                  <IonIcon
                                    style={{
                                      color: "#fff",
                                      marginLeft: 5,
                                      fontSize: 18,
                                    }}
                                    icon={add}
                                  ></IonIcon>
                                </button>
                              </div>
                              <div>
                                <h6>
                                  Total Pending Amount:
                                  <span>
                                    {/* {isSend
                                ? counter === key
                                  ? (
                                      resident?.balance_left +
                                      (amount ? amount : 0)
                                    )
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : resident?.balance_left
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : resident?.balance_left
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}

                                    {resident?.pendingAmount
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </IonCol>

                        <IonCol size="3" className="customResidentView">
                          <div
                            className="resident-card"
                            style={{ marginTop: 20 }}
                          >
                            <div
                              className="view-details-right"
                              onClick={() => {
                                setResidentDetails(resident);
                              }}
                            >
                              <IonIcon
                                style={{ fontSize: 36 }}
                                icon={arrowForwardCircleOutline}
                              ></IonIcon>
                              <p>View Details</p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {/* {residents?.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
