import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonLabel,
  IonModal,
  IonRefresher,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from "@ionic/react";
import { arrowForwardCircleOutline, documentTextOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./RejectedBills.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const RejectedBills: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  const [home, setHome] = useState<any>();
  const [centerName, setCenterName] = useState(0);
  const [rejectedBills, setRejectedBills] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [counter, setCounter] = useState(0);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [residents, setResidents] = useState([]);
  const [resident, setResident] = useState(0);

  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedRejectedBills(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }

    getProfileFromLocalStorage();
    if (profile) {
      const profileObject = JSON.parse(profile);
      setHome(profileObject?.centers);
    }
    getTokenFromLocalStorage();

    if (token) {
      getRejectedBills();
    }
  }, [token, counter, centerName, resident]);

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
  };

  const getProfileFromLocalStorage = () => {
    get("profile").then((res) => {
      setProfile(res);
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getRejectedBills = async () => {
    const res = await axios.get(
      "/api/am/get-rejected-bills/" + centerName + "/0" + "/" + resident,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setRejectedBills(res?.data?.rejected_bills);
    setTotalPages(res?.data?.rejected_pages);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedRejectedBills = async (updatePage: Number) => {
    const res = await axios.get(
      "/api/am/get-rejected-bills/" +
        centerName +
        "/" +
        updatePage +
        "/" +
        resident,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setRejectedBills(res?.data?.rejected_bills);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  // const viewMoreData = () => {
  //   if (totalPages - 1 < apiCallCounter) {
  //     return alert("No More Data to show");
  //   }
  //   setIsDataLoaded(true);
  //   setApiCallCounter(apiCallCounter + 1);
  //   getPaginatedRejectedBills();
  // };

  checkIfUserDoesntExist();

  const getResidents = async (home: string) => {
    const res = await axios.get(`/api/am/residents/${home}`, {
      headers: {
        "x-access-token": token,
      },
    });
    setResidents(res?.data?.residents);
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customRejectedBills__am"
              : "customRejectedBills__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Rejected Bills </IonTitle>
          </IonToolbar>
          {/* <IonItem lines="none" className="selectHome_am">
            <IonLabel position="stacked">Select Home</IonLabel>
            <IonSelect
              value={centerName}
              placeholder="Select One"
              onIonChange={(e: any) => {
                setCenterName(e.detail.value);
                setIsDataLoaded(true);
              }}
            >
              {home?.map((home: string, key: number) => {
                return (
                  <IonSelectOption key={key} value={home}>
                    {home}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem> */}
          <div
            className="selectOption-am"
            // style={{ display: "flex", justifyContent: "space-between" }}
          >
            <IonItem lines="none" style={{ flex: 1 }} className="selectHome_am">
              <IonLabel position="stacked">Select Home</IonLabel>
              <IonSelect
                value={centerName}
                placeholder="Select One"
                onIonChange={(e: any) => {
                  setCenterName(e.detail.value);
                  setIsDataLoaded(true);
                  getResidents(e.detail.value);
                }}
              >
                {home?.map((home: string, key: number) => {
                  return (
                    <IonSelectOption key={key} value={home}>
                      {home}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>

            {residents.length !== 0 ? (
              <IonItem
                lines="none"
                style={{ flex: 1 }}
                className="selectHome_am"
              >
                <IonLabel position="stacked">Select Residents</IonLabel>
                <IonSelect
                  value={resident}
                  placeholder="Select One"
                  onIonChange={(e: any) => {
                    setResident(e.detail.value);
                    setIsDataLoaded(true);
                  }}
                >
                  {residents?.map((home: string, key: number) => {
                    return (
                      <IonSelectOption key={key} value={home}>
                        {home}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            ) : null}
          </div>

          <IonCard>
            {rejectedBills?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to show</h4>
            ) : (
              rejectedBills?.map((bill: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="2.5">
                          <IonIcon
                            style={{ fontSize: 65 }}
                            icon={documentTextOutline}
                          />
                        </IonCol>
                        <IonCol size="6.5">
                          <div className="resident-card">
                            <h3> {bill?.name} </h3>
                            <div className="rb-pTag">
                              <p className="">
                                {" "}
                                {moment(bill?.bill_date).format(
                                  "MMM DD,YYYY"
                                )}{" "}
                              </p>
                              <p className=""> {bill?.category} </p>
                            </div>
                            <h6>
                              {" "}
                              Rs.{" "}
                              {bill?.amount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </h6>
                          </div>
                        </IonCol>
                        <IonCol size="3" className="customResidentView">
                          <div className="resident-card">
                            <div
                              className="view-details-right"
                              onClick={() => {
                                set("whichRoute", "FRB").then(() => {});
                                setBillId(bill?._id);
                                history.push("/AM/ResidentBillDetails");
                              }}
                            >
                              <IonIcon
                                style={{ fontSize: 36 }}
                                icon={arrowForwardCircleOutline}
                              ></IonIcon>
                              <p>View Details</p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {/* {rejectedBills?.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
