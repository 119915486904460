import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSlide,
  IonSlides,
  IonTextarea,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { Login } from "../../Login";
import { trashBin } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import { get, set } from "../../services/storage";
import { useHistory } from "react-router-dom";
import "./BillDetails.css";
import Loading from "../../constants/loading";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import "../../Animations/Animation.css";

export const BillDetails: React.FC = () => {
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [residentId, setResidentId] = useState<any>();
  const [billId, setBillId] = useState<any>();
  const [billImg, setBillImg] = useState<any>();
  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/Client/PettyCash");
      });
    }
  }, []);

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    get("resident_id").then((res) => {
      setResidentId(res);
    });
    get("billId").then((res) => {
      setBillId(res);
    });

    const getData = async () => {
      const res = await axios.get("/api/client/petty_bill/" + billId, {
        headers: {
          "x-access-token": token,
        },
      });
      setBillDetails(res.data);
      setBillImg(res?.data?.image_url);
      if (res) {
        setIsDataLoaded(false);
      }
    };
    if (token && billId) {
      getData();
    }
  }, [token, residentId, billId, profile]);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  const chkImg = () => {
    var img = document.getElementById("billImg__cl")?.offsetHeight;
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customBillDetails__cl"
              : "customBillDetails__cl animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/Client/PettyCash"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Bill Details </IonTitle>
          </IonToolbar>

          <IonCard>
            <IonGrid>
              {/* <IonRow>
              <a href={billImg} target="_blank" rel="noopener noreferrer">
                <img
                  id="billImg__cl"
                  className={
                    isImgFullyLoaded
                      ? "dontShowImg customImg"
                      : "showImg customImg fadeIn"
                  }
                  src={billImg}
                  onLoad={chkImg}
                  alt="img"
                />
              </a>
            </IonRow> */}
              <IonRow>
                <IonSlides pager={true} options={slideOpts}>
                  {billImg?.map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__am"
                          // className={
                          //   isImgFullyLoaded
                          //     ? "dontShowImg customImg"
                          //     : "showImg customImg fadeIn"
                          // }
                          src={"https://epoch.theflak.in/" + img}
                          // onLoad={chkImg}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>
              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3> {billDetails?.name} </h3>
                    <p> {billDetails?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <button id="billdetail-balance">
                        {" "}
                        Rs.{" "}
                        {billDetails?.amount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </button>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <div>
                      <p style={{ fontSize: 14 }}>Date</p>
                      <h6
                        style={{ color: "#333333", fontSize: 16 }}
                        id="resident-card-display-in-line"
                      >
                        {moment(billDetails?.bill_date).format("MMM DD,YYYY")}
                      </h6>
                    </div>
                    <div>
                      <p style={{ fontSize: 14 }}>Category</p>
                      <h6 style={{ color: "#333333", fontSize: 16 }}>
                        {" "}
                        {billDetails?.category?.expense}{" "}
                      </h6>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    <h6> Bill number </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.billNo}
                    </p>
                    <h6> Description </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.description}{" "}
                    </p>
                    <h6> Status </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.status.charAt(0).toUpperCase() +
                        billDetails?.status.slice(1)}{" "}
                    </p>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow
                className="raiseQueryBtnRow__cl"
                style={{ float: "right", margin: "2%" }}
              >
                {billDetails?.queryPresent ? undefined : (
                  // <IonItem lines="none" className="raiseQueryBtn__cl">
                  <button
                    className="raiseQueryBtn__cl"
                    // disabled
                    onClick={() => history.push("/Client/RaiseQuery")}
                  >
                    Raise a query
                  </button>
                  // </IonItem>
                )}
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
