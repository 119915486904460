import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { get, set } from "../../services/storage";
import "./ResidentDetails.css";
import { Plugins, Capacitor } from "@capacitor/core";
import { Pagination } from "../../services/pagination/pagination";
import moment from "moment";
import "../../Animations/Animation.css";

export const ResidentDetails: React.FC = (props: any) => {
  const history = useHistory<any>();
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [token, setToken] = useState<any>();
  const [resident_id, setResidentId] = useState<any>(
    props.match.params.resident_id
  );
  const [residentData, setResidentData] = useState<any>([]);
  const [residentName, setResidentName] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [totalBalance, setTotalBalance] = useState(0);
  const [lastTopupDate, setLastTopupDate] = useState<any>();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedParticularResidentDetails(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Residents");
      });
    }
    getTokenFromLocalStorage();
    getResidentIdFromLocalStorage();
    getResidentName();

    if (token && resident_id) {
      getParticularResidentDetails();
    }
  }, [token, resident_id]);

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentIdFromLocalStorage = () => {
    get("residentId").then((res) => {
      setResidentId(res);
    });
  };

  const getResidentName = () => {
    get("residentName").then((res) => {
      setResidentName(res);
    });
  };

  const getParticularResidentDetails = async () => {
    const res = await axios.get(
      "/api/am/get-resident-bills/" + resident_id + "/0",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setResidentData(res?.data?.bills);
    setTotalPages(res?.data?.pages);
    setLastTopupDate(res?.data?.lastTopup);
    setTotalBalance(res?.data?.totalBalance);
    setPage(1);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedParticularResidentDetails = async (updatePage: Number) => {
    const res = await axios.get(
      "/api/am/get-resident-bills/" + resident_id + "/" + updatePage,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setResidentData(res?.data?.bills);
    // if (apiCallCounter === 0) {
    //   setTotalPages(res?.data?.pages);
    // }
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FRD").then(() => {});
  };
  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  // const viewMoreData = () => {
  //   if (totalPages - 1 < apiCallCounter) {
  //     return alert("No More Data to show");
  //   }
  //   console.log(apiCallCounter);
  //   setIsDataLoaded(true);
  //   setApiCallCounter(apiCallCounter + 1);
  //   getPaginatedParticularResidentDetails();
  // };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidentDetails__am"
              : "customResidentDetails__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Residents"
                // onClick={goBack}
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> {residentName} </IonTitle>
          </IonToolbar>
          <div
          className="totalBalance_wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 12,
            }}
          >
            <p
            className="totalBills_am"
              style={{
                color: "#FFF",
                backgroundColor: "#c12525",
                padding: "3%",
                borderRadius: 5,
              }}
            >
              Total Balance: &#8377;
              {totalBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
            <p
            className="totalBills_am"
              style={{
                color: "#FFF",
                backgroundColor: "#c12525",
                padding: "3%",
                borderRadius: 5,
              }}
            >
              Topup:
              {moment(lastTopupDate).format("MMM DD, YYYY")}
            </p>
          </div>

          <IonCard>
            {residentData?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No data to show</h4>
            ) : (
              residentData?.map((items: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="7">
                          <div className="resident-card">
                            <h3> {items?.expense_type?.expense} </h3>
                            <div className="rb-pTag">
                              <p className="">
                                {moment(items?.bill_date).format("MMM DD,YYYY")}
                              </p>
                              <p className=""> {items?.category} </p>
                            </div>
                            <h6>
                              {" "}
                              Rs.{" "}
                              {items?.amount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </h6>
                          </div>
                        </IonCol>
                        <IonCol size="3" className="customResidentView">
                          <div className="resident-card">
                            <div
                              className="view-details-right"
                              onClick={() => {
                                setBillId(items?._id);
                                history.push("/AM/ResidentBillDetails");
                              }}
                            >
                              <IonIcon
                                style={{ fontSize: 36 }}
                                icon={arrowForwardCircleOutline}
                              ></IonIcon>
                              <p>View Details</p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {/* {residentData?.bills?.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
