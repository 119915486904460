export const CHECKBOX_FILTER = "CHECKBOX_FILTER";

export const initialState = {
  residentExpensesHomeFilter: "0",
  residentExpensesClientFilter: "0",
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_RESIDENT_EXPENSE_HOME_FILTER":
      return {
        ...state,
        residentExpensesHomeFilter: action.data,
      };
    case "SET_RESIDENT_EXPENSE_RESIDENT_FILTER":
      return {
        ...state,
        residentExpensesClientFilter: action.data,
      };

    default:
      return state;
  }
}

export default reducer;
