import React, { useState, useEffect } from "react";
import { IonContent } from "@ionic/react";
import {
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonCard,
  IonLabel,
  IonList,
  IonItemDivider,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonButton,
} from "@ionic/react";
import {
  walletOutline,
  cashOutline,
  documentLock,
  arrowUpCircle,
  cogSharp,
} from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Spinner from "../../constants/spinner";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { get, set, clear } from "../../services/storage";
import "../../Animations/Animation.css";
import { Plugins, Capacitor } from "@capacitor/core";
import { CSSTransition } from "react-transition-group";
// import image from "../../invoice/dfd38aea-69fd-11eb-8b25-0cc47a792c0a_id_dfd38aea-69fd-11eb-8b25-0cc47a792c0a_files/background1.png"

export const QuickLinks: React.FC = () => {
  const [name, setName] = useState("");
  const [venue, setVenue] = useState("");
  const [userType, setUserType] = useState("");
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [queryData, setqueryData] = useState<[]>([]);
  const [isLoaded, setIsLoaded] = useState<any>(true);
  const history = useHistory();

  // const [testUri, setTestUri] = useState<any>();

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
        setVenue(profileObject.center_name);
        setUserType(profileObject.user_type);
      } else {
        setName("UNVERIFIED");
      }
    });
    get("token").then((res) => {
      setToken(res);
    });
  }, [profile, token]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      const profile = JSON.parse(res);
      if (profile?.user_type !== "AFM") {
        clear().then(() => history.replace("/Login"));
      }
    });
  };

  checkIfUserDoesntExist();

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customHome__afm animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle> Home </IonTitle>
      </IonToolbar>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Hi {name},</IonCardTitle>
          <IonCardSubtitle>
            {userType}, {venue}{" "}
          </IonCardSubtitle>
          <IonCardSubtitle> How are you doing today? </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>Here are some Quick Actions for you.</IonCardContent>
        <IonItem
          routerLink="/AFM/BillUpload"
          className="ion-activated"
          lines="none"
        >
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={arrowUpCircle} slot="start" />
            </div>
          </div>

          <IonLabel>Upload a New Bill</IonLabel>
        </IonItem>

        <IonItem routerLink="/AFM/ResidentBills" lines="none">
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={walletOutline} slot="start" />
            </div>
          </div>

          <IonLabel>Uploaded Bills</IonLabel>
        </IonItem>
      </IonCard>
    </IonContent>
  );
};
