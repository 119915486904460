import React, { useState, useEffect } from "react";
import { IonContent, IonImg, IonInput, IonItem, IonLabel } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import "./ContactUs.css";
import {
  personOutline,
  cardOutline,
  playOutline,
  callOutline,
  mailOutline,
  logoWhatsapp,
} from "ionicons/icons";
import profileImg from "../../assets/profileImg.png";
import Loading from "../../constants/loading";
import { get } from "../../services/storage";
import { useHistory } from "react-router-dom";
import { Plugins, Capacitor } from "@capacitor/core";
import "../../Animations/Animation.css"

export const ContactUs: React.FC = () => {
  const [name, setName] = useState("");
  const [profile, setProfile] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }
  }, []);

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
        setTimeout(() => {
          setIsDataLoaded(false);
        }, 1000);
      }
    });
  }, [profile]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={isDataLoaded ? "customContactUs__cl" : "customContactUs__cl animated"}
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/Client/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Contact Us </IonTitle>
          </IonToolbar>

          <IonCard>
            <IonItem lines="none">
              <div className="profileImg__cl--wrapper">
                <IonImg className="profileImg__cl" src={profileImg}></IonImg>
              </div>
            </IonItem>
            <IonItem className="customContactUs__name" lines="none">
              <h4>Hi {name}</h4>
            </IonItem>
            <IonItem className="customContactUs__subHeading" lines="none">
              <p>We are here to help you!</p>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="customContactUs__cl--label">
                <IonIcon icon={callOutline} />
                <IonInput
                  onClick={() => (window.location.href = "tel:8888888888")}
                  value="1234567890"
                  type="text"
                  readonly
                ></IonInput>
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <a href="mailto:test@epoch.com">
                <IonLabel className="customContactUs__cl--label">
                  <IonIcon icon={mailOutline} />
                  <IonInput
                    value="test@epoch.com"
                    type="text"
                    readonly
                  ></IonInput>
                </IonLabel>
              </a>
            </IonItem>
            <IonItem
              lines="none"
              onClick={() =>
                (window.location.href = "https://wa.me/+911234567890")
              }
            >
              <IonLabel className="customContactUs__cl--label">
                <IonIcon icon={logoWhatsapp} />
                <IonInput value="1234567890" type="text" readonly></IonInput>
              </IonLabel>
            </IonItem>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
