import Menu from "../management/components/Menu";
import React from "react";
import { Home } from "../management/pages/Home";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import "../theme/variables.css";
import "../Fm/pages/Page.css";
import { TrackExpenses } from "../management/pages/TrackExpenses";
import { Profile } from "../management/pages/Profile";
import { Login } from "../Login";
import { ChangePassword } from "../Auth/changePassword";


const MGMT: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/Login" component={Login} />
              <Route path="/ChangePassword" component={ChangePassword} />
              <Route path="/MGMT/Home" component={Home} />
              <Route path="/MGMT/TrackExpenses" component={TrackExpenses} />
              <Route path="/MGMT/Profile" component={Profile} />
              <Redirect from="/" to="/MGMT/Home" />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default MGMT;
