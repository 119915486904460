import React, { useState, useEffect } from "react";
import { Login } from "../../Login";
import { IonContent, IonItem, IonSlide, IonSlides } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonImg,
  IonCol,
  IonAlert,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { get, set } from "../../services/storage";
import "./QueryDetails.css";
import { Plugins, Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import "../../Animations/Animation.css";

export const QueryDetails: React.FC = () => {
  const history = useHistory();
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [queryData, setQueryData] = useState<any>([]);
  const [queryId, setQueryId] = useState<any>();
  const [queryImg, setQueryImg] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlert1, setShowAlert1] = useState<boolean>(false);
  const [stateChanged, setStateChanged] = useState<boolean>(false);

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Queries");
      });
    }
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    get("queryId").then((res) => {
      setQueryId(res);
    });
    const getData = async () => {
      const res = await axios.get("/api/am/query/" + queryId, {
        headers: {
          "x-access-token": token,
        },
      });
      setQueryData(res.data);
      setQueryImg(res.data.bill_image_url);
      if (res) {
        setTimeout(() => {
          setIsDataLoaded(false);
        }, 1000);
      }
    };
    if (token && queryId) {
      getData();
    }
  }, [token, profile, queryId, stateChanged]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  checkIfUserDoesntExist();

  const chkImg = () => {
    var img = document.getElementById("queryImg__fm")?.offsetHeight;
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customQueryDetails__am"
              : "customQueryDetails__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Queries"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Query Details </IonTitle>
          </IonToolbar>

          <IonCard className="customQueryDetails_card">
            <IonGrid>
              {/* <IonRow>
              <IonImg src={queryData?.bill_image_url} />
            </IonRow> */}
              <IonRow>
                <IonSlides pager={true} options={slideOpts}>
                  {queryData?.bill_image_url?.map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__am"
                          // className={
                          //   isImgFullyLoaded
                          //     ? "dontShowImg customImg"
                          //     : "showImg customImg fadeIn"
                          // }
                          src={"https://epoch.theflak.in/" + img}
                          // onLoad={chkImg}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>
              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3> {queryData?.name} </h3>
                    <p> {queryData?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <button id="billdetail-balance">
                        {" "}
                        Rs. {queryData?.amount}
                      </button>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <h6 id="resident-card-display-in-line">
                      {" "}
                      {moment(queryData?.date).format("MMM DD,YYYY")}{" "}
                    </h6>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    <h6> Bill number </h6>
                    <p id="zeromargin" className="customQueryValue">
                      {" "}
                      {queryData?.billNo}{" "}
                    </p>
                    <h6> Description </h6>
                    <p id="zeromargin" className="customQueryValue">
                      {" "}
                      {queryData?.description}{" "}
                    </p>
                    <h6> Message from the client </h6>
                    <p id="zeromargin" className="customQueryValue">
                      {queryData?.query}{" "}
                    </p>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow style={{ float: "right" }}>
                <IonRow className="raiseQueryBtnRow__cl">
                  {queryData?.responded ? ( !queryData?.closed ?
                    <IonItem lines="none" className="raiseQueryBtn__cl">
                      <button
                        style={{
                          padding: "3%",
                          color: "#fff",
                          background: "#c12525",
                          borderRadius: 5,
                        }}
                        onClick={() => setShowAlert1(true)}
                      >
                        Close Query
                      </button>
                    </IonItem> : undefined
                  ) : undefined}
                  <IonItem lines="none" className="raiseQueryBtn__cl">
                    <button
                      style={{
                        padding: "3%",
                        color: "#fff",
                        background: "#c12525",
                        borderRadius: 5,
                      }}
                      // disabled
                      onClick={() => setShowAlert(true)}
                    >
                      Open in Mail
                    </button>
                  </IonItem>
                </IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    {/* <button id="billdetail-balance"> Respond </button> */}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Responding?"}
            message={"Please close the query only when resolved successfully"}
            buttons={[
              {
                text: "Confirm",
                role: "confirm",
                cssClass: "secondary",
                handler: () => {
                  axios
                    .get("/api/am/set-responded/" + queryData._id, {
                      headers: { "x-access-token": token },
                    })
                    .then((res: any) => {
                      if (res.data.done) {
                        var mail = `mailto:${queryData.from}?subject=${
                          "Regarding bill ID: " + queryData.billNo
                        }&cc=aditi@epocheldercare.com&body=Query From Client: ${
                          queryData?.query
                        } \n\n`;
                        var a = document.createElement("a");
                        a.href = mail;
                        a.click();
                        setStateChanged(!stateChanged);
                      }
                    })
                    .catch((err) => {
                      alert("Something went wrong..");
                    });
                },
              },
            ]}
          />
          <IonAlert
            isOpen={showAlert1}
            onDidDismiss={() => setShowAlert1(false)}
            cssClass="my-custom-class"
            header={"Are you sure?"}
            message={"This query will be closed."}
            buttons={[
              {
                text: "Confirm",
                role: "confirm",
                cssClass: "secondary",
                handler: () => {
                  axios
                    .get("/api/am/set-closed/" + queryData._id, {
                      headers: { "x-access-token": token },
                    })
                    .then((res: any) => {
                      if (res.data.done) {
                        setStateChanged(!stateChanged);
                        alert("Query has been marked closed");
                        history.replace("/AM/Queries");
                      }
                    })
                    .catch((err) => {
                      alert("Something went wrong..");
                    });
                },
              },
            ]}
          />
        </IonContent>
      )}
    </IonContent>
  );
};
