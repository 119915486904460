import React, { useState, useEffect } from "react";
import { IonContent, IonImg, IonSlide, IonSlides } from "@ionic/react";
import {
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonCard,
  IonLabel,
  IonList,
  IonItemDivider,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonButton,
} from "@ionic/react";
import {
  walletOutline,
  cashOutline,
  documentLock,
  arrowUpCircle,
  documentSharp,
} from "ionicons/icons";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { get } from "../../services/storage";
import familyImg from "../../assets/family.png";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import aboutImg1 from "../../assets/clientAbout1.jpg";
import aboutImg2 from "../../assets/clientAbout2.jpg";
import aboutImg3 from "../../assets/clientAbout3.jpg";
import "../../Animations/Animation.css";

export const QuickLinks: React.FC = () => {
  const [name, setName] = useState("");
  const [venue, setVenue] = useState("");
  const [userType, setUserType] = useState("");
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [queryData, setqueryData] = useState<[]>([]);
  const [epochId, setEpochId] = useState("");
  const [img, setImg] = useState("");
  const history = useHistory();

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
        setVenue(profileObject.center_name);
        setUserType(profileObject.user_type);
        setEpochId(profileObject.epoch_id);
        setImg(profileObject.img);
      } else {
        setName("UNVERIFIED");
      }
    });
    get("token").then((res) => {
      setToken(res);
    });
  }, [profile, token]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customHome__cl animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle> Home </IonTitle>
      </IonToolbar>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Hi,</IonCardTitle>
          <IonCardSubtitle> You are logged in for {name} </IonCardSubtitle>
          <IonCardSubtitle>Resident, {venue} </IonCardSubtitle>
        </IonCardHeader>

        <IonItem lines="none">
          <IonSlides pager={true} options={slideOpts}>
            <IonSlide>
              <img
                onClick={() => {
                  PhotoViewer.show(aboutImg1);
                }}
                className="img_CL"
                src={"https://epoch.theflak.in/" + img}
                alt="img"
                style={{ objectFit: "cover", height: "13em", width: "100%" }}
              />
            </IonSlide>
            <IonSlide>
              <img
                onClick={() => {
                  PhotoViewer.show(aboutImg2);
                }}
                className="img_CL"
                src={aboutImg2}
                alt="img"
                style={{ objectFit: "cover", height: "13em", width: "100%" }}
              />
            </IonSlide>
            <IonSlide>
              <img
                onClick={() => {
                  PhotoViewer.show(aboutImg3);
                }}
                className="img_CL"
                src={aboutImg3}
                alt="img"
                style={{ objectFit: "cover", height: "13em", width: "100%" }}
              />
            </IonSlide>
          </IonSlides>
        </IonItem>
        <IonCardContent>Here are some Quick Actions for you.</IonCardContent>
        <IonItem
          routerLink="/Client/PettyCash"
          className="ion-activated"
          lines="none"
        >
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={documentSharp} slot="start" />
            </div>
          </div>

          <IonLabel>Check Recent Bills</IonLabel>
        </IonItem>

        <IonItem routerLink="/Client/Invoices" lines="none">
          <div className="image-box">
            <div className="image-container">
              <IonIcon icon={walletOutline} slot="start" />
            </div>
          </div>

          <IonLabel>Download Invoices</IonLabel>
        </IonItem>
      </IonCard>
    </IonContent>
  );
};
