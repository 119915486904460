import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonImg,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { arrowForwardCircleOutline, documentTextOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./ResidentBills.css";
import { Plugins, Capacitor } from "@capacitor/core";
import { ShowRejectedBills } from "../../services/showrejectedbills";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const ResidentBills: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [approvedBills, setApprovedBills] = useState<any>([]);
  const [pendingBills, setPendingBills] = useState<any>([]);
  const [switchButton, setSwitchButton] = useState<any>("Pending");
  const [noData, setNoData] = useState<any>(
    <h2 className="customNoData">Nothing to Display...</h2>
  );
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [pendingPages, setPendingPages] = useState<any>();
  const [approvedPages, setApprovedPages] = useState<any>();
  const [isRejected, setIsRejected] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [residentNames, setResidentNames] = useState<any>();
  const [selectedResident, setSelectedResident] = useState<any>(0);
  const [checkboxState, setCheckboxState] = useState(false);
  const [approvedCount, setApprovedCount] = useState();
  const [pendingCount, setPendingCount] = useState();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedResidentBillDetails(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FM/Home");
      });
    }

    getTokenFromLocalStorage();

    if (token) {
      getResidentBillDetails();
    }
  }, [token, switchButton, selectedResident]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentBillDetails = async () => {
    const res = await axios.get(
      "/api/fm/get-resident-bills/0/" + selectedResident,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setPendingBills(res?.data?.pendingBills);
    setApprovedBills(res?.data?.approvedBills);
    if (switchButton === "Pending") {
      setBillDetails(res?.data?.pendingBills);
    } else {
      setBillDetails(res?.data?.approvedBills);
    }
    setPendingPages(res?.data?.pending_pages);
    setApprovedPages(res?.data?.approved_pages);
    setResidentNames(res?.data?.residents);
    setPendingCount(res?.data?.pendingCount);
    setApprovedCount(res?.data?.approvedCount);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedResidentBillDetails = async (updatePage: Number) => {
    console.log(updatePage);
    const res = await axios.get(
      "/api/fm/get-resident-bills/" +
        // (switchButton === "Pending"
        //   ? pendingCounter.toString()
        //   : approvedCounter.toString()),
        updatePage +
        "/" +
        selectedResident,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setDataAccToSwitch(res?.data);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const billStatusFunc = (e: any) => {
    if (e.toLowerCase() === "approved") {
      setSwitchButton("Approved");
      setDisableCheckbox(true);
      setBillDetails(approvedBills);
      setPage(1);
      if (approvedBills?.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    } else if (e.toLowerCase() === "pending") {
      setSwitchButton("Pending");
      setDisableCheckbox(false);
      // setIsRejected(isRejected ? !isRejected : !isRejected);
      setBillDetails(pendingBills);
      setPage(1);
      if (pendingBills?.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    }
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FRB").then(() => {});
  };

  const setUpdatedPendingBills = (res: any) => {
    // setApiCallCounterPending(apiCallCounterPending + 1);
    // setPendingCounter(pendingCounter + 1);
    // setPendingBills([...pendingBills, ...res.pendingBills]);
    // setBillDetails([...billDetails, ...res.pendingBills]);
    setPendingBills(res?.pendingBills);
    setBillDetails(res?.pendingBills);
  };

  const setUpdatedApprovedBills = (res: any) => {
    // setApiCallCounterApproved(apiCallCounterApproved + 1);
    // setApprovedCounter(approvedCounter + 1);
    // setApprovedBills([...approvedBills, ...res.approvedBills]);
    // setBillDetails([...billDetails, ...res.approvedBills]);
    setApprovedBills(res?.approvedBills);
    setBillDetails(res?.approvedBills);
  };

  const setDataAccToSwitch = (res: any) => {
    if (switchButton === "Pending") {
      setUpdatedPendingBills(res);
      setIsRejected(false);
    } else {
      setUpdatedApprovedBills(res);
      setIsRejected(true);
    }
  };

  checkIfUserDoesntExist();

  const handleCallback = (childData: any) => {
    if (childData?.length === 0) {
      setBillDetails(pendingBills);
      return alert("There are no rejected bills!");
    }
    setBillDetails(childData);
    console.log(childData);
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidentBills__fm"
              : "customResidentBills__fm animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/Fm/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Resident Bills </IonTitle>
          </IonToolbar>

          <IonToolbar>
            <IonSegment
              style={{ color: "red" }}
              color="danger"
              swipeGesture={true}
              value={switchButton}
              onIonChange={(e: any) => billStatusFunc(e.detail.value)}
            >
              <IonSegmentButton value="Pending">Pending</IonSegmentButton>
              <IonSegmentButton value="Approved">Approved</IonSegmentButton>
            </IonSegment>
          </IonToolbar>
          <IonItem lines="none" className="selectHome_fm">
            <IonLabel position="stacked">Select Resident</IonLabel>
            <IonSelect
              value={selectedResident}
              className="customSelectResident"
              placeholder="Select One"
              onIonChange={(e: any) => {
                setSelectedResident(e.detail.value);
                setIsDataLoaded(true);
                // setPendingCounter(1);
                // setApprovedCounter(1);
              }}
            >
              {residentNames?.map((resident: string, key: number) => {
                return (
                  <IonSelectOption key={key} value={resident}>
                    {resident}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <p
            className="totalBills_fm"
            style={{
              background: "#c12525",
              padding: "2%",
              width: "60%",
              margin: "0 auto",
              marginTop: "2%",
              color: "#fff",
              textAlign: "center",
              borderRadius: 18,
            }}
          >
            Total Bills:{" "}
            {switchButton === "Pending" ? pendingCount : approvedCount}
          </p>

          <IonCard>
            {/* <ShowRejectedBills
            disableCheckbox={disableCheckbox}
            dataToShow={pendingBills}
            checkboxState={checkboxState}
            parentCallback={handleCallback}
          /> */}
            {billDetails?.length === 0
              ? noData
              : billDetails?.map((bill: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="2.5">
                            <IonIcon
                              icon={documentTextOutline}
                              style={{ fontSize: 70 }}
                            />
                          </IonCol>
                          <IonCol size="6">
                            <div className="resident-card">
                              <h3> {bill.name} </h3>
                              <div className="rb-pTag">
                                <p className="">
                                  {" "}
                                  {moment(bill?.bill_date).format(
                                    "MMM DD,YYYY"
                                  )}
                                </p>
                                <p className=""> {bill?.category} </p>
                              </div>
                              <h6>
                                {" "}
                                Rs.{" "}
                                {bill?.amount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </h6>
                            </div>
                          </IonCol>
                          <IonCol size="3" className="customResidentView">
                            <div
                              className="resident-card"
                              style={{ marginTop: 10 }}
                            >
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  setBillId(bill?._id);
                                  history.push("/FM/BillDetails");
                                }}
                              >
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowForwardCircleOutline}
                                ></IonIcon>
                                <p>View Details</p>
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })}
            {/* <div className="viewMoreBtn__fm">
            {" "}
            <button
              onClick={() => {
                viewMoreData();
              }}
            >
              View More
            </button>
          </div> */}
            {(pendingPages > 1 && switchButton === "Pending") ||
            (approvedPages > 1 && switchButton === "Approved") ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={
                    switchButton === "Pending" ? pendingPages : approvedPages
                  }
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
