import React, { useState, useEffect } from "react";
import { IonContent, IonImg, IonInput, IonItem, IonLabel } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import "./Profile.css";
import {
  personOutline,
  cardOutline,
  playOutline,
  callOutline,
  fileTrayFullOutline,
  mailOutline,
} from "ionicons/icons";
import profileImg from "../../assets/profileImg.png";
import { get } from "../../services/storage";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { Plugins, Capacitor } from "@capacitor/core";
import "../../Animations/Animation.css"

export const Profile: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [center, setCenter] = useState("");
  const [id, setId] = useState("");
  const [contact, setContact] = useState("");
  const [profile, setProfile] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }
    get("profile").then((res) => {
      setProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
        setEmail(profileObject.email);
        setCenter(profileObject.center_name);
        setContact(profileObject.phone);
        setId(profileObject.epoch_id);
        setTimeout(() => {
          setIsDataLoaded(false);
        }, 1000);
      }
    });
  }, [profile]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  const chkImg = () => {
    var img = document.getElementById("profileImg__mgmt")?.offsetHeight;
    if (img !== 0) {
      setIsImgFullyLoaded(false);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={isDataLoaded ? "customProfile__mgmt" : "customProfile__mgmt animated"}
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/MGMT/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Profile </IonTitle>
          </IonToolbar>

          <IonCard>
            {/* <IonItem lines="none">
          <div className="profileImg__mgmt--wrapper">
            <img
              id="profileImg__mgmt"
              className={
                isImgFullyLoaded
                  ? "dontShowImg profileImg__mgmt"
                  : "showImg profileImg__mgmt fadeIn"
              }
              src={profileImg}
              onLoad={chkImg}
              alt="img"
            />
          </div>
        </IonItem> */}
            <IonItem lines="none">
              <IonLabel className="customProfile__mgmt--label">
                <IonIcon icon={personOutline} />
                <IonInput value={name} type="text" readonly></IonInput>
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <a href={"tel:" + contact}>
                <IonLabel className="customProfile__mgmt--label">
                  <IonIcon icon={callOutline} />
                  <IonInput value={contact} type="text" readonly></IonInput>
                </IonLabel>
              </a>
            </IonItem>
            <IonItem lines="none">
              <a href={"mailto:" + email}>
                <IonLabel className="customProfile__mgmt--label">
                  <IonIcon icon={mailOutline} />
                  <IonInput value={email} type="text" readonly></IonInput>
                </IonLabel>
              </a>
            </IonItem>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
