import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert,
} from "@ionic/react";
import { trashBin } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import { get } from "../../services/storage";
import { useHistory } from "react-router-dom";
import Loading from "../../constants/loading";
import "./BillDetails.css";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@ionic-native/file-transfer";
import { File } from "@ionic-native/file";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import "../../Animations/Animation.css";

export const BillDetails: React.FC = () => {
  const history = useHistory();
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [billId, setBillId] = useState<any>();
  const [whichRoute, setWhichRoute] = useState<any>();
  const [billImg, setBillImg] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const [file, setFile] = React.useState<string | undefined>();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [amountEdit, setAmountEdit] = useState<string>();
  const [billNoEdit, setBillNoEdit] = useState<string>();
  const [descriptionEdit, setDescriptionEdit] = useState<string>();

  const [editAlert, setEditAlert] = useState(false);
  const [editDone, setEditDone] = useState(false);

  useEffect(() => {
    getTokenFromLocalStorage();
    getBillIdFromLocalStorage();

    if (token && billId) {
      getBillDetails();
    }
  }, [billId, token, isDataLoaded]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getBillIdFromLocalStorage = () => {
    get("billId").then((res) => {
      setBillId(res);
    });
  };

  const getBillDetails = async () => {
    const res = await axios.get("/api/fm/bill/" + billId, {
      headers: {
        "x-access-token": token,
      },
    });

    setBillDetails(res?.data);
    setAmountEdit(res?.data?.amount);
    setBillNoEdit(res?.data?.billNo);
    setDescriptionEdit(res?.data?.description);
    setBillImg(res?.data?.image_url);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const checkIfImageIsLoaded = () => {
    var img = document.getElementById("billImg")?.offsetHeight;
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  const getWhichRouteToGoBackFromLocalStorage = () => {
    get("whichRoute").then((res) => {
      setWhichRoute(res);
      if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e) => {
          if (whichRoute === "FRB") {
            history.replace("/FM/ResidentBills");
          } else if (whichRoute === "FRD") {
            history.replace("/FM/ResidentDetails");
          } else if (whichRoute === "FHPC") {
            history.replace("/FM/Expenses");
          } else if (whichRoute === "FRJB") {
            history.replace("/FM/RejectedBills");
          }
        });
      }
    });
  };

  const goBack = () => {
    if (whichRoute === "FRB") {
      history.replace("/FM/ResidentBills");
    } else if (whichRoute === "FRD") {
      history.replace("/FM/ResidentDetails");
    } else if (whichRoute === "FHPC") {
      history.replace("/FM/Expenses");
    } else if (whichRoute === "FRJB") {
      history.replace("/FM/RejectedBills");
    }
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const deleteBill = async () => {
    setIsDataLoaded(true);
    const res = await axios.get("/api/fm/delete/" + billId, {
      headers: {
        "x-access-token": token,
      },
    });
    if (res?.data?.deleted) {
      setShowErrorAlert(true);
      setIsDataLoaded(false);
      history.replace("/FM/ResidentBills");
    } else {
      setIsDataLoaded(false);
      setShowErrorAlert(true);
      setIsError(true);
    }
  };

  const sendEdit = async () => {
    setIsEdit(false);
    setIsDataLoaded(true);
    const data = {
      amountEdit,
      billNoEdit,
      descriptionEdit,
    };

    const res = await axios.request({
      method: "POST",
      url: "https://epoch.theflak.in/api/fm/edit-bill/" + billId,
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: JSON.stringify(data),
    });
    if (res?.data?.done) {
      setEditDone(true);
      setIsDataLoaded(false);
      setEditAlert(true);
    } else {
      alert("Cannot Edit, Bill has been approved")
    }
  };

  checkIfUserDoesntExist();
  getWhichRouteToGoBackFromLocalStorage();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customBillDetails__fm"
              : "customBillDetails__fm animated"
          }
        >
          <IonAlert
            isOpen={showErrorAlert}
            onDidDismiss={() => setShowErrorAlert(false)}
            cssClass="my-custom-class"
            message={
              isError
                ? "Bill hasn't been deleted Successfully!."
                : "Bill has been deleted Successfully!"
            }
            buttons={["OK"]}
          />
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            message={"<strong>Are you sure?</strong>"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  setShowAlert(false);
                },
              },
              {
                text: "Okay",
                handler: () => {
                  deleteBill();
                },
              },
            ]}
          />
          <IonAlert
            isOpen={editAlert}
            onDidDismiss={() => setEditAlert(false)}
            cssClass="my-custom-class"
            header={"Alert"}
            subHeader={""}
            message={
              editDone
                ? "Edited Successfully"
                : "Edit Failed, Please try after some time"
            }
            buttons={["OK"]}
          />
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                onClick={goBack}
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Bill Details </IonTitle>
          </IonToolbar>

          <IonCard className="fadeIn">
            <IonGrid>
              <IonRow>
                <IonSlides pager={true} options={slideOpts}>
                  {billImg?.map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__fm"
                          src={"https://epoch.theflak.in/" + img}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>
              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3 style={{ fontSize: 20 }}> {billDetails?.name} </h3>
                    <p style={{ fontSize: 12 }}> {billDetails?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <p className="amountTag" id="billdetail-balance">
                        {" "}
                        {!isEdit ? "Rs." : "Edit Rs."}{" "}
                        {!isEdit ? (
                          billDetails?.amount
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        ) : (
                          <IonInput
                            type="text"
                            autoCapitalize="on"
                            value={amountEdit}
                            onIonChange={(e: any) =>
                              setAmountEdit(e.detail.value)
                            }
                          ></IonInput>
                        )}{" "}
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <div>
                      <p style={{ fontSize: 14 }}>Date</p>
                      <h6
                        style={{ color: "#333333", fontSize: 16 }}
                        id="resident-card-display-in-line"
                      >
                        {moment(billDetails?.bill_date).format("MMM DD,YYYY")}
                      </h6>
                    </div>
                    <div>
                      <p style={{ fontSize: 14 }}>Category</p>
                      <h6 style={{ color: "#333333", fontSize: 16 }}>
                        {" "}
                        {billDetails?.category}{" "}
                      </h6>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                  { billDetails?.vendor_name && 
                    <>
                    <h6> Vendor </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.vendor_name}
                    </p>
                    </>
                    }
                    <h6> {!isEdit ? "Bill number" : "Edit Bill number"} </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {!isEdit ? (
                        billDetails?.billNo
                      ) : (
                        <IonInput
                          type="text"
                          autoCapitalize="on"
                          value={billNoEdit}
                          onIonChange={(e: any) =>
                            setBillNoEdit(e.detail.value)
                          }
                        ></IonInput>
                      )}
                    </p>
                    <h6> {!isEdit ? "Description" : "Edit Description"} </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {!isEdit ? (
                        billDetails?.description
                      ) : (
                        <IonInput
                          type="text"
                          autoCapitalize="on"
                          value={descriptionEdit}
                          onIonChange={(e: any) =>
                            setDescriptionEdit(e.detail.value)
                          }
                        ></IonInput>
                      )}
                    </p>
                    <h6> Status </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.status.charAt(0).toUpperCase() +
                        billDetails?.status.slice(1)}{" "}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {billDetails.status === "pending" ? (
                        <>
                          <button
                            style={{
                              marginRight: "1em",
                              padding: 10,
                              background: "#A91D2A",
                              borderRadius: 5,
                              color: "#fff",
                            }}
                            onClick={() => setIsEdit(!isEdit)}
                          >
                            {isEdit ? "Cancel" : "Edit"}
                          </button>
                          {isEdit ? (
                            <button
                              style={{
                                marginRight: "1em",
                                padding: 10,
                                background: "#A91D2A",
                                borderRadius: 5,
                                color: "#fff",
                              }}
                              onClick={() => sendEdit()}
                            >
                              Done
                            </button>
                          ) : null}
                          <button
                            style={{
                              marginRight: "1em",
                              padding: 10,
                              background: "#A91D2A",
                              borderRadius: 5,
                              color: "#fff",
                            }}
                            onClick={() => setShowAlert(true)}
                          >
                            Delete
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {billDetails.status === "rejected" ? (
                      <div>
                        {" "}
                        <h6> Reason for rejection </h6>
                        <p id="zeromargin" className="customBillValue">
                          {" "}
                          {billDetails?.reason}{" "}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
