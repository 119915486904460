import React, { useState, useEffect } from "react";
import {
  IonBackButton,
  IonContent,
  IonCard,
  IonToolbar,
  IonButtons,
  IonItem,
  IonMenuButton,
  IonTitle,
  IonInput,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonFabButton,
  IonButton,
  IonTextarea,
  IonIcon,
  IonDatetime,
  IonFab,
  IonCol,
  IonAlert,
  IonImg,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import { useHistory } from "react-router-dom";
import { Login } from "../../Login";
import "./BillUpload.css";
import { get, set, clear } from "../../services/storage";
import { camera, trash, close } from "ionicons/icons";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import Loading from "../../constants/loading";
import { Plugins, Capacitor } from "@capacitor/core";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import "../../Animations/Animation.css"
import moment from "moment";

export const BillUpload: React.FC = () => {
  const history = useHistory();
  const [amount, setAmount] = useState<number>();
  const [image, setImage] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [userReference, setUserReference] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [billDate, setBillDate] = useState<any>();
  const {
    photosBase64,
    photos,
    takePhoto,
    photoFormat,
    setPhotoArrayEmpty,
  } = usePhotoGallery();
  const [whichType, setWhichType] = useState<any>();
  const [categoryName, setCategoryName] = useState<any>();
  const [subCategory, setSubCategory] = useState<any>();
  const [subCategoryName, setSubCategoryName] = useState<any>();
  const [expense, setExpense] = useState<any>();
  const [expenseName, setExpenseName] = useState<any>();
  const [serverData, setServerData] = useState<any>();
  const [slab, setSlab] = useState<any>();
  const [profileJson, setProfileJson] = useState<any>();
  const [btnDisabled, setBtnDisabled] = useState<any>(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [billNo, setBillNo] = useState<any>("");
  const [isBillUploaded, setIsBillUploaded] = useState(false);
  const currentDate = new Date();
  const [showImageCropModal, SetshowImageCropModal] = useState(false);
  const [cropper, setCropper] = useState<any>();
  const [cropData, setCropData] = useState([]);
  const [balanceLeft, setBalanceLeft] = useState(0);

  useEffect(() => {
    if (photosBase64.length !== 0) SetshowImageCropModal(true);
  }, [photosBase64]);
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FA/Home");
      });
    }
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    setUserReference(profile);

    if (token && profile) {
      const profileJson = JSON.parse(profile);
      setProfileJson(profileJson);
      const getData = async () => {
        const res = await axios.get("/api/fa/get-categories", {
          headers: {
            "x-access-token": token,
          },
        });
        setServerData(res.data);
      };
      getData();
    }
  }, [token, profile, image]);

  const handleSubmit = async (e: any) => {
    console.log(photoFormat, photosBase64);
    e.preventDefault();
    if (
      !billDate ||
      !amount ||
      cropData.length === 0 ||
      !description ||
      !slab ||
      !billNo ||
      !expenseName ||
      !categoryName ||
      !subCategoryName
    ) {
      alert("Please fill all the fields!");
    } else {
      setIsBillUploaded(true);
      console.log("Approved");
      const email = JSON.parse(userReference !== null ? userReference : "{}")
        .email;
      const data = {
        billDate,
        category: categoryName,
        subCategory: subCategoryName,
        expenseType: expenseName,
        amount,
        description,
        user_reference: email,
        base64String: cropData,
        format: photoFormat,
        taxSlab: slab,
        billNo,
      };
      const res = await axios.request({
        method: "POST",
        url: "https://epoch.theflak.in/api/fa/upload-bill",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      });
      if (res?.data?.uploaded) {
        setIsBillUploaded(false);
        setBtnDisabled(false);
        setShowAlert(true);
        setBillDate("");
        setCategoryName("");
        setSubCategoryName("");
        setExpenseName("");
        setAmount(undefined);
        setCropData([]);
        setDescription("");
        setSlab("0");
        setBillNo(undefined);
      } else {
        setIsBillUploaded(false);
        alert(res.data);
      }
    }
  };

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      const profile = JSON.parse(res);
      if (profile?.user_type !== "FA") {
        clear().then(() => history.replace("/Login"));
      }
    });
  };

  checkIfUserDoesntExist();

const getCropData = () => {
  SetshowImageCropModal(false);
  if (typeof cropper !== "undefined") {
    base64ToBlob(cropper.getCroppedCanvas().toDataURL().slice(22));
  }
};

const base64ToBlob = async (base64: any) => {
  const base64Response = await fetch(`data:image/png;base64,${base64}`);
  const blob = await base64Response.blob();
  if (blob) {
    const newCropImage = imageDegrade(blob);
    newCropImage.then((res: any) =>
      setCropData(cropData.concat(res.slice(22)))
    );
  }
};

const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      photoFormat!,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
      500,
      500
    );
  });

const imageDegrade = async (file: any) => {
  try {
    const resizedImage = await resizeFile(file);
    console.log(resizedImage);
    return resizedImage;
  } catch (err) {
    console.log(err);
  }
};

  return (
    <IonContent>
      {isBillUploaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isBillUploaded
              ? "customBillUpload__fa"
              : "customBillUpload__fa animated"
          }
        >
          <IonCard
            style={{
              display: showImageCropModal ? "block" : "none",
              position: "absolute",
              zIndex: 10,
              top: 84,
              width: "100%",
              height: "100%",
              background: "#fff",
              margin: 0,
              borderRadius: 0,
            }}
          >
            <Cropper
              src={photosBase64[photosBase64.length - 1]}
              style={{ height: 300, width: "100%" }}
              zoomTo={0}
              preview=".img-preview"
              viewMode={1}
              guides={true}
              minCropBoxHeight={100}
              minCropBoxWidth={100}
              zoomable={false}
              background={false}
              responsive={true}
              // autoCropArea={1}
              draggable={false}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 auto",
              }}
            >
              {/* <button
                style={{
                  background: "#c12525",
                  color: "#fff",
                  padding: "3%",
                  width: "50%",
                  fontSize: 18,
                }}
                onClick={() => {
                  SetshowImageCropModal(false);
                  setCropData(
                    cropData.concat(photosBase64[photosBase64.length - 1])
                  );
                }}
              >
                Cancel
              </button> */}
              <button
                style={{
                  background: "#c12525",
                  color: "#fff",
                  padding: "3%",
                  width: "100%",
                  fontSize: 18,
                }}
                onClick={() => {
                  SetshowImageCropModal(false);
                  getCropData();
                }}
              >
                Okay
              </button>
            </div>
          </IonCard>
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle> Upload a Bill </IonTitle>
          </IonToolbar>
          ​
          <IonCard>
            <IonItem lines="none">
              <IonLabel position="stacked">Date</IonLabel>
              <IonDatetime
                className="customInputFields"
                value={billDate}
                max={moment().format()}
                onIonChange={(e: any) => setBillDate(e.detail.value)}
              ></IonDatetime>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">GST Slab</IonLabel>
              <IonSelect
                placeholder="Select One"
                onIonChange={(e: any) => {
                  setSlab(e.detail.value);
                }}
              >
                <IonSelectOption value="0">0 %</IonSelectOption>
                <IonSelectOption value="5">5 %</IonSelectOption>
                <IonSelectOption value="12">12 %</IonSelectOption>
                <IonSelectOption value="18">18 %</IonSelectOption>
                <IonSelectOption value="28">28 %</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Category</IonLabel>
              <IonSelect
                className="customInputFields"
                disabled={whichType}
                placeholder="Select One"
                onIonChange={(e: any) => {
                  setSubCategory(e.detail.value.sub_categories);
                  setCategoryName(e.detail.value.category_name);
                }}
              >
                {serverData?.categories &&
                  serverData?.categories.map((category: any, key: any) => {
                    return (
                      <IonSelectOption value={category} key={key}>
                        {category.category_name}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Sub Category</IonLabel>
              <IonSelect
                className="customInputFields"
                disabled={whichType}
                placeholder="Select One"
                onIonChange={(e: any) => {
                  setExpense(e.detail.value.expenses);
                  setSubCategoryName(e.detail.value.sub_cat_name);
                }}
              >
                {subCategory?.map((subCategory: any, key: any) => {
                  return (
                    <IonSelectOption value={subCategory} key={key}>
                      {subCategory.sub_cat_name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Expense Type</IonLabel>
              <IonSelect
                className="customInputFields"
                placeholder="Select One"
                onIonChange={(e: any) => {
                  setExpenseName(e.detail.value);
                  setBalanceLeft(
                    e.detail.value.budget - e.detail.value.expenditure
                  );
                }}
              >
                {expense?.map((expense: any, key: any) => {
                  return (
                    <IonSelectOption value={expense} key={key}>
                      {expense?.expense}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
              <p
                className="balanceLeft_fa"
                style={{
                  marginTop: 4,
                  background: "rgb(193, 37, 37)",
                  color: "#fff",
                  padding: "2%",
                  borderRadius: 5,
                  fontSize: 12,
                }}
              >
                Balance left:{" "}
                {balanceLeft?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </p>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Enter an Amount</IonLabel>
              <IonInput
                className="customInputFields"
                type="number"
                autoCapitalize="on"
                value={amount}
                onIonChange={(e) => setAmount(parseInt(e.detail.value!, 10))}
              ></IonInput>
            </IonItem>
            <IonItem lines="none">
              <IonLabel position="stacked">Enter Bill Number</IonLabel>
              <IonInput
                className="customInputFields"
                type="text"
                autoCapitalize="on"
                value={billNo}
                onIonChange={(e) => setBillNo(e.detail.value)}
              ></IonInput>
            </IonItem>
            ​
            <IonItem
              lines="none"
              disabled={photosBase64.length < 8 ? false : true}
              onClick={() => {
                takePhoto();
              }}
              className="uploadPicture"
            >
              <IonLabel>
                {photosBase64?.length === 0
                  ? "Upload a picture"
                  : "Add more pictures"}
              </IonLabel>
              <IonIcon icon={camera}></IonIcon>
            </IonItem>
            ​
            <p
              className="totalImages_fa"
              style={{
                marginTop: 4,
                background: "rgb(193, 37, 37)",
                color: "#fff",
                padding: "2%",
                borderRadius: 5,
                fontSize: 12,
                width: "29%",
                margin: "-3% 0 0 5%",
              }}
            >
              Total Images: {cropData.length}
            </p>
            <IonItem lines="none" className="customBillUploadMarginTop">
              <IonLabel position="stacked">Description</IonLabel>
              <IonTextarea
                className="customInputFields"
                autoCapitalize="on"
                value={description}
                onIonChange={(e) => setDescription(e.detail.value!)}
                required
              ></IonTextarea>
            </IonItem>
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => {
                setShowAlert(false);
              }}
              cssClass="my-custom-class"
              header={"Your bill has been successfully uploaded!"}
              buttons={["OK"]}
            />
            <IonItem lines="none">
              <button
                className="submitBill"
                onClick={(e: any) => {
                  handleSubmit(e);
                }}
              >
                {" "}
                Submit{" "}
              </button>
            </IonItem>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
