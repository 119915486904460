import React, { useState, useEffect } from "react";
import { IonContent, IonSearchbar } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from "@ionic/react";
import { arrowForwardCircleOutline, personCircleOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import { Plugins, Capacitor } from "@capacitor/core";
import "./Residents.css";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";
// import { BillDetails } from "./dist/dist/BillDetails.dev";

export const Residents: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [residents, setResidents] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [total, setTotal] = useState<any>();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState<any>("");
  const [text, setText] = useState<any>(0);

  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedResidentDetails(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FM/Home");
      });
    }

    getTokenFromLocalStorage();

    if (token) {
      getResidentDetails();
    }
  }, [token, text]);

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentDetails = async () => {
    const res = await axios.get("/api/fm/get-residents/0/" + text, {
      headers: {
        "x-access-token": token,
      },
    });

    let x = res?.data?.total;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);

    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }

    setTotal(otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree);

    setResidents(res?.data?.residents?.resident_details);
    setTotalPages(res?.data?.pages);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedResidentDetails = async (updatePage: Number) => {
    const res = await axios.get(
      "/api/fm/get-residents/" + updatePage + "/" + text,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setResidents(res?.data?.residents?.resident_details);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getSearchData = async (updatePage: Number) => {
    const res = await axios.get("/search/fm/" + searchText + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setResidents(res?.data?.residents?.resident_details);
  };

  // const viewMoreData = () => {
  //   if (totalPages - 1 < apiCallCounter) {
  //     return alert("No More Data to show");
  //   }
  //   setIsDataLoaded(true);
  //   setApiCallCounter(apiCallCounter + 1);
  //   getPaginatedResidentDetails();
  // };

  const setResidentDetails = (params: any) => {
    history.push("/FM/ResidentDetails");
    console.log(history);
    set("residentId", params?.epoch_id).then((res) => {
      console.log(res);
    });
    set("residentName", params?.resident_name).then((res) => {
      console.log(res);
    });
  };

  checkIfUserDoesntExist();

  const handleSearch = (value: any) => {
    setSearchText(value);
    if (value === "") {
      setText(0);
    } else {
      setText(value);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidents__fm"
              : "customResidents__fm animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/FM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Residents </IonTitle>
          </IonToolbar>
          <IonSearchbar
            className="search_fm"
            value={searchText}
            onIonChange={(e) => handleSearch(e.detail.value!)}
            placeholder="Search Residents"
            debounce={300}
          ></IonSearchbar>

          <p className="totalBalance_fm" id="tpc">
            Total Petty Cash: &#8377;
            {total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>

          <IonCard>
            {/* <IonSearchbar
            value={searchText}
            animated={true}
            debounce={0}
            onIonChange={(e) => {
              setSearchText(e.detail.value!)
              console.log(e.detail.value)
            }}
            showCancelButton="always"

          ></IonSearchbar> */}

            {totalPages === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to show</h4>
            ) : (
              residents?.map((resident: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="2">
                          <IonIcon
                            style={{ fontSize: 50 }}
                            icon={personCircleOutline}
                          />
                        </IonCol>
                        <IonCol size="7">
                          <div className="resident-card">
                            <h3> {resident?.resident_name} </h3>
                            {/* <p> {resident?.epoch_id} </p> */}
                            <h6>
                              Balance left:
                              <span>
                                {" "}
                                {resident?.balance_left
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </span>
                            </h6>
                          </div>
                        </IonCol>
                        <IonCol size="3" className="customResidentView">
                          <div
                            className="resident-card"
                            style={{ marginTop: 10 }}
                          >
                            <div
                              className="view-details-right"
                              onClick={() => {
                                setResidentDetails(resident);
                                console.log(history);
                              }}
                            >
                              <IonIcon
                                style={{ fontSize: 36 }}
                                icon={arrowForwardCircleOutline}
                              ></IonIcon>
                              <p>View Details</p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {/* {residents?.length >= 10 ? (
            <div className="viewMoreBtn__fm">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
