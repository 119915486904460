import axios from "axios";

// Defining base API URL for global use
// const baseURL = "http://127.0.0.1:5000";
const baseURL = "https://epoch.theflak.in";

const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Exporting for global use

export default axiosInstance;
