import React, { useState, useEffect } from "react";
import { IonContent, IonDatetime, IonItem } from "@ionic/react";
import {
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import Spinner from "../../constants/spinner";
import "./Home.css";
import { useHistory } from "react-router-dom";
import { get, set, clear } from "../../services/storage";
import { Plugins, Capacitor } from "@capacitor/core";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import Loading from "../../constants/loading";
import "../../Animations/Animation.css";

// import image from "../../invoice/dfd38aea-69fd-11eb-8b25-0cc47a792c0a_id_dfd38aea-69fd-11eb-8b25-0cc47a792c0a_files/background1.png"

export const Home: React.FC = () => {
  const [name, setName] = useState("");
  // const [venue, setVenue] = useState("");
  // const [userType, setUserType] = useState("");
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [month, setMonth] = useState<any>(moment().format("M"));
  const [year, setYear] = useState<any>(moment().format("YYYY"));
  const [expense, setExpense] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [dateChangeCounter, setDateChangeCounter] = useState(0);
  const [isLoaded, setIsLoaded] = useState<any>(true);
  const [date, setDate] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
        // setVenue(profileObject.center_name);
        // setUserType(profileObject.user_type);
      } else {
        setName("UNVERIFIED");
      }
    });
    get("token").then((res) => {
      setToken(res);
    });
    if (token) {
      getManagementData();
    }
  }, [profile, token, dateChangeCounter]);

  const getManagementData = async () => {
    const res = await axios.get("/api/mgmt/get-pnl/" + month + "/" + year, {
      headers: {
        "x-access-token": token,
      },
    });
    if (res) {
      setIsLoaded(false);
      setExpense(res?.data?.expense);
      setRevenue(res?.data?.revenue);
      setTimeout(() => {}, 1000);
    }
  };

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      const profile = JSON.parse(res);
      if (profile?.user_type !== "MGMT") {
        clear().then(() => history.replace("/Login"));
      }
    });
  };

  checkIfUserDoesntExist();

  const data = {
    labels: ["Total"],
    options: {
      legend: {
        display: false,
      },
    },
    datasets: [
      {
        label: "Expense",

        data: [expense],
        backgroundColor: "#c12525",
      },
      {
        label: "Revenue",
        data: [revenue],
        backgroundColor: "#bbbbbb",
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <IonContent>
      {isLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isLoaded ? "customHome__mgmt" : "customHome__mgmt animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle> Home </IonTitle>
          </IonToolbar>

          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Hi {name},</IonCardTitle>
              <IonCardSubtitle> How are you doing today? </IonCardSubtitle>
            </IonCardHeader>
          </IonCard>

          <IonCard className="graphCard_mgmt">
            <IonItem>
              <IonDatetime
              value={date}
                placeholder="Select Month and Year"
                displayFormat="MMMM, YYYY"
                max={new Date(
                  new Date().setMonth(new Date().getMonth())
                ).toISOString()}
                onIonChange={(e: any) => {
                  setIsLoaded(true);
                  setDate(moment(e.detail.value).format("MMM, YYYY"));
                  setMonth(moment(e.detail.value).format("M"));
                  setYear(moment(e.detail.value).format("YYYY"));
                  setDateChangeCounter(dateChangeCounter + 1);
                }}
              />
            </IonItem>
            <Bar height={350} data={data} options={options} />
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
