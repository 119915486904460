import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonImg,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { arrowForwardCircleOutline, documentTextOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./FacilityInvoices.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const FacilityInvoices: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  const [home, setHome] = useState<any>();
  const [centerName, setCenterName] = useState(0);
  const [billDetails, setBillDetails] = useState<any>([]);
  const [approvedBills, setApprovedBills] = useState<any>([]);
  const [pendingBills, setPendingBills] = useState<any>([]);
  const [switchButton, setSwitchButton] = useState<any>("Pending");
  const [noData, setNoData] = useState<any>(
    <h2 className="customNoData">Nothing to Display...</h2>
  );
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [pendingPages, setPendingPages] = useState<any>();
  const [approvedPages, setApprovedPages] = useState<any>();
  const [pendingCount, setPendingCount] = useState();
  const [approvedCount, setApprovedCount] = useState();
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  // const [apiCallCounterPending, setApiCallCounterPending] = useState<any>(1);
  // const [apiCallCounterApproved, setApiCallCounterApproved] = useState<any>(1);
  // const [pendingCounter, setPendingCounter] = useState<any>(1);
  // const [approvedCounter, setApprovedCounter] = useState<any>(1);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedResidentInvoices(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }

    getProfileFromLocalStorage();
    if (profile) {
      const profileObject = JSON.parse(profile);
      setHome(profileObject?.centers);
    }
    getTokenFromLocalStorage();

    if (token) {
      getResidentInvoices();
    }
  }, [token, centerName, switchButton]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getProfileFromLocalStorage = () => {
    get("profile").then((res) => {
      setProfile(res);
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentInvoices = async () => {
    const res = await axios.get(
      "/api/am/facility-invoices/" + centerName + "/0",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setPendingBills(res?.data?.pending);
    setApprovedBills(res?.data?.approved);
    if (switchButton === "Pending") {
      setBillDetails(res?.data?.pending);
    } else {
      setBillDetails(res?.data?.approved);
    }
    setPendingPages(res?.data?.pending_pages);
    setApprovedPages(res?.data?.approved_pages);
    setPendingCount(res?.data?.pendingCount);
    setApprovedCount(res?.data?.approvedCount);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedResidentInvoices = async (updatePage: Number) => {
    console.log("getPaginatedData");
    const res = await axios.get(
      "/api/am/facility-invoices/" +
        centerName +
        "/" +
        // (switchButton === "Pending" ? pendingCounter : approvedCounter),
        updatePage,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setDataAccToSwitch(res.data);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const billStatusFunc = (e: any) => {
    if (e.toLowerCase() === "approved") {
      setSwitchButton("Approved");
      setBillDetails(approvedBills);
      if (approvedBills?.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    } else if (e.toLowerCase() === "pending") {
      setSwitchButton("Pending");
      setBillDetails(pendingBills);
      if (pendingBills?.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    }
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
  };

  const setUpdatedPendingBills = (res: any) => {
    // setApiCallCounterPending(apiCallCounterPending + 1);
    // setPendingCounter(pendingCounter + 1);
    // setPendingBills([...pendingBills, ...res.pending]);
    // setBillDetails([...billDetails, ...res.pending]);
    setPendingBills(res?.pending);
    setBillDetails(res?.pending);
  };

  const setUpdatedApprovedBills = (res: any) => {
    // setApiCallCounterApproved(apiCallCounterApproved + 1);
    // setApprovedCounter(approvedCounter + 1);
    // setApprovedBills([...approvedBills, ...res.approved]);
    // setBillDetails([...billDetails, ...res.approved]);
    setApprovedBills(res?.approved);
    setBillDetails(res?.approved);
  };
  const setDataAccToSwitch = (res: any) => {
    if (switchButton === "Pending") {
      setUpdatedPendingBills(res);
    } else {
      setUpdatedApprovedBills(res);
    }
  };

  // const viewMoreData = () => {
  //   if (pendingPages - 1 < pendingCounter && switchButton === "Pending") {
  //     return alert("No More Data to show");
  //   } else if (
  //     approvedPages - 1 < approvedCounter &&
  //     switchButton === "Approved"
  //   ) {
  //     return alert("No More Data to show");
  //   } else {
  //     setIsDataLoaded(true);
  //     // setApiCallCounter(apiCallCounter + 1);
  //     if (switchButton === "Pending") {
  //       setPendingCounter(pendingCounter + 1);
  //     } else {
  //       setApprovedCounter(approvedCounter + 1);
  //     }
  //     getPaginatedResidentInvoices();
  //   }
  // };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customFacilityInvoices__am"
              : "customFacilityInvoices__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Facility Expenses </IonTitle>
          </IonToolbar>

          <IonToolbar>
            <IonSegment
              color="danger"
              value={switchButton}
              onIonChange={(e: any) => billStatusFunc(e.detail.value)}
            >
              <IonSegmentButton value="Pending">Pending</IonSegmentButton>
              <IonSegmentButton value="Approved">Approved</IonSegmentButton>
            </IonSegment>
          </IonToolbar>
          <IonItem lines="none" className="selectHome_am">
            <IonLabel position="stacked">Select Home</IonLabel>
            <IonSelect
              value={centerName}
              placeholder="Select One"
              onIonChange={(e: any) => {
                setCenterName(e.detail.value);
                setIsDataLoaded(true);
                // setPendingCounter(1)
                // setApprovedCounter(1)
              }}
            >
              {home?.map((home: string, key: number) => {
                return (
                  <IonSelectOption key={key} value={home}>
                    {home}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <p
            className="totalBills_am"
            style={{
              background: "#c12525",
              padding: "2%",
              width: "60%",
              margin: "0 auto",
              marginTop: "2%",
              color: "#fff",
              textAlign: "center",
              borderRadius: 18,
            }}
          >
            Total Bills:{" "}
            {switchButton === "Pending" ? pendingCount : approvedCount}
          </p>

          <IonCard>
            {billDetails.length === 0
              ? noData
              : billDetails?.map((bill: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="2">
                            <IonIcon
                              style={{ fontSize: 60 }}
                              icon={documentTextOutline}
                            />
                          </IonCol>
                          <IonCol size="7">
                            <div className="resident-card">
                              <h3> {bill?.bill?.expense_type?.expense} </h3>
                              <div className="rb-pTag">
                                <p className="">
                                  {" "}
                                  {moment(bill?.bill?.bill_date).format(
                                    "MMM DD, YYYY"
                                  )}{" "}
                                </p>
                                <p className=""> {bill?.bill?.category} </p>
                              </div>
                              <h6>
                                {" "}
                                Rs.{" "}
                                {bill?.bill?.amount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </h6>
                            </div>
                          </IonCol>
                          <IonCol size="3" className="customResidentView">
                            <div
                              className="resident-card"
                              style={{ marginTop: 10 }}
                            >
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  set("whichRoute", "FFI").then(() => {});
                                  if (switchButton === "Pending") {
                                    setBillId(bill?.bill?._id);
                                    history.push("/AM/BillAction");
                                  } else {
                                    setBillId(bill?.bill?._id);
                                    history.push("/AM/ResidentBillDetails");
                                  }
                                }}
                              >
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowForwardCircleOutline}
                                ></IonIcon>
                                <p>View Details</p>
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })}
            {/* {billDetails.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {(pendingPages > 1 && switchButton === "Pending") ||
            (approvedPages > 1 && switchButton === "Approved") ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={
                    switchButton === "Pending" ? pendingPages : approvedPages
                  }
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
