import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonInput,
  IonLabel,
  IonModal,
  IonRefresher,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  arrowDownCircleOutline,
  personCircleOutline,
  add,
} from "ionicons/icons";
import { documentOutline } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { clear, get, set } from "../../services/storage";
import "./TrackExpenses.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const TrackExpenses: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  const [home, setHome] = useState<any>();
  const [centerName, setCenterName] = useState(0);
  const [managementData, setManagementData] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [
    downloadedManagementData,
    setDownloadedManagementData,
  ] = useState<any>();
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [counter, setCounter] = useState(0);
  const [month, setMonth] = useState<any>(moment().format("M"));
  const [year, setYear] = useState<any>(moment().format("YYYY"));
  const [totInvoiceId, setTotInvoiceId] = useState<any>([]);
  const [dateChangeCounter, setDateChangeCounter] = useState(0);
  const [date, setDate] = useState<any>();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedTotalManagementData(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/MGMT/Home");
      });
    }
    getToken();

    if (token) {
      getTotalManagementData();
    }
  }, [token, counter, dateChangeCounter]);

  const getToken = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      const profile = JSON.parse(res);
      if (profile?.user_type !== "MGMT") {
        clear().then(() => history.replace("/Login"));
      }
    });
  };

  checkIfUserDoesntExist();

  const getTotalManagementData = async () => {
    const res = await axios.get(
      "/api/mgmt/track-expenses/" + month + "/" + year + "/" + "0",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    if (res) {
      setManagementData(res?.data?.centersData);
      setTotalPages(res?.data?.pages);
      setPage(1);
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedTotalManagementData = async (updatePage: Number) => {
    const res = await axios.get(
      "/api/mgmt/track-expenses/" + month + "/" + year + "/" + updatePage,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setManagementData(res?.data?.centersData);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const downloadManagementData = async (centerName: string) => {
    const res = await axios.get(
      "/api/mgmt/download-expense/" + month + "/" + year + "/" + centerName,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    if (res) {
      jsonToExcel(res?.data?.expenses, "Expenses", true);
      jsonToExcel(res?.data?.revenue, "Revenue", true);
      setDownloadedManagementData(res);
    }
  };

  const jsonToExcel = async (
    jsonData: any,
    reportTitle: any,
    showLabel: any
  ) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData =
      typeof jsonData != "object" ? JSON.parse(jsonData) : jsonData;

    var CSV = "";
    //Set Report title in first row or lin
    CSV += reportTitle + "\r\n\n";

    //This condition will generate the Label/Header
    if (showLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    var encodedCSV = btoa(CSV);
    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += reportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension
    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    // //set the visibility hidden so it will not effect on your web-layout
    // // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    // //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customTrackExpenses__mgmt"
              : "customTrackExpenses__mgmt animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/MGMT/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Track Expenses </IonTitle>
          </IonToolbar>
          <IonItem className="selectOption-mgmt">
            <IonDatetime
              value={date}
              placeholder="Select Month and Year"
              displayFormat="MMMM, YYYY"
              max={new Date(
                new Date().setMonth(new Date().getMonth())
              ).toISOString()}
              onIonChange={(e: any) => {
                setIsDataLoaded(true);
                setDate(moment(e.detail.value).format("MMM, YYYY"));
                setMonth(moment(e.detail.value).format("M"));
                setYear(moment(e.detail.value).format("YYYY"));
                setDateChangeCounter(dateChangeCounter + 1);
              }}
            />
          </IonItem>

          <IonCard>
            {managementData?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to show</h4>
            ) : (
              managementData?.map((mgmtData: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="2.5">
                          <IonIcon
                            style={{ fontSize: 60 }}
                            icon={documentOutline}
                          />
                        </IonCol>
                        <IonCol
                          size="5"
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          {/* <IonIcon style={{fontSize: 36}} icon={personCircleOutline}></IonIcon> */}
                          <div className="resident-card">
                            <h3>{mgmtData?.centerName} </h3>
                            <p>
                              {" "}
                              Revenue: Rs.{" "}
                              {mgmtData?.revenue
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </p>
                            <p>
                              {" "}
                              Expense: Rs.{" "}
                              {mgmtData?.expense
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </p>
                            <p> {mgmtData?.status} </p>
                          </div>
                        </IonCol>

                        <IonCol size="2" className="customResidentView">
                          <div className="resident-card">
                            <div
                              className="view-details-right"
                              onClick={() => {
                                downloadManagementData(mgmtData?.centerName);
                              }}
                            >
                              <button>
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowDownCircleOutline}
                                ></IonIcon>
                                <p>Download</p>
                              </button>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
