import React, { useState, useEffect } from "react";
import { Login } from "../../Login";

import { IonContent, IonItem, IonSlide, IonSlides } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonImg,
  IonCol,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import { get, set } from "../../services/storage";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import "./ResponedQuery.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";

export const ResponedQuery: React.FC = () => {
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [queryData, setQueryData] = useState<any>([]);
  const [queryId, setQueryId] = useState<any>();
  const [queryImg, setQueryImg] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const [billImg, setBillImg] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/Client/Queries");
      });
    }
  }, []);

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    get("queryId").then((res) => {
      setQueryId(res);
    });
    const getData = async () => {
      const res = await axios.get("/api/client/query/" + queryId, {
        headers: {
          "x-access-token": token,
        },
      });
      setQueryData(res?.data);
      setQueryImg(res.data.bill_image_url);
      if (res) {
        setTimeout(() => {
          setIsDataLoaded(false);
        }, 1000);
      }
    };
    if (token && queryId) {
      getData();
    }
  }, [token, profile, queryId]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  checkIfUserDoesntExist();

  const chkImg = () => {
    var img = document.getElementById("queryImg__cl")?.offsetHeight;
    console.log(img);
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customQueryDetails__cl"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/Client/Queries"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Query Details </IonTitle>
      </IonToolbar>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonCard>
          <IonGrid>
            <IonRow>
              {/* <a href={queryImg} target="_blank" rel="noopener noreferrer">
                  <img
                    id="queryImg__cl"
                    className={
                      isImgFullyLoaded
                        ? "dontShowImg customImg"
                        : "showImg customImg fadeIn"
                    }
                    src={queryImg}
                    onLoad={chkImg}
                    alt="img"
                  />
                </a> */}
              <IonSlides pager={true} options={slideOpts}>
                {queryImg?.reverse().map((img: string, key: any) => {
                  return (
                    <IonSlide key={key}>
                      <img
                        onClick={() => {
                          PhotoViewer.show("https://epoch.theflak.in/" + img);
                        }}
                        id="billDetails__am"
                        // className={
                        //   isImgFullyLoaded
                        //     ? "dontShowImg customImg"
                        //     : "showImg customImg fadeIn"
                        // }
                        src={"https://epoch.theflak.in/" + img}
                        // onLoad={chkImg}
                        alt="img"
                        className="customImg"
                      />
                    </IonSlide>
                  );
                })}
              </IonSlides>
            </IonRow>
            <IonRow>
              <IonCol size="7">
                <div className="resident-card">
                  <h3> {queryData?.name} </h3>
                  <p> {queryData?.center} </p>
                </div>
              </IonCol>
              <IonCol size="5">
                <div className="resident-card">
                  <div className="bill-details-right">
                    <button id="billdetail-balance">
                      {" "}
                      Rs.{" "}
                      {queryData?.amount
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </button>
                  </div>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <div className="resident-card display-in-line">
                  <h6 id="resident-card-display-in-line">
                    {" "}
                    {moment(queryData?.date).format("MMM DD, YYYY")}{" "}
                  </h6>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12">
                <div className="resident-card">
                  <h6> Bill number </h6>
                  <p
                    style={{ color: "#333" }}
                    id="zeromargin"
                    className="customQueryValue"
                  >
                    {" "}
                    {queryData?.billNo}{" "}
                  </p>
                  <h6> Description </h6>
                  <p
                    style={{ color: "#333" }}
                    id="zeromargin"
                    className="customQueryValue"
                  >
                    {" "}
                    {queryData?.description}{" "}
                  </p>
                  <h6> Message from the client </h6>
                  <p
                    style={{ color: "#333" }}
                    id="zeromargin"
                    className="customQueryValue"
                  >
                    {queryData?.query}{" "}
                  </p>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      )}
    </IonContent>
  );
};
