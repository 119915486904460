import React from 'react';
import { IonContent, IonToolbar, IonTitle, IonButtons, IonBackButton, IonButton, IonIcon, IonMenuButton, IonSearchbar, IonSegment, IonSegmentButton } from '@ionic/react';
import { personCircle, search, helpCircle, star, create, ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';

export const TextAreaExamples: React.FC = () => (
  <IonContent
    scrollEvents={true}
    onIonScrollStart={() => { }}
    onIonScroll={() => { }}
    onIonScrollEnd={() => { }}>
    <IonToolbar>
      <IonTitle>Title Only</IonTitle>
      <IonButtons slot="start">
        <IonBackButton defaultHref="/" />
      </IonButtons>
    </IonToolbar>

    <IonToolbar>
      <IonSegment value="all">
        <IonSegmentButton value="all">
          All
      </IonSegmentButton>
        <IonSegmentButton value="favorites">Favorites</IonSegmentButton>
      </IonSegment>
    </IonToolbar>
    
  </IonContent>

);

