import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { trashBin } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { get, set } from "../../services/storage";
import "./BillAction.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import "../../Animations/Animation.css";

export const BillAction: React.FC = () => {
  const history = useHistory();
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [residentId, setResidentId] = useState<any>();
  const [billId, setBillId] = useState<any>();
  const [billImg, setBillImg] = useState<any>();
  const [whichRoute, setWhichRoute] = useState<any>();
  const [btnDisabled, setBtnDisabled] = useState<any>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const [reasonOfRejection, setReasonOfRejection] = useState("0");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    get("resident_id").then((res) => {
      setResidentId(res);
    });
    get("billId").then((res) => {
      setBillId(res);
    });
    get("whichRoute").then((res) => {
      setWhichRoute(res);
      if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e) => {
          if (res === "FFI") {
            history.replace("/Am/FacilityInvoices");
          } else if (res === "FRI") {
            history.replace("/Am/ResidentInvoices");
          } else if (res === "FRD") {
            history.replace("/Am/ResidentDetails");
          } else if (res === "FRB") {
            history.replace("/Am/RejectedBills");
          }
        });
      }
    });

    const getData = async () => {
      const res = await axios.get("/api/am/bill/" + billId, {
        headers: {
          "x-access-token": token,
        },
      });
      console.log(res.data);
      setBillDetails(res.data);
      setBillImg(res?.data?.image_url);
      if (res) {
        setTimeout(() => {
          setIsDataLoaded(false);
        }, 1000);
      }
    };
    if (token && billId) {
      getData();
    }
  }, [token, residentId, billId, profile]);

  const approveOrReject = async (param: any) => {
    setIsDataLoaded(true);
    if (reasonOfRejection) {
      const res1 = await axios.get(
        "/api/am/bill-action/" + billId + "/" + param + "/" + reasonOfRejection,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (res1.data.done === 1) {
        setTimeout(() => {
          if (whichRoute === "FFI") {
            history.replace("/Am/FacilityInvoices");
          } else if (whichRoute === "FRI") {
            history.replace("/Am/ResidentInvoices");
          }
          setIsDataLoaded(false);
        }, 1000);
      } else {
        setIsDataLoaded(false);
        setBtnDisabled(false);
        alert("Your request is declined!");
      }
    }
  };
  const goBack = () => {
    // history.goBack()
    if (whichRoute === "FFI") {
      history.replace("/AM/FacilityInvoices");
    } else if (whichRoute === "FRI") {
      history.replace("/AM/ResidentInvoices");
    }
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  const chkImg = () => {
    var img = document.getElementById("billActionImg__am")?.offsetHeight;
    console.log(img);
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customBillAction__am"
              : "customBillAction__am animated"
          }
        >
          <IonModal
            swipeToClose={true}
            showBackdrop={true}
            backdropDismiss={true}
            isOpen={showModal}
            cssClass="customModal"
          >
            <IonItem>
              <IonLabel position="stacked">Reason for Rejection:</IonLabel>
              <IonSelect
                value={reasonOfRejection}
                okText="Okay"
                cancelText="Dismiss"
                onIonChange={(e) => setReasonOfRejection(e.detail.value)}
              >
                <IonSelectOption value="Bill not clear">
                  Bill not clear
                </IonSelectOption>
                <IonSelectOption value="Bill amount mismatch">
                  Bill amount mismatch
                </IonSelectOption>
                <IonSelectOption value="Description not clear">
                  Description not clear
                </IonSelectOption>
                <IonSelectOption value="Overwriting on bill">
                  Overwriting on bill
                </IonSelectOption>
                <IonSelectOption value="Duplicate bill">
                  Duplicate bill
                </IonSelectOption>
                <IonSelectOption value="Other">Other</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem disabled={reasonOfRejection === "Other" ? false : true}>
              <IonInput
                className="modalInput"
                onIonChange={(e: any) => setReasonOfRejection(e.detail.value)}
                type="text"
                placeholder="If Other, please mention reason"
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonButton
                className="modalButton1"
                disabled={reasonOfRejection.length !== 0 ? false : true}
                onClick={() => {
                  approveOrReject("rejected");
                  setShowModal(false);
                }}
              >
                Send
              </IonButton>
              <IonButton
                className="modalButton2"
                onClick={() => setShowModal(false)}
              >
                Close
              </IonButton>
            </IonItem>
          </IonModal>
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                // routerLink="/AM/Home"
                onClick={goBack}
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Bill Action </IonTitle>
          </IonToolbar>

          <IonCard className="customBillAction__card">
            <IonGrid>
              {/* <IonRow>
              <a href={billImg} target="_blank" rel="noopener noreferrer">
                <img
                  id="billActionImg__am"
                  className={
                    isImgFullyLoaded
                      ? "dontShowImg customImg"
                      : "showImg customImg fadeIn"
                  }
                  src={billImg}
                  onLoad={chkImg}
                  alt="img"
                />
              </a>
            </IonRow> */}
              <IonRow>
                <IonSlides pager={true} options={slideOpts}>
                  {billImg?.map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__am"
                          // className={
                          //   isImgFullyLoaded
                          //     ? "dontShowImg customImg"
                          //     : "showImg customImg fadeIn"
                          // }
                          src={"https://epoch.theflak.in/" + img}
                          // onLoad={chkImg}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>
              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3> {billDetails?.name} </h3>
                    <p> {billDetails?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <p className="amountTag" id="billdetail-balance">
                        {" "}
                        Rs.{" "}
                        {billDetails?.amount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <div>
                      <p style={{ fontSize: 14 }}>Date</p>
                      <h6
                        style={{ color: "#333333", fontSize: 16 }}
                        id="resident-card-display-in-line"
                      >
                        {moment(billDetails?.bill_date).format("MMM DD,YYYY")}
                      </h6>
                    </div>
                    <div>
                      <p style={{ fontSize: 14 }}>Category</p>
                      <h6 style={{ color: "#333333", fontSize: 16 }}>
                        {" "}
                        {billDetails?.category}{" "}
                      </h6>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    <h6> Bill number </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails.billNo}
                    </p>
                    { billDetails?.vendor_name && 
                    <>
                    <h6> Vendor </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.vendor_name}
                    </p>
                    </>
                    }
                    <h6> Description </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.description}{" "}
                    </p>
                    <h6> Status </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.status.charAt(0).toUpperCase() +
                        billDetails?.status.slice(1)}{" "}
                    </p>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <button
                    className="customApproveBtn--am"
                    id="billaction"
                    value="approved"
                    disabled={btnDisabled}
                    onClick={(e: any) => {
                      setReasonOfRejection("0");
                      setBtnDisabled(true);
                      approveOrReject(e.target.value);
                    }}
                  >
                    {" "}
                    Approve{" "}
                  </button>
                </IonCol>
                <IonCol size="6">
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="customRejectBtn--am"
                    id="billaction"
                    value="rejected"
                  >
                    {" "}
                    Reject{" "}
                  </button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
