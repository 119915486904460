import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonSlide,
  IonSlides,
  IonText,
  IonTextarea,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { get, set } from "../../services/storage";
import { useHistory } from "react-router-dom";
import "./BillDetails.css";
import { Plugins, Capacitor } from "@capacitor/core";
import clientHomeImg from "../../assets/clientHome.png";
import epochLogo from "../../assets/epoch.png";
import aboutEnd from "../../assets/aboutEnd.png";
import { call, mail, logoWhatsapp, calendarClear } from "ionicons/icons";
import "../../Animations/Animation.css";
import "./About.css";

export const About: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/Client/Home");
      });
    }
  }, []);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customAbout__cl animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/Client/Home"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> About Us </IonTitle>
      </IonToolbar>
      <div className="row1">
        <div className="row11">
          <img
            style={{ height: "100%", width: "100%" }}
            src={epochLogo}
            alt="epochLogo"
          />
        </div>
        <div className="row12">
          <p>India's Expert in</p>
          <p>Assisted Living &</p>
          <p>Dementia Care</p>
        </div>
      </div>

      <IonItem
        className="mainImage_cl"
        lines="none"
        style={{ marginTop: "4%" }}
      >
        <IonImg src={clientHomeImg} style={{ width: "100%" }} />
      </IonItem>
      {/* <IonCard
        style={{
          padding: "5%",
          boxShadow: "none",
          background: "#f2f2f2",
          borderRadius: 20,
        }}
      >
        <p className="clientAboutText_CL">
          Epoch Elder Care is India’s expert in Assisted Living and Dementia
          Care. We believe in focusing on the person as much as the clinical
          condition. Epoch Assisted Living Homes provide holistic, Person
          Centered Care that enables elders to have the highest quality of life.
          Our services also include Skilled Nursing Care, Palliative Care, Daily
          Assisted Living, 24*7 Care & Assistance. Epoch has more than 8 years
          of relevant experience in India, is managed by 100+ professionals, and
          follows evidence-based global best practices of dementia and geriatric
          care. Currently, Epoch operates 3 homes in Delhi NCR and Pune
        </p>
        <IonGrid>
          <IonRow style={{ marginTop: 20 }}>
            <IonCol style={{ textAlign: "center" }}>
              <a href="mailto:info@epocheldercare.com">
                <IonIcon icon={mail} />
                <IonText
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    display: "block",
                  }}
                >
                  Email
                </IonText>
              </a>
            </IonCol>

            <IonCol style={{ textAlign: "center" }}>
              <a href="tel:+919899681595">
                <IonIcon icon={call} />
                <IonText
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    display: "block",
                  }}
                >
                  Phone
                </IonText>
              </a>
            </IonCol>
            <IonCol style={{ textAlign: "center" }}>
              <a href="https://wa.me/message/6YVZP2K5H2XLK1">
                <IonIcon icon={logoWhatsapp} />
                <IonText
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    display: "block",
                  }}
                >
                  Whatsapp
                </IonText>
              </a>
            </IonCol>
            <IonCol style={{ textAlign: "center" }}>
                <IonIcon icon={calendarClear} />
                <IonText
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    display: "block",
                  }}
                >
                  Book a slot
                </IonText>
              </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard> */}
      <div className="row2">
        <p>
          <span style={{ color: "#c12525", fontWeight: 600 }}>
            Epoch Elder Care&nbsp;
          </span>
          is India’s expert in Assisted Living and Dementia Care.
        </p>

        <p>
          Epoch Assisted Living Homes provide holistic, Person Centered Care
          that enables elders to have the highest quality of life. At Epoch, the
          team believes in focusing on the person as much as the clinical
          condition.
        </p>
        <p>
          Epoch has more than 9 years of relevant experience in India, and
          follows evidence-based global best practices. Currently, Epoch
          operates 3 homes in India - Vermeer House and Frida House in Gurgaon,
          Monet House in Pune, and will be expanding the services soon.
        </p>
        <p>
          Epoch Elder Care is helmed by Neha Sinha, a dementia specialist,
          clinical psychologist and trained in palliative care philosophy from
          Stockholm (Sweden). She has gained international recognition for
          creating dementia services in India. Epoch Homes are managed under the
          expertise of registered senior nurses trained in elder and dementia
          care.
        </p>
      </div>
      <img style={{ width: "100%" }} src={aboutEnd} alt="end" />
    </IonContent>
  );
};
