import {
  IonAlert,
  IonCard,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import axios from "../constants/axios-instance";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { set } from "../services/storage";
import Loading from "../constants/loading";
import "../Animations/Animation.css"
import "./Auth.css";


export const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otp, setOtp] = useState<Number>();
  const history = useHistory();

  const emailValidation = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const forgetPasswordFunctionality = async () => {
    setDisableButton(true);
    if (await checkWhetherEmailExist()) {
      await sendOtpToEmail();
      setShowAlert(true);
    } else {
      setDisableButton(false);
      setShowAlert(true);
    }
  };

  const sendOtpToEmail = async () => {
    const data = { id: email };
    const res = await axios.post(
      "/login/forgot-password",
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.done) {
      setShowVerifyOtp(true);
      setDisableButton(false);
    }
  };

  const verifyOtp = async () => {
    if (otp) {
      setDisableButton(true);
      const data = { id: email, otp: otp };
      const res = await axios.post("/login/varify-otp", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if(res.data.success){
        setDisableButton(false)
        set('token', res.data.token).then(() => {
          history.push('ChangePassword');
        })
      }
      else{
        setDisableButton(false);
        setIsOtpVerified(true)
        setDisableButton(false)
      }
    } else {
      return false;
    }
  };

  const checkWhetherEmailExist = async () => {
    if (emailValidation()) {
      const res = await axios.get("/admin/checkemail/" + email, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res?.data?.res) {
        setIsEmailExist(true);
        return true;
      } else {
        return false;
      }
    } else {
    }
  };

  return (
    <IonContent className="animated auth">
      {disableButton ? <Loading /> : <></>}
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header={
          isEmailExist
            ? "An OTP has been sent to your email Id!"
            : "Email doesn't exist!"
        }
        buttons={["Okay"]}
      />
      <IonAlert
        isOpen={isOtpVerified}
        onDidDismiss={() => setIsOtpVerified(false)}
        cssClass="my-custom-class"
        header={"Incorrect OTP!"}
        buttons={["Okay"]}
      />
      <IonHeader
        style={{
          background: "#a91d2a",
          color: "#fff",
          textAlign: "center",
          padding: " 8%",
          fontSize: 19,
        }}
      >
        Forgot Password
      </IonHeader>
      <IonItem className="inputField_fp" style={{marginTop: "1%"}}>
        <IonInput
          onIonChange={
            showVerifyOtp
              ? (e: any) => setOtp(e.target.value)
              : (e: any) => setEmail(e.target.value)
          }
          type={showVerifyOtp ? "number" : "email"}
          required={true}
          placeholder={
            showVerifyOtp ? "Enter your OTP here" : "Enter your email"
          }
        />
        <button
          disabled={disableButton}
          onClick={
            showVerifyOtp
              ? () => verifyOtp()
              : () => forgetPasswordFunctionality()
          }
          style={{
            background: disableButton ? "gray" : "#a91d2a",
            padding: "3%",
            color: "#fff",
          }}
        >
          {showVerifyOtp ? "Verify" : "Send OTP"}
        </button>
      </IonItem>
      {showVerifyOtp ? (
        <p className="otp_fp" style={{ color: "#a91d2a" }}>OTP will expire in 10 min</p>
      ) : (
        <></>
      )}
      <p
      className="backToLogin_fp"
        style={{
          background: "rgb(169, 29, 42)",
          color: "#fff",
          textAlign: "center",
          width: "60%",
          padding: "2%",
          margin: "0 auto",
          marginTop: "4%",
        }}
        onClick={() => history.push("Login")}
      >
        Go to Login page
      </p>
    </IonContent>
  );
};
