import React from "react";
import { IonBackButton, IonButtons, IonCard, IonContent, IonItem, IonTitle, IonToolbar} from "@ionic/react";
import "../../Animations/Animation.css";
import "./TechSupport.css";

export const TechSupport: React.FC = () => {

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customTechSupport animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/Client/Home"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Tech Support </IonTitle>
      </IonToolbar>
      <IonCard>
        <p>
          If you face any problem while using the application and need
          assistance, please reach out to us at:{" "}
          <a href="mailto:support@epocheldercare.com">
            support@epocheldercare.com
          </a>
        </p>
      </IonCard>
    </IonContent>
  );
};
