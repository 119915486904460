import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonImg,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { walletOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./TopUpHistory.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const TopUps: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [topUps, setTopUps] = useState<any>();
  const [totalPages, setTotalPages] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [amount, setAmount] = useState();
  const [isPending, setIsPending] = useState();
  const [balance, setBalance] = useState();
  const [invoicePending, setInvoicePending] = useState();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedExpensesData(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }

    getTokenFromLocalStorage();

    if (token) {
      getExpensesData();
    }
  }, [token]);

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FHPC").then(() => {});
  };

  const getExpensesData = async () => {
    const res = await axios.get("/api/client/invoice-topups/0", {
      headers: {
        "x-access-token": token,
      },
    });
    setTopUps(res?.data?.topups);
    setTotalPages(res?.data?.pages);
    setAmount(res?.data?.amount);
    setBalance(res?.data?.pendingPettyCash);
    setInvoicePending(res?.data?.pendingInvoiceAmount);
    setIsPending(res?.data?.pending);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedExpensesData = async (updatePage: Number) => {
    const res = await axios.get("/api/client/invoice-topups/" + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setTopUps(res?.data?.topups);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customTopUpHistory__am"
              : "customTopUpHistory__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/CL/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> My Payments </IonTitle>
          </IonToolbar>

          <p
            style={{ color: "#fff" }}
            className="customPetty__totBalance totalBalance_cl"
          >
            {`Required Petty Cash: ₹${balance}`}
          </p>
          <p
              style={{ color: "#fff" }}
              className="customPetty__totBalance totalBalance_cl"
          >
            {`Required Petty Cash =  Balance to be maintained - Current balance`}
          </p>

          <p
            style={{ color: "#fff" }}
            className="customPetty__totBalance totalBalance_cl"
          >
            {`Invoice Pending Amount: ₹${invoicePending}`}
          </p>

          {/*<p*/}
          {/*  style={{ color: "#fff" }}*/}
          {/*  className="customPetty__totBalance totalBalance_cl"*/}
          {/*>*/}
          {/*  {isPending*/}
          {/*    ? "Total Pending Amount: ₹" + amount*/}
          {/*    : "Total Advance Amount: ₹" + amount}*/}
          {/*</p>*/}

          <IonCard>
            {totalPages === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to Show</h4>
            ) : (
              topUps
                ?.slice(0)
                // .reverse()
                ?.map((topUp: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="3">
                            <IonIcon
                              style={{ fontSize: 60 }}
                              icon={walletOutline}
                            />
                          </IonCol>
                          <IonCol size="6">
                            <div className="resident-card">
                              <h3>
                                {" "}
                                {moment(topUp?.date).format("MMM DD,YYYY")}{" "}
                              </h3>
                              <h6> Rs. {topUp?.amount} </h6>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })
            )}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
