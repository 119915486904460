import Menu from "../Fm/components/Menu";
import React, { useState, useEffect } from "react";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";



/* Theme variables */
import "../theme/variables.css";
import "../Fm/pages/Page.css";

/* Pages variables */
import { Login } from "../Login";
import { QuickLinks } from "../Fm/pages/Home";
import { BillUpload } from "../Fm/pages/BillUpload";
import { Expenses } from "../Fm/pages/Expenses";
import { ResidentBills } from "../Fm/pages/ResidentBills";
import { BillDetails } from "../Fm/pages/BillDetails";
import { ResidentDetails } from "../Fm/pages/ResidentDetails";
import { Residents } from "../Fm/pages/Residents";
import { Queries } from "../Fm/pages/Queries";
import { QueryDetails } from "../Fm/pages/QueryDetails";
import { Profile } from "../Fm/pages/Profile";
import { TextAreaExamples } from "../Fm/pages/test";
import { RejectedBills } from "../Fm/pages/RejectedBills";
import { TopUps } from "../Fm/pages/TopUpHistory";
import { ChangePassword } from "../Auth/changePassword";
import { PettyCashSnapshot } from "../Fm/pages/PettyCashSnapshot";

const FM: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/Login" component={Login} />
              <Route path="/ChangePassword" component={ChangePassword} />
              <Route path="/FM/Home" component={QuickLinks} />
              <Route path="/FM/Expenses" component={Expenses} />
              <Route path="/FM/BillUpload" component={BillUpload} />
              <Route path="/FM/ResidentBills" component={ResidentBills} />
              <Route path="/FM/BillDetails" component={BillDetails} />
              <Route path="/FM/Residents" component={Residents} />
              <Route path="/FM/RejectedBills" component={RejectedBills} />
              <Route path="/FM/ResidentDetails" component={ResidentDetails} />
              <Route path="/FM/Queries" component={Queries} />
              <Route path="/FM/PettyCashSnapshot" component={PettyCashSnapshot} />
              <Route path="/FM/QueryDetails" component={QueryDetails} />
              <Route path="/FM/Profile" component={Profile} />
              <Route path="/FM/test" component={TextAreaExamples} />
              <Route path="/FM/TopUps" component={TopUps} />
              <Route path="/FM/Home" component={QuickLinks} />
              <Redirect from="/" to="/FM/Home" />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default FM;
