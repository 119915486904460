import React, { useState, useEffect } from "react";
import { IonAlert, IonContent, IonDatetime, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonImg,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import {
  calendarOutline,
  downloadOutline,
  walletOutline,
} from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./TopUpHistory.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const DownloadReciept: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [receipt, setReceipt] = useState<any>();
  const [totalPages, setTotalPages] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [month, setMonth] = useState<any>(0);
  const [year, setYear] = useState<any>(0);
  const [date, setDate] = useState<any>();

  const currentDate = new Date();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedExpensesData(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }

    getTokenFromLocalStorage();

    if (token) {
      getExpensesData();
    }
  }, [token, month, year]);

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const downloadPdf = (link: any) => {
    window.open(link, "_blank");
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FHPC").then(() => {});
  };

  const getExpensesData = async () => {
    setIsDataLoaded(true)
    const res = await axios.get("api/client/get-receipts/0/" + month + "/" + year, {
      headers: {
        "x-access-token": token,
      },
    });
    setReceipt(res?.data?.receipts);
    setTotalPages(res?.data?.pages);
    // setAmount(res?.data?.amount);
    // setIsPending(res?.data?.pending);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedExpensesData = async (updatePage: Number) => {
    const res = await axios.get("api/client/get-receipts/" + updatePage + "/"  + month + "/" + year, {
      headers: {
        "x-access-token": token,
      },
    });
    setReceipt(res?.data?.receipts);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customTopUpHistory__am"
              : "customTopUpHistory__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/CL/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle>Download Receipt </IonTitle>
          </IonToolbar>

          <IonItem lines="none">
            <IonIcon
              className="customPettyCashSnapshot__icon"
              icon={calendarOutline}
            />
            <IonDatetime
              max={currentDate.toISOString()}
              placeholder="Select Month and Year"
              onIonChange={(e: any) => {
                setDate(e.detail.value);
                setMonth(moment(e.detail.value).format("M"));
                setYear(moment(e.detail.value).format("YYYY"));
              }}
              displayFormat="MM YYYY"
              value={date}
            ></IonDatetime>
          </IonItem>
          <IonCard>
            {totalPages === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to Show</h4>
            ) : (
              receipt
                ?.slice(0)
                // .reverse()
                ?.map((topUp: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="3">
                            <IonIcon
                              style={{ fontSize: 60 }}
                              icon={walletOutline}
                            />
                          </IonCol>
                          <IonCol size="6">
                            <div className="resident-card">
                              <h6>Receipt </h6>
                              <h3>
                                {moment(topUp?.date).format("MMM DD,YYYY")}{" "}
                              </h3>
                            </div>
                          </IonCol>
                          <IonCol
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContect: "center",
                            }}
                            size="3"
                          >
                            <IonIcon
                              style={{ fontSize: 36, marginLeft: "35%" }}
                              icon={downloadOutline}
                              onClick={() => downloadPdf(topUp.link)}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })
            )}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
