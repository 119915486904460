import React from "react";
import Menu from "../Fa/components/Menu";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";

/* Theme variables */
import "../theme/variables.css";
import "../Fm/pages/Page.css";

// Page Variables
import { Login } from "../Login";
import { QuickLinks } from "../Fa/pages/Home";
import { BillUpload } from "../Fa/pages/BillUpload";
import { UploadedBills } from "../Fa/pages/UploadedBills";
import { BillDetails } from "../Fa/pages/BillDetails";
import { Profile } from "../Fa/pages/Profile";
import { RejectedBills } from "../Fa/pages/RejectedBills";
import { ChangePassword } from "../Auth/changePassword";

const FA: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/Login" component={Login} />
              <Route path="/ChangePassword" component={ChangePassword} />
              <Route path="/FA/Home" component={QuickLinks} />
              <Route path="/FA/BillUpload" component={BillUpload} />
              <Route path="/FA/UploadedBills" component={UploadedBills} />
              <Route path="/FA/BillDetails" component={BillDetails} />
              <Route path="/FA/RejectedBills" component={RejectedBills} />
              <Route path="/FA/Profile" component={Profile} />
              <Route path="/FA/Home" component={QuickLinks} />
              <Redirect from="/" to="/FA/Home" />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default FA;
