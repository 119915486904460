import Menu from "../Client/components/Menu";
import React, { useState, useEffect } from "react";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";

/* Theme variables */
import "../theme/variables.css";
// import "../Client/pages/Page.css";

/* Pages variables */
import { Login } from "../Login";
import { QuickLinks } from "../Client/pages/Home";
import { BillDetails } from "../Client/pages/BillDetails";
import { Invoices } from "../Client/pages/Invoices";
import { PettyCash } from "../Client/pages/PettyCash";
import { Queries } from "../Client/pages/Queries";
import { TechSupport } from "../Client/pages/TechSupport";
import { PrivacyPolicy } from "../Client/pages/PrivacyPolicy";
import { RaiseQuery } from "../Client/pages/RaiseQuery";
import { ResponedQuery } from "../Client/pages/ResponedQuery";
import { Profile } from "../Client/pages/Profile";
import { About } from "../Client/pages/About";
import { ContactUs } from "../Client/pages/ContactUs";
import { TextAreaExamples } from "../Client/pages/test";
import { ChangePassword } from "../Auth/changePassword";
import { PettyCashSnapshot } from "../Client/pages/PettyCashSnapshot";
import { TopUps } from "../Client/pages/TopUpHistory";
import { DownloadReciept } from "../Client/pages/DownloadReciept";


const Client: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/Login" component={Login} />
              <Route path="/ChangePassword" component={ChangePassword} />
              <Route path="/Client/BillDetails" component={BillDetails} />
              <Route path="/Client/Invoices/" component={Invoices} />
              <Route path="/Client/PettyCash" component={PettyCash} />
              <Route path="/Client/Queries" component={Queries} />
              <Route path="/Client/TechSupport" component={TechSupport} />
              <Route path="/Client/PrivacyPolicy" component={PrivacyPolicy} />
              <Route path="/Client/RaiseQuery" component={RaiseQuery} />
              <Route path="/Client/RaiseQuery" component={RaiseQuery} />
              <Route path="/Client/ResponedQuery" component={ResponedQuery} />
              {/* <Route path="/Client/ResponedQuery" component={ResponedQuery} /> */}
              <Route
                path="/Client/PettyCashSnapshot"
                component={PettyCashSnapshot}
              />
              <Route
                path="/Client/downloadReciept"
                component={DownloadReciept}
              />
              <Route path="/Client/Profile" component={Profile} />
              <Route path="/Client/About" component={About} />
              <Route path="/Client/myPayments" component={TopUps} />
              <Route path="/Client/ContactUs" component={ContactUs} />
              <Route path="/Client/test" component={TextAreaExamples} />
              <Route path="/Client/Home" component={QuickLinks} />
              <Route path="/CL/Home" component={QuickLinks} />
              {/* <Route path="/" component={QuickLinks} /> */}
              <Redirect from="/" to="/CL/Home" exact />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default Client;
