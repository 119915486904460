import React, { useState, useEffect } from "react";
import { IonContent } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
} from "@ionic/react";
import { arrowDownCircleOutline, documentTextOutline } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./Invoices.css";
import { Plugins, Capacitor, FilesystemDirectory } from "@capacitor/core";
import { useFilesystem } from "@ionic/react-hooks/filesystem";
import { Pagination } from "../../services/pagination/pagination";
import { PDFGenerator } from "@ionic-native/pdf-generator";
import { invoice } from "../../invoice/invoice";
// import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../../Animations/Animation.css";

export const Invoices: React.FC = () => {
  const { writeFile } = useFilesystem();
  const { LocalNotifications } = Plugins;
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  // const [home, setHome] = useState<any>();
  // const [centerName, setCenterName] = useState(0);
  const [invoices, setInvoices] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedInvoices(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/Client/Home");
      });
    }

    getProfileFromLocalStorage();
    // if (profile) {
    //   const profileObject = JSON.parse(profile);
    //   setHome(profileObject?.centers);
    // }
    getTokenFromLocalStorage();

    if (token) {
      getInvoices();
    }
  }, [token]);

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getProfileFromLocalStorage = () => {
    get("profile").then((res) => {
      setProfile(res);
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getInvoices = async () => {
    const res = await axios.get("/api/client/invoices/" + "0", {
      headers: {
        "x-access-token": token,
      },
    });
    setInvoices(res?.data);
    setTotalPages(res?.data?.pages);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedInvoices = async (updatePage: Number) => {
    const res = await axios.get("/api/client/invoices/" + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setInvoices(res?.data);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const downloadInvoice = async (invoiceId: string) => {
    setIsDataLoaded(true);

    if (Capacitor.isNative) {
      const res = await axios.request({
        method: "GET",
        url:
          "https://epoch.theflak.in/api/client/download-invoice/" + invoiceId,
        headers: {
          "x-access-token": token,
        },
      });

      PDFGenerator.fromData(
        invoice(
          res.data.relativeName,
          res.data.billingAddress,
          res.data.shippingAddress,
          res.data.placeOfSupply,
          res.data.dueDate,
          res.data.preTaxAmount,
          res.data.taxAmount,
          res.data.postTaxAmount,
          res.data.totalTax,
          res.data.totalAmountText,
          res.data.residentName,
          res.data.monthYear,
          res.data.gstin,
          res.data.invoiceNo,
          res.data.roundOff,
          res.data.roundedOff,
          res.data.homeGSTIN,
          res.data.monthYearNote
        ),
        {
          documentSize: "A4",
          type: "share",
          fileName: "Invoice" + new Date().getTime() + ".pdf",
        }
      );
      setIsDataLoaded(false);
    } else {
      const res = await axios.request({
        method: "GET",
        url:
          "https://epoch.theflak.in/api/client/download-invoice-web/" +
          invoiceId +
          "/" +
          new Date().getTime(),
        headers: {
          "x-access-token": token,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Invoice " + new Date().getTime() + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      setIsDataLoaded(false);
    }
  };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidentInvoices__cl"
              : "customResidentInvoices__cl animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/Client/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle>Invoices </IonTitle>
          </IonToolbar>

          <IonCard>
            {invoices?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to show</h4>
            ) : (
              invoices?.map((invoice: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol
                          size="2"
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <IonIcon
                            style={{ fontSize: 50 }}
                            icon={documentTextOutline}
                          ></IonIcon>
                        </IonCol>
                        <IonCol>
                          <div className="resident-card">
                            <h3> {invoice?.month} Invoice</h3>
                            <p style={{ fontSize: 13 }}>
                              {" "}
                              Total Amount:{" "}
                              {invoice?.totalAmount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </p>
                          </div>
                        </IonCol>

                        <IonCol size="3" className="customResidentView">
                          <div className="resident-card">
                            <div
                              className="view-details-right"
                              onClick={() => {
                                // setResidentDetails(resident);
                              }}
                            >
                              <button
                                onClick={() => {
                                  console.log(invoice);
                                  downloadInvoice(invoice?.invoice_id);
                                }}
                              >
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowDownCircleOutline}
                                ></IonIcon>
                                <p>Download</p>
                              </button>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {/* {invoices?.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
