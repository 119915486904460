import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonItem,
  IonCard,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonItemDivider,
  IonBackButton,
  IonDatetime,
  IonIcon,
} from "@ionic/react";
import { get } from "../../services/storage";
import axios from "../../constants/axios-instance";
import { homeOutline, calendarOutline } from "ionicons/icons";
import "./HomeSnapshot.css";

import { Plugins, Capacitor, FilesystemDirectory } from "@capacitor/core";
import { useHistory } from "react-router";
import "../../Animations/Animation.css";
import { jsonToExcel } from "../../Functions/conversion";
import { useFilesystem } from "@ionic/react-hooks/filesystem";

export const HomeSnapshot: React.FC = () => {
  const history = useHistory();
  const [token, setToken] = useState<any>();
  const [minDate, setMinDate] = useState<any>();
  const [maxDate, setMaxDate] = useState<any>();
  const [totCenter, settotCenter] = useState<any>();
  const [center, setCenter] = useState<any>();
  const [profileObjData, setProfileObjData] = useState<any>();
  const currentDate = new Date();

  const { writeFile } = useFilesystem();
  const { LocalNotifications, Share } = Plugins;

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }
    get("profile").then((res) => {
      settotCenter(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    if (totCenter) {
      const profileObj = JSON.parse(totCenter);
      setProfileObjData(profileObj);
    }
  }, [totCenter]);

  const downloadBills = async () => {
    if (!center || !minDate || !maxDate) {
      alert("Please select all the fields!");
    } else {
      const res = await axios.request({
        method: "GET",
        url:
          "https://epoch.theflak.in/api/am/home-snapshot/" +
          minDate +
          "/" +
          maxDate +
          "/" +
          center,
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (res?.data?.expenses) {
        jsonToExcel(res?.data?.expenses, "Snapshot", true).then(
          async (res: any) => {
            if (Capacitor.getPlatform() === "ios") {
              const result = await writeFile({
                path: "Homesnapshot" + new Date().getTime() + ".csv",
                data: res,
                directory: FilesystemDirectory.Documents,
              });
              await Share.share({
                title: "Homesnapshot" + new Date().getTime() + ".csv",
                url: result.uri,
                dialogTitle: "Save file",
              });
            } else {
              const result = await writeFile({
                path: "Download/Homesnapshot" + new Date().getTime() + ".csv",
                data: res,
                directory: FilesystemDirectory.ExternalStorage,
              })
                .then(() => {
                  LocalNotifications.schedule({
                    notifications: [
                      {
                        title: "Your file has been downloaded",
                        body: "Please check Downloads folder in the files.",
                        id: 1,
                        schedule: { at: new Date(Date.now() + 1000 * 2) },
                        actionTypeId: "",
                        extra: null,
                      },
                    ],
                  });
                  alert("File saved in download folder")
                })
                .catch(() => {
                  LocalNotifications.schedule({
                    notifications: [
                      {
                        title: "There is some error!",
                        body: "Error occured while downloading the file!",
                        id: 1,
                        schedule: { at: new Date(Date.now() + 1000 * 2) },
                        actionTypeId: "",
                        extra: null,
                      },
                    ],
                  });
                });
            }
          }
        ).catch((error) => console.log(error))
      }
    }
  };

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customHomeSnapshot__am animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/AM/Home"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Home Snapshot </IonTitle>
      </IonToolbar>
      <IonCard>
        <IonItem lines="none">
          <IonIcon className="customHomeSnapshot__icon" icon={homeOutline} />
          <IonSelect
            placeholder="Select Home"
            onIonChange={(e: any) => {
              setCenter(e.detail.value);
            }}
          >
            {profileObjData?.centers?.map((center: any, key: any) => {
              return (
                <IonSelectOption value={center} key={key}>
                  {center}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        <IonItem lines="none">
          <IonIcon
            className="customHomeSnapshot__icon"
            icon={calendarOutline}
          />
          <IonDatetime
            placeholder="Select Start Date"
            onIonChange={(e: any) => {
              setMaxDate("");
              setMinDate("");
              setMinDate(e.detail.value);
            }}
            value={minDate}
            max={currentDate.toISOString()}
          ></IonDatetime>
        </IonItem>
        <IonItem lines="none">
          <IonIcon
            className="customHomeSnapshot__icon"
            icon={calendarOutline}
          />
          <IonDatetime
            placeholder="Select End Date"
            onIonChange={(e: any) => {
              setMaxDate(e.detail.value);
            }}
            min={minDate}
            value={maxDate}
            max={currentDate.toISOString()}
          ></IonDatetime>
        </IonItem>
        <div className="customHomeSnapshot__btn">
          <button onClick={downloadBills}>Download</button>
        </div>
      </IonCard>
    </IonContent>
  );
};
