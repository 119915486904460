import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { get, set } from "../../services/storage";
import { useHistory } from "react-router-dom";
import "./Queries.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css"

export const Queries: React.FC = () => {
  const history = useHistory();
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [pendingQuery, setPendingQuery] = useState<any>([]);
  const [respondedQuery, setRespondedQuery] = useState<any>([]);
  const [queryDetails, setQueryDetails] = useState<any>([]);
  const [switchButton, setSwitchButton] = useState<any>("Pending");
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [pendingPages, setPendingPages] = useState<any>();
  const [respondedPages, setRespondedPages] = useState<any>();
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  // const [apiCallCounterPending, setApiCallCounterPending] = useState<any>(1);
  // const [apiCallCounterResponded, setApiCallCounterResponded] = useState<any>(
  //   1
  // );
  const [noData, setNoData] = useState<any>(
    <h2 className="customNoData">Nothing to Display...</h2>
  );
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedQueryData(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }
  }, []);

  useEffect(() => {
    getTokenFromLocalStorage();

    if (token) {
      getQueryData();
    }
  }, [token, switchButton]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getQueryData = async () => {
    const res = await axios.get("/api/client/queries/0", {
      headers: {
        "x-access-token": token,
      },
    });
    setPendingQuery(res?.data?.pending);
    setRespondedQuery(res?.data?.responded);
    if (switchButton === "Pending") {
      setQueryDetails(res?.data?.pending);
    } else {
      setQueryDetails(res?.data?.responded);
    }
    setPendingPages(res?.data?.pending_pages);
    setRespondedPages(res?.data?.responded_pages);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedQueryData = async (updatePage: Number) => {
    const res = await axios.get("/api/client/queries/" + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setDataAccToSwitch(res.data);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };
  const queryStatusFunc = (e: any) => {
    if (e.toLowerCase() === "responded") {
      setSwitchButton("Responded");
      setQueryDetails(respondedQuery);
      if (respondedQuery.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    } else if (e.toLowerCase() === "pending") {
      setSwitchButton("Pending");
      setQueryDetails(pendingQuery);
      if (pendingQuery.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    }
  };

  const setUpdatedPendingQuery = (res: any) => {
    // setApiCallCounterPending(apiCallCounterPending + 1);
    // setPendingQuery([...pendingQuery, ...res.pending]);
    // setQueryDetails([...queryDetails, ...res.pending]);
    setPendingQuery(res?.pending);
    setQueryDetails(res?.pending);
  };

  const setUpdatedRespondedQuery = (res: any) => {
    // setApiCallCounterResponded(apiCallCounterResponded + 1);
    // setRespondedQuery([...respondedQuery, ...res.responded]);
    // setQueryDetails([...queryDetails, ...res.responded]);
    setRespondedQuery(res?.responded);
    setQueryDetails(res?.responded);
  };

  const setDataAccToSwitch = (res: any) => {
    if (switchButton === "Pending") {
      setUpdatedPendingQuery(res);
    } else {
      setUpdatedRespondedQuery(res);
    }
  };

  // const viewMoreData = () => {
  //   if (pendingPages - 1 < apiCallCounter && switchButton === "Pending") {
  //     return alert("No More Data to show");
  //   } else if (
  //     respondedPages - 1 < apiCallCounter &&
  //     switchButton === "Responded"
  //   ) {
  //     return alert("No More Data to show");
  //   } else {
  //     setIsDataLoaded(true);
  //     setApiCallCounter(apiCallCounter + 1);
  //     getPaginatedQueryData();
  //   }
  // };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded ? "customQueries__cl" : "customQueries__cl animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/client/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Queries </IonTitle>
          </IonToolbar>

          <IonToolbar>
            <IonSegment
              color="danger"
              value={switchButton}
              onIonChange={(e: any) => queryStatusFunc(e.detail.value)}
            >
              <IonSegmentButton value="Pending"> Pending </IonSegmentButton>
              <IonSegmentButton value="Responded">Responded</IonSegmentButton>
            </IonSegment>
          </IonToolbar>

          <IonCard>
            {queryDetails.length === 0
              ? noData
              : queryDetails?.map((query: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow key={key}>
                          <IonCol size="9">
                            <div className="resident-card">
                              <h3>
                                {query.query.length <= 20
                                  ? query.query
                                  : query.query.slice(0, 20) + "..."}
                              </h3>
                              <div className="rb-pTag">
                                <p className="ion-float-left">
                                  {moment(query?.date).format("MMM DD,YYYY")}
                                </p>
                                <p className="ion-float-right">
                                  {query?.center}
                                </p>
                              </div>
                              <h6>
                                {" "}
                                Rs.{" "}
                                {query?.amount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </h6>
                              <h6>{query?.closed ? "Closed" : "Open"}</h6>
                            </div>
                          </IonCol>
                          <IonCol size="3" className="customQueriesView" style={{marginTop: 20}}>
                            <div className="resident-card">
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  set("queryId", query?._id).then(() => {
                                    console.log();
                                  });
                                  history.push(
                                    "/Client/ResponedQuery/" + query?._id
                                  );
                                }}
                              >
                                <IonIcon
                                  icon={arrowForwardCircleOutline}
                                  style={{ fontSize: 40 }}
                                ></IonIcon>
                                <p>View Details</p>
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })}
            {/* {queryDetails.length >= 10 ? (
            <div className="viewMoreBtn__cl">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {(pendingPages > 1 && switchButton === "Pending") ||
            (respondedPages > 1 && switchButton === "Responded") ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={
                    switchButton === "Pending" ? pendingPages : respondedPages
                  }
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
