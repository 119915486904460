import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonItem,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "../../Animations/Animation.css";
import "./PrivacyPolicy.css";

export const PrivacyPolicy: React.FC = () => {
  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customPrivacyPolicy animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/Client/Home"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Privacy policy </IonTitle>
      </IonToolbar>
      <IonCard>
        <p>
          Please visit the below link to view Epoch Elder Care App Privacy
          Policy:
        </p>
        <a href="https://www.epocheldercare.com/privacy-policy-epoch-app">
          https://www.epocheldercare.com/privacy-policy-epoch-app
        </a>
      </IonCard>
    </IonContent>
  );
};
