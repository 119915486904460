import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonImg,
  IonItem,
  IonSlide,
  IonIcon,
  IonSlides,
  IonText,
  IonLabel,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
} from "@ionic/react";
import { IonCard } from "@ionic/react";
import {
  calendarClear,
  call,
  caretBackCircle,
  caretForwardCircle,
  logoWhatsapp,
  mail,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { get, set } from "../services/storage";
import landingImage1 from "../assets/landingImage1.jpg";
import landingImage2 from "../assets/landingImage2.jpg";
import landingImage3 from "../assets/landingImage3.jpg";
import landingImage4 from "../assets/landingImage4.jpg";
import epochLandingImage from "../assets/epochLandingImage.png";
import epochImg from "../assets/epoch.png";
import "./LandingScreens.css";
import { Capacitor } from "@capacitor/core";

export const LandingScreens: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<any>();
  const history = useHistory();

  const setFirstTimeUser = () => {
    set("isFirstTimeUser", 1).then((res) => {
      console.log(res);
    });
  };

  const getFirstTimeUser = () => {
    get("isFirstTimeUser").then((res) => {
      if (res === 1) {
        get("profile").then((res) => {
          if (res) {
            history.replace("/" + JSON.parse(res).user_type + "/Home");
          } else {
            history.replace("Login");
          }
        });
      }
    });
  };
  const isUserExist = () => {
    get("profile").then((res) => {
      if (res) {
        history.replace("/" + JSON.parse(res).user_type + "/Home");
      }
    });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  isUserExist();
  getFirstTimeUser();
  setFirstTimeUser();

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="landingScreens"
    >
      <IonCard style={{ boxShadow: "none", margin: 0, marginTop: "5%" }} className={Capacitor.getPlatform() === 'ios' ? "iosLandingScreen" : ""}>
        <IonImg
          className="logoImg"
          src={epochImg}
          alt="Logo"
          style={{ width: "50%", margin: "0 auto", marginBottom: "5%" }}
        />
        <IonSlides pager={false} options={slideOpts}>
          <IonSlide className="splashScreens_mobile" style={{ display: "block" }}>
            <IonImg
              className="landingImg"
              src={landingImage1}
              alt="img"
              style={{ height: "25em", objectFit: "cover", width: "100%" }}
            />
            <IonGrid style={{ marginTop: "4%" }}>
              <IonRow>
                <IonCol></IonCol>
                <IonCol
                  size="8"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IonText
                    style={{
                      color: "#A91D2A",
                      fontSize: 18,
                      alignItems: "center",
                    }}
                  >
                    Expertise
                  </IonText>
                </IonCol>
                <IonCol>
                  <IonIcon icon={caretForwardCircle} style={{ fontSize: 32 }} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonSlide>
          <IonSlide className="splashScreens_mobile" style={{ display: "block" }}>
            <IonImg
              className="landingImg"
              src={landingImage2}
              alt="img"
              style={{ height: "25em", objectFit: "cover", width: "100%" }}
            />
            <IonGrid style={{ marginTop: "4%" }}>
              <IonRow>
                <IonCol>
                  <IonIcon icon={caretBackCircle} style={{ fontSize: 32 }} />
                </IonCol>
                <IonCol
                  size="8"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IonText
                    style={{
                      color: "#A91D2A",
                      fontSize: 18,
                      alignItems: "center",
                    }}
                  >
                    Evidence based practices
                  </IonText>
                </IonCol>
                <IonCol>
                  <IonIcon icon={caretForwardCircle} style={{ fontSize: 32 }} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonSlide>
          <IonSlide className="splashScreens_mobile" style={{ display: "block" }}>
            <IonImg
              className="landingImg"
              src={landingImage3}
              alt="img"
              style={{ height: "25em", objectFit: "cover", width: "100%" }}
            />
            <IonGrid style={{ marginTop: "4%" }}>
              <IonRow>
                <IonCol>
                  <IonIcon icon={caretBackCircle} style={{ fontSize: 32 }} />
                </IonCol>
                <IonCol
                  size="8"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IonText
                    style={{
                      color: "#A91D2A",
                      fontSize: 18,
                      alignItems: "center",
                    }}
                  >
                    Experience
                  </IonText>
                </IonCol>
                <IonCol>
                  <IonIcon icon={caretForwardCircle} style={{ fontSize: 32 }} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonSlide>
          <IonSlide style={{ display: "block" }}>
              <IonImg
                className="landingImg landingImg4"
                src={landingImage4}
                alt="img"
                style={{ objectFit: "fill", width: "100%" }}
              />
              <IonText
                style={{
                  color: "#333",
                  fontSize: 20,
                  alignItems: "center",
                  fontWeight: 600,
                }}
              >
                <p style={{ marginTop: "1em", textAlign: "center" }}>
                  India's expert in
                  <br /> Assisted Living & Dementia Care.
                </p>
              </IonText>
              <IonGrid>
                <IonRow className="ctaIconRow" style={{ margin: "4% 0 3% 0" }}>
                  <IonCol style={{ textAlign: "center" }}>
                    <a href="mailto:info@epocheldercare.com">
                      <IonIcon icon={mail} />
                      <IonText
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          display: "block",
                        }}
                      >
                        Email
                      </IonText>
                    </a>
                  </IonCol>
                  <IonCol style={{ textAlign: "center" }}>
                    <a href="tel:+919899681595">
                      <IonIcon icon={call} />
                      <IonText
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          display: "block",
                        }}
                      >
                        Phone
                      </IonText>
                    </a>
                  </IonCol>
                  <IonCol style={{ textAlign: "center" }}>
                    <a href="https://wa.me/message/6YVZP2K5H2XLK1">
                      <IonIcon icon={logoWhatsapp} />
                      <IonText
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          display: "block",
                        }}
                      >
                        Whatsapp
                      </IonText>
                    </a>
                  </IonCol>
                  {/* <IonCol style={{ textAlign: "center" }}>
                  <IonIcon icon={calendarClear} />
                  <IonText
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      display: "block",
                    }}
                  >
                    Book a slot
                  </IonText>
                </IonCol> */}
                </IonRow>
                <IonRow className="btnRow" style={{ margin: "4% 0 4% 0" }}>
                  <button
                    className="landingScreenLogin"
                    style={{
                      background: "#A91D2A",
                      borderRadius: 18,
                      width: "49%",
                      textAlign: "center",
                      margin: "0 auto",
                      color: "#FFF",
                      padding: "3%",
                    }}
                    title="Login"
                    onClick={() => {
                      history.push("Login");
                    }}
                  >
                    Login
                  </button>
                </IonRow>
              </IonGrid>
          </IonSlide>
        </IonSlides>
      </IonCard>
    </IonContent>
  );
};
