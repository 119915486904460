import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonItem,
  IonCard,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonItemDivider,
  IonBackButton,
  IonDatetime,
  IonIcon,
} from "@ionic/react";
import { get } from "../../services/storage";
import axios from "../../constants/axios-instance";
import { homeOutline, calendarOutline, personOutline } from "ionicons/icons";
import "./PettyCashSnapshot.css";

import { Plugins, Capacitor, FilesystemDirectory } from "@capacitor/core";
import { useHistory } from "react-router";
import "../../Animations/Animation.css";
import moment from "moment";
import { jsonToExcel } from "../../Functions/conversion";
import { useFilesystem } from "@ionic/react-hooks/filesystem";

export const PettyCashSnapshot: React.FC = () => {
  const history = useHistory();
  const [token, setToken] = useState<any>();
  const [residentNames, setResidentNames] = useState<any>();
  const [resName, setResName] = useState<any>();
  const [date, setDate] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [year, setYear] = useState<any>();
  const [monthMMM, setMonthMMM] = useState<any>();
  const currentDate = new Date();

  const { writeFile } = useFilesystem();
  const { LocalNotifications, Share } = Plugins;

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FM/Home");
      });
    }
    get("token").then((res) => {
      setToken(res);
    });
    if (token) {
      getResidentNames();
    }
  }, [token]);

  const getResidentNames = async () => {
    const res = await axios.get("/api/fm/get-resident-data", {
      headers: {
        "x-access-token": token,
      },
    });
    if (res) {
      console.log(res.data.residents.resident_details);
      setResidentNames(res?.data?.residents?.resident_details);
    }
  };

  const downloadBills = async () => {
    if (!resName || !month || !year) {
      alert("Please select all the fields!");
    } else {
      const res = await axios.request({
        method: "GET",
        url:
          "https://epoch.theflak.in/api/fm/petty-snapshot/" +
          year +
          "/" +
          month +
          "/" +
          resName,
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (res?.data?.failed) {
        alert("Not enough bills for chosen duration");
      } else {
        jsonToExcel(
          res?.data,
          `Petty-Cash Ledger of ${resName} for ${monthMMM} ${year}`,
          true
        ).then(async (res: any) => {
          if (Capacitor.getPlatform() === "ios") {
            const result = await writeFile({
              path:
                `Petty-Cash Ledger of ${resName} for ${monthMMM} ${year}` +
                new Date().getTime() +
                ".csv",
              data: res,
              directory: FilesystemDirectory.Documents,
            });
            await Share.share({
              title:
                `Petty-Cash Ledger of ${resName} for ${monthMMM} ${year}` +
                new Date().getTime() +
                ".csv",
              url: result.uri,
              dialogTitle: "Save file",
            });
          } else {
            const result = await writeFile({
              path:
                `Download/Petty-Cash Ledger of ${resName} for ${monthMMM} ${year}` +
                new Date().getTime() +
                ".csv",
              data: res,
              directory: FilesystemDirectory.ExternalStorage,
            })
              .then(() => {
                LocalNotifications.schedule({
                  notifications: [
                    {
                      title: "Your file has been downloaded",
                      body: "Please check Downloads folder in the files.",
                      id: 1,
                      schedule: { at: new Date(Date.now() + 1000 * 2) },
                      actionTypeId: "",
                      extra: null,
                    },
                  ],
                });
                alert("File saved in download folder")
              })
              .catch(() => {
                LocalNotifications.schedule({
                  notifications: [
                    {
                      title: "There is some error!",
                      body: "Error occured while downloading the file!",
                      id: 1,
                      schedule: { at: new Date(Date.now() + 1000 * 2) },
                      actionTypeId: "",
                      extra: null,
                    },
                  ],
                });
              });
          }
        });
      }
    }
  };

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customPettyCashSnapshot__fm animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/FM/Home"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Petty Cash Ledger </IonTitle>
      </IonToolbar>
      <IonCard>
        <IonItem lines="none">
          <IonIcon
            className="customPettyCashSnapshot__icon"
            icon={personOutline}
          />
          <IonSelect
            placeholder="Select Resident"
            onIonChange={(e: any) => {
              setResName(e.detail.value);
            }}
          >
            {residentNames?.map((rName: any, key: any) => {
              return (
                <IonSelectOption value={rName.resident_name} key={key}>
                  {rName.resident_name}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        <IonItem lines="none">
          <IonIcon
            className="customPettyCashSnapshot__icon"
            icon={calendarOutline}
          />
          <IonDatetime
            max={currentDate.toISOString()}
            placeholder="Select Month and Year"
            onIonChange={(e: any) => {
              setDate(e.detail.value);
              setMonth(moment(e.detail.value).format("M"));
              setMonthMMM(moment(e.detail.value).format("MMM"));
              setYear(moment(e.detail.value).format("YYYY"));
            }}
            displayFormat="MM YYYY"
            value={date}
          ></IonDatetime>
        </IonItem>
        <div className="customPettyCashSnapshot__btn">
          <button onClick={downloadBills}>Download</button>
        </div>
      </IonCard>
    </IonContent>
  );
};
