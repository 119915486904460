import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonLoading,
} from "@ionic/react";

import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  browsersOutline,
  cashOutline,
  cashSharp,
  personOutline,
  peopleOutline,
  peopleSharp,
  personSharp,
  browsersSharp,
  handLeftOutline,
  handLeftSharp,
  logOutOutline,
  logOutSharp,
  homeOutline,
  homeSharp,
  closeCircle,
  keyOutline,
  keySharp,
  copyOutline,
  copySharp,
} from "ionicons/icons";
import "./Menu.css";
import { get, clear } from "../../services/storage";
import axios from "../../constants/axios-instance";
import { Capacitor } from "@capacitor/core";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/FM/Home",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
  },
  {
    title: "Uploaded Bills",
    url: "/FM/ResidentBills",
    iosIcon: browsersOutline,
    mdIcon: browsersSharp,
  },
  {
    title: "House Petty Cash",
    url: "/FM/Expenses",
    iosIcon: cashOutline,
    mdIcon: cashSharp,
  },
  {
    title: "Residents",
    url: "/FM/Residents",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: "Rejected Bills",
    url: "/FM/RejectedBills",
    iosIcon: closeCircle,
    mdIcon: closeCircle,
  },
  {
    title: "Top-up History",
    url: "/FM/TopUps",
    iosIcon: cashOutline,
    mdIcon: cashOutline,
  },
  {
    title: "Petty Cash Ledger",
    url: "/FM/PettyCashSnapshot",
    iosIcon: copyOutline,
    mdIcon: copySharp,
  },
  {
    title: "Queries",
    url: "/FM/Queries",
    iosIcon: handLeftOutline,
    mdIcon: handLeftSharp,
  },
  {
    title: "Profile",
    url: "/FM/Profile",
    iosIcon: personOutline,
    mdIcon: personSharp,
  },
  {
    title: "Change Password",
    url: "/ChangePassword",
    iosIcon: keyOutline,
    mdIcon: keySharp,
  },
];

const Menu: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [token, SetToken] = useState<any>();
  const [profile, SetProfile] = useState<any>();
  const [name, setName] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    get("token").then((res) => {
      SetToken(res);
    });
    get("profile").then((res) => {
      SetProfile(res);
      if (profile) {
        const profileObject = JSON.parse(profile);
        setName(profileObject.name);
      } else {
        setName("UNVERIFIED");
      }
    });
  }, [token, profile]);

  const handleLogout = () => {
    if (token && profile) {
      setLoading(true);
      get("token").then((token) => {
        get("profile").then((res) => {
          if (Capacitor.isNative) {
            let formData = new FormData();
            formData.append("user", JSON.parse(res).email);
            axios
              .post("/login/logout", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "x-access-token": token,
                },
              })
              .then(() => {
                clear().then(() => {
                  history.push("/login");
                  window.location.reload();
                });
              });
          } else {
            clear().then(() => {
              history.push("/login");
              window.location.reload();
            });
          }
        });
      });
    } else {
      alert("SESSION DOESNT EXIST!");
    }
  };

  return (
    <IonMenu contentId="main" type="overlay" className="customMenu__fm">
      <IonContent>
        <IonList id="inbox-list">
          <div className="menuHeadingDiv">
            <IonIcon icon={personOutline}></IonIcon>
            <div className="menuHeadingDiv--content">
              {" "}
              <p style={{ color: "#A91D2A", fontWeight: 700 }}>{name}</p>
              <p style={{ color: "#A91D2A", fontSize: 14 }}>Facility Manager</p>
            </div>
          </div>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    // ios={appPage.iosIcon}
                    // md={appPage.mdIcon}
                    icon={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonMenuToggle key={1098} autoHide={false}>
            <IonItem
              className="logoutBtn"
              onClick={handleLogout}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        {/*   <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={loading}
        // onDidDismiss={() => setLoading(false)}
        message={"Logging out..."}
        // duration={5000}
      />
    </IonMenu>
  );
};

export default Menu;
