import React, { useState, useEffect, useRef } from "react";
import "@capacitor-community/http";
import {
  IonBackButton,
  IonContent,
  IonCard,
  IonToolbar,
  IonButtons,
  IonItem,
  IonMenuButton,
  IonTitle,
  IonInput,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonFabButton,
  IonButton,
  IonTextarea,
  IonIcon,
  IonDatetime,
  IonFab,
  IonCol,
  IonImg,
  IonAlert,
  IonBackdrop,
  IonModal,
} from "@ionic/react";
import axios from "../../constants/axios-instance";
import { useHistory } from "react-router-dom";
import { Login } from "../../Login";
import "./BillUpload.css";
import { get, set } from "../../services/storage";
import Loading from "../../constants/loading";
import { camera, trash, close } from "ionicons/icons";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { Plugins, Capacitor } from "@capacitor/core";
import { setupConfig } from "@ionic/react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import "../../Animations/Animation.css";
import moment from "moment";

export const BillUpload: React.FC = () => {
  const { Http } = Plugins;
  const history = useHistory();
  const [particularExpenseType, setParticularExpenseType] = useState<any>();
  const [residentName, setResidentName] = useState<any>("");
  const [balanceLeft, setBalanceLeft] = useState<string>("");
  const [amount, setAmount] = useState<number>();
  const [image, setImage] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [userReference, setUserReference] = useState<any>();
  const [tokenLocal] = useState(sessionStorage.getItem("token"));
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [epoch_id, setEpoch_id] = useState<any>("");
  const [billDate, setBillDate] = useState<any>();
  // const [slab, setSlab] = useState<any>();

  const {
    photosBase64,
    photos,
    takePhoto,
    photoFormat,
    setPhotoArrayEmpty,
  } = usePhotoGallery();
  const [whichType, setWhichType] = useState<any>();
  const [facility, setFacility] = useState<any>();
  const [residents, setResidents] = useState<any>();
  const [category, setCategory] = useState<any>();
  const [categoryName, setCategoryName] = useState<any>();
  const [vendorId, setVendorId] = useState<any>();
  const [subCategory, setSubCategory] = useState<any>();
  const [subCategoryName, setSubCategoryName] = useState<any>();
  const [expense, setExpense] = useState<any>();
  const [vendor, setVendor] = useState<any>();
  const [expenseName, setExpenseName] = useState<any>();
  const [serverData, setServerData] = useState<any>();
  const [billType, setBillType] = useState<any>();
  const [btnDisabled, setBtnDisabled] = useState<any>(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [billNo, setBillNo] = useState<any>("");
  const [isBillUploaded, setIsBillUploaded] = useState(false);
  const currentDate = new Date();
  const [showImageCropModal, SetshowImageCropModal] = useState(false);
  const [cropper, setCropper] = useState<any>();
    const [showModal, setShowModal] = useState(false);
  const [cropData, setCropData] = useState([]);
  const [billUploadCounter, setBillUploadCounter] = useState(0);

  useEffect(() => {
    if (photosBase64.length !== 0) SetshowImageCropModal(true);
  }, [photosBase64]);
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AFM/Home");
      });
    }
    get("profile").then((res) => {
      setProfile(res);
      console.log(profile);
    });
    get("token").then((res) => {
      setToken(res);
    });

    setUserReference(profile);
    const getData = async () => {
      if (token) {
        const res = await Http.request({
          method: "GET",
          url: "https://epoch.theflak.in/api/afm/get-resident-data",
          headers: {
            "x-access-token": token,
          },
        });
        setServerData(res.data);
      } else {
        console.log("loading");
      }
    };
    getData();
  }, [token, profile, image, billUploadCounter]);
  const handleSubmit = async (e: any) => {
    console.log(cropData);
    e.preventDefault();
    if (
      !billDate ||
      !billType ||
      !amount ||
      !cropData.length ||
      !description ||
      !billNo ||
      !expenseName
      // !slab
    ) {
      return alert("Please fill all the fields!");
    } else if (billType === "Resident" && (!residentName || !epoch_id)) {
      return alert("Please fill all the details!");
    } else {
      setIsBillUploaded(true);
      setBtnDisabled(true);
      console.log("Approved");
      const email = JSON.parse(userReference !== null ? userReference : "{}")
        .fm_email;
      const centerName = JSON.parse(
        userReference !== null ? userReference : "{}"
      ).center_name;
      const data = {
        billDate,
        type: "Resident",
        category: categoryName,
        subCategory: subCategoryName,
        expenseType: expenseName,
        name: residentName,
        resident_id: epoch_id,
        amount,
        description,
        user_reference: email,
        base64String: cropData,
        format: photoFormat,
        centerName,
        billNo,
        vendorId: vendorId,
        // taxSlab: "0",
      };
      console.log(data);

      const res = await axios.request({
        method: "POST",
        url: "https://epoch.theflak.in/api/afm/upload-bill",
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: JSON.stringify(data),
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      });

      if (res.data.uploaded) {
        setBillUploadCounter(billUploadCounter + 1);
        setIsBillUploaded(false);
        setBtnDisabled(false);
        setShowAlert(true);
        setBillDate("");
        setBillType("");
        setCategory("");
        setSubCategoryName("");
        setExpenseName("");
        setAmount(undefined);
        setDescription("");
        setEpoch_id(undefined);
        setBalanceLeft("");
        setResidentName("");
        setBillNo(undefined);
        setCropData([]);
        setVendor([]);
        // setSlab("");
      } else {
        setIsBillUploaded(false);
        alert("Failed");
      }
    }
  };

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  const getCropData = () => {
    SetshowImageCropModal(false);
    if (typeof cropper !== "undefined") {
      base64ToBlob(cropper.getCroppedCanvas().toDataURL().slice(22));
    }
  };

  const base64ToBlob = async (base64: any) => {
    const base64Response = await fetch(`data:image/png;base64,${base64}`);
    const blob = await base64Response.blob();
    if (blob) {
      const newCropImage = imageDegrade(blob);
      newCropImage.then((res: any) =>
        setCropData(cropData.concat(res.slice(22)))
      );
    }
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        photoFormat!,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64",
        500,
        500
      );
    });

  const imageDegrade = async (file: any) => {
    try {
      const resizedImage = await resizeFile(file);
      console.log(resizedImage);
      return resizedImage;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customBillUpload__afm animated"
    >
      <IonModal
        swipeToClose={true}
        showBackdrop={true}
        backdropDismiss={true}
        isOpen={showModal}
        cssClass="customModal customModal1"
      >
        <IonLabel style={{ padding: "3%" }}>
          Please specify other expense
        </IonLabel>
        <IonInput
          className="otherExpenseReasonInput"
          onIonChange={(e: any) => {
            e.detail.value === ""
              ? (expenseName.expense = "Other expense")
              : (expenseName.expense = e.detail.value);
          }}
          type="text"
          placeholder="Please write here"
        ></IonInput>
        <button
          style={{ color: "#fff", background: "#c12525", padding: "3%" }}
          onClick={() => {
            console.log(expenseName);
            setShowModal(false);
          }}
        >
          Okay
        </button>
      </IonModal>
      <IonCard
        style={{
          display: showImageCropModal ? "block" : "none",
          position: "absolute",
          zIndex: 10,
          top: 84,
          width: "100%",
          height: "100%",
          background: "#fff",
          margin: 0,
          borderRadius: 0,
        }}
      >
        <Cropper
          src={photosBase64[photosBase64.length - 1]}
          style={{ height: 300, width: "100%" }}
          zoomTo={0}
          preview=".img-preview"
          viewMode={1}
          guides={true}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          zoomable={false}
          background={false}
          responsive={true}
          // autoCropArea={1}
          draggable={false}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 auto",
          }}
        >
          {/* <button
            style={{
              background: "#c12525",
              color: "#fff",
              padding: "3%",
              width: "50%",
              fontSize: 18,
            }}
            onClick={() => {
              SetshowImageCropModal(false);
              setCropData(
                cropData.concat(photosBase64[photosBase64.length - 1])
              );
            }}
          >
            Cancel
          </button> */}
          <button
            style={{
              background: "#c12525",
              color: "#fff",
              padding: "3%",
              width: "100%",
              fontSize: 18,
            }}
            onClick={() => {
              SetshowImageCropModal(false);
              getCropData();
            }}
          >
            Okay
          </button>
        </div>
      </IonCard>
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle> Upload a Bill </IonTitle>
      </IonToolbar>
      ​
      {isBillUploaded ? (
        <Loading />
      ) : (
        <IonCard>
          <IonItem lines="none">
            <IonLabel position="stacked">Date</IonLabel>
            <IonDatetime
              className="customInputFields"
              value={billDate}
              max={moment().format()}
              onIonChange={(e: any) => setBillDate(e.detail.value)}
            ></IonDatetime>
          </IonItem>
          {/* <IonItem lines="none">
            <IonLabel position="stacked">GST Slab</IonLabel>
            <IonSelect
              placeholder="Select One"
              onIonChange={(e: any) => {
                setSlab(e.detail.value);
              }}
            >
              <IonSelectOption value="0">0 %</IonSelectOption>
              <IonSelectOption value="5">5 %</IonSelectOption>
              <IonSelectOption value="12">12 %</IonSelectOption>
              <IonSelectOption value="18">18 %</IonSelectOption>
              <IonSelectOption value="28">28 %</IonSelectOption>
            </IonSelect>
          </IonItem> */}
          <IonItem lines="none">
            <IonLabel position="stacked">Type</IonLabel>
            <IonSelect
              className="customInputFields"
              placeholder="Select One"
              onIonChange={(e: any) => {
                setCategory([]);
                setSubCategory([]);
                setExpense([]);
                setCategoryName("");
                setSubCategoryName("");
                setExpenseName("");
                setResidentName("");
                setBalanceLeft("");
                setEpoch_id("");
                setBillType(e.detail.value);
                if (e.detail.value === "Resident") {
                  setWhichType(true);
                  setResidents(serverData?.residents);
                  setExpense(serverData?.residents.expenses);
                  setVendor(serverData?.residents.vendor);
                } else {
                  setWhichType(false);
                  setFacility(serverData?.facility);
                }
              }}
            >
              <IonSelectOption>Resident</IonSelectOption>
              <IonSelectOption disabled={true}>Facility</IonSelectOption>
            </IonSelect>
          </IonItem>
          {/* <IonItem lines="none">
            <IonLabel position="stacked">Category</IonLabel>
            <IonSelect
              disabled={whichType}
              placeholder="Select One"
              onIonChange={(e: any) => {
                setSubCategory(e.detail.value.sub_categories);
                setCategoryName(e.detail.value.category_name);
              }}
            >
              {facility?.categories &&
                facility?.categories?.map((category: any, key: any) => {
                  return (
                    <IonSelectOption value={category} key={key}>
                      {category.category_name}
                    </IonSelectOption>
                  );
                })}
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Sub Category</IonLabel>
            <IonSelect
              disabled={whichType}
              placeholder="Select One"
              onIonChange={(e: any) => {
                setExpense(e.detail.value.expenses);
                setSubCategoryName(e.detail.value.sub_cat_name);
              }}
            >
              {subCategory?.map((subCategory: any, key: any) => {
                return (
                  <IonSelectOption value={subCategory} key={key}>
                    {subCategory?.sub_cat_name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem> */}
          <IonItem lines="none">
            <IonLabel position="stacked">Expense Type</IonLabel>
            <IonSelect
              className="customInputFields"
              placeholder="Select One"
              onIonChange={(e: any) => {
                // if (e.detail.value.expense === "Other expense") {
                //   setShowModal(true);
                // }
                setExpenseName(e.detail.value);
              }}
            >
              {expense?.map((expense: any, key: any) => {
                return (
                  <IonSelectOption value={expense} key={key}>
                    {expense?.expense}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
              <IonLabel position="stacked">Vendor</IonLabel>
              <IonSelect
                className="customInputFields"
                placeholder="Select One"
                onIonChange={(e: any) => {
                  // if (e.detail.value.expense === "Other expense") {
                  //   setShowModal(true);
                  // }
                  setVendorId(e.detail.value);
                  // setBalanceLeft(
                  //   e.detail.value.budget - e.detail.value.expenditure
                  // );
                }}
              >
                {vendor?.map((expense: any, key: any) => {
                  return (
                    <IonSelectOption value={expense._id} key={key}>
                      {expense?.vendor_name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
          <IonItem lines="none" className="customBillUploadMargin">
            <IonLabel position="stacked">Name</IonLabel>
            <IonSelect
              className="customInputFields"
              placeholder="Select One"
              onIonChange={(e: any) => {
                setEpoch_id(e.target.value.epoch_id);
                setBalanceLeft(e.target.value.balance_left);
                setResidentName(e.target.value.resident_name);
              }}
            >
              {residents?.resident_details.map((rData: any, key: any) => {
                return (
                  <IonSelectOption value={rData} key={key}>
                    {rData?.resident_name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Id</IonLabel>
            <IonInput className="customInputFields" value={epoch_id} disabled>
              {epoch_id}
            </IonInput>
          </IonItem>
          <IonItem
            lines="none"
            // className="customBillUploadMargin"
            // disabled={!whichType}
          >
            <IonLabel position="stacked">Balance Left</IonLabel>
            <IonInput
              className="customInputFields"
              value={balanceLeft}
              disabled
            >
              {balanceLeft?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </IonInput>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Enter an amount</IonLabel>
            <IonInput
              className="customInputFields"
              type="number"
              autoCapitalize="one"
              value={amount}
              onIonChange={(e) => setAmount(parseInt(e.detail.value!, 10))}
            ></IonInput>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Enter Bill Number</IonLabel>
            <IonInput
              className="customInputFields"
              type="text"
              autoCapitalize="on"
              value={billNo}
              onIonChange={(e) => setBillNo(e.detail.value)}
            ></IonInput>
          </IonItem>
          ​
          <IonItem
            disabled={cropData.length < 8 ? false : true}
            lines="none"
            onClick={() => {
              takePhoto();
            }}
            className="uploadPicture"
          >
            <IonLabel>
              {photosBase64?.length === 0
                ? "Upload a picture"
                : "Add more pictures"}
            </IonLabel>
            <IonIcon icon={camera}></IonIcon>
          </IonItem>
          ​
          <p
            className="totalImages_afm"
            style={{
              marginLeft: "5%",
              marginTop: "-3%",
              color: "#fff",
              background: "#c12525",
              width: "31%",
              padding: "1%",
              borderRadius: 5,
            }}
          >
            Total Images: {cropData.length}
          </p>
          <IonItem lines="none" className="customBillUploadMarginTop">
            <IonLabel position="stacked">Description</IonLabel>
            <IonTextarea
              className="customInputFields"
              autoCapitalize="one"
              value={description}
              onIonChange={(e) => setDescription(e.detail.value!)}
              required
            ></IonTextarea>
          </IonItem>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => {
              setShowAlert(false);
            }}
            cssClass="my-custom-class"
            header={"Your bill has been successfully uploaded!"}
            buttons={["OK"]}
          />
          <IonItem lines="none">
            <button
              disabled={btnDisabled}
              className="submitBill"
              onClick={(e: any) => {
                handleSubmit(e);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </IonItem>
        </IonCard>
      )}
    </IonContent>
  );
};
