import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { arrowForwardCircleOutline, documentOutline } from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./Expenses.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css"

export const Expenses: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [totalBalance, setTotalBalance] = useState<any>();
  const [totalExpense, setTotalExpense] = useState<any>();
  const [expenses, setExpenses] = useState<any>();
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [totalBills, setTotalBills] = useState();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedExpensesData(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FM/Home");
      });
    }

    getTokenFromLocalStorage();

    if (token) {
      getExpensesData();
    }
  }, [token]);

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FHPC").then(() => {});
  };

  const getExpensesData = async () => {
    const res = await axios.get("/api/fm/get-expenses/0", {
      headers: {
        "x-access-token": token,
      },
    });
    setExpenses(res?.data?.expenses);
    setTotalBalance(res?.data?.total_balance);
    setTotalExpense(res?.data?.total_expense);
    setTotalPages(res?.data?.expenses_pages);
    setTotalBills(res?.data?.totalBills);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedExpensesData = async (updatePage: Number) => {
    const res = await axios.get("/api/fm/get-expenses/" + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setExpenses(expenses?.concat(res?.data?.expenses));
    setTotalBalance(res?.data?.total_balance);
    setTotalExpense(res?.data?.total_expense);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  // const viewMoreData = () => {
  //   if (totalPages - 1 < apiCallCounter) {
  //     return alert("No More Data to show");
  //   }
  //   setIsDataLoaded(true);
  //   setApiCallCounter(apiCallCounter + 1);
  //   getPaginatedExpensesData();
  // };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidentBills__fm--exp"
              : "customResidentBills__fm--exp animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/FM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> House Petty Cash </IonTitle>
          </IonToolbar>
          <div
          className="totalBalance_wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2%",
            }}
          >
            <p
              style={{
                fontSize: 11,
                color: "#fff",
                background: "#C12625",
                padding: "2%",
                borderRadius: 5,
              }}
            >
              Total Balance: &#8377;
              {totalBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
            <p
              style={{
                fontSize: 11,
                color: "#fff",
                background: "#C12625",
                padding: "2%",
                borderRadius: 5,
              }}
            >
              Total Expense: &#8377;
              {totalExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>

          <p
            className="totalBills_fm"
            style={{
              color: "#fff",
              background: "#c12525",
              fontSize: 13,
              padding: "2%",
              borderRadius: 18,
              width: "45%",
              margin: "0 auto",
              marginBottom: "3%",
              textAlign: "center",
            }}
          >
            Total bills: {totalBills}
          </p>

          <IonCard>
            {totalPages === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to Show</h4>
            ) : (
              expenses
                ?.slice(0)
                .reverse()
                ?.map((expense: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="2">
                            <IonIcon
                              style={{ fontSize: 60 }}
                              icon={documentOutline}
                            />
                          </IonCol>
                          <IonCol size="7">
                            <div className="resident-card">
                              <h3> {expense?.expense_type?.expense} </h3>
                              <div className="rb-pTag">
                                <p>
                                  {" "}
                                  {moment(expense?.bill_date).format(
                                    "MMM DD,YYYY"
                                  )}
                                </p>
                                <p className=""> {expense?.category} </p>
                              </div>
                              <h6>
                                {" "}
                                Rs.{" "}
                                {expense?.amount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </h6>
                            </div>
                          </IonCol>
                          <IonCol size="3" className="customResidentView">
                            <div className="resident-card">
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  setBillId(expense?._id);
                                  history.push("/FM/BillDetails");
                                }}
                              >
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowForwardCircleOutline}
                                ></IonIcon>
                                <p>View Details</p>
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })
            )}
            {/* {expenses?.length >= 10 ? (
            <div className="viewMoreBtn__fm">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
