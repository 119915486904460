import React, { useState, useEffect, useRef } from 'react';
import {
  IonContent,
  IonItem,
  IonCard,
  IonLabel,
  IonToolbar,
  IonInput,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonItemDivider,
  IonBackButton,
  IonDatetime,
  IonIcon,
  IonAlert,
  IonButton,
  IonRow,
} from "@ionic/react";
import { get } from "../../services/storage";
import axios from "../../constants/axios-instance";
import { homeOutline, calendarOutline, personOutline } from "ionicons/icons";
import "./HomeSnapshot.css";

import { Plugins, Capacitor, FilesystemDirectory } from "@capacitor/core";
import { useHistory } from "react-router";
import "../../Animations/Animation.css";
import { jsonToExcel } from "../../Functions/conversion";
import { useFilesystem } from "@ionic/react-hooks/filesystem";
import { UploadedBills } from "../../Fa/pages/UploadedBills";

export const CreateDayCareInvoice: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);
  const [token, setToken] = useState<any>();
  const [recieptDate, setRecieptDate] = useState<any>();
  const [totCenter, settotCenter] = useState<any>();
  const [center, setCenter] = useState<string>('');
  const [requiredFile, setRequiredFile] = useState<any>();
  const [profileObjData, setProfileObjData] = useState<any>();
  const [residentNames, setResidentNames] = useState<any>();
  const [resName, setResName] = useState<string>('');
  const currentDate = new Date();
  const [successAlert, setSuccessAlert] = useState(false);
  const [failureAlert, setFailureAlert] = useState(false);

  const [itemName, setItemName] = useState<string>('');
  const [sacCode, setSacCode] = useState<string>('');
  const [grossAmount, setGrossAmount] = useState<string>('');
  const [discount , setDiscount] = useState<string>('');
  const [netAmount, setNetAmount] = useState<number>(0);
  

  const { writeFile } = useFilesystem();
  const { LocalNotifications, Share } = Plugins;

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }
    get("profile").then((res) => {
      settotCenter(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    if (totCenter) {
      const profileObj = JSON.parse(totCenter);
      setProfileObjData(profileObj);
    }
    if (token && center) {
      getResidentNames();
    }
  }, [token, center]);

  useEffect(() => {
    const calculatedAmount = (1.18 * Number(grossAmount)) - Number(discount)*Number(grossAmount)/100
    setNetAmount(calculatedAmount)
  }, [grossAmount, discount]);


  const getResidentNames = async () => {
    const res = await axios.get("/api/am/residents/" + center, {
      headers: {
        "x-access-token": token,
      },
    });
    if (res) {
      setResidentNames(res?.data?.residents);
    }
  };

  const handleSubmit = async () => {
    console.log(itemName)
  }

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customHomeSnapshot__am animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/AM/day-care"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Create Day Care Invoice</IonTitle>
      </IonToolbar>
      <IonCard>
          {/* <IonItem lines="none">
            <IonIcon
                className="customHomeSnapshot__icon"
                icon={calendarOutline}
            />
            <IonDatetime
                placeholder="Select Date"
                onIonChange={(e: any) => {
                  setRecieptDate(e.detail.value);
                }}
                value={recieptDate}
                max={currentDate.toISOString()}
            ></IonDatetime>
          </IonItem> */}
          <IonItem lines="none">
            <IonIcon className="customHomeSnapshot__icon" icon={homeOutline} />
            <IonSelect
                placeholder="Select Home"
                onIonChange={(e: any) => {
                  setCenter(e.detail.value);
                }}
                value={center}
            >
              {profileObjData?.centers?.map((center: any, key: any) => {
                return (
                    <IonSelectOption value={center} key={key}>
                      {center}
                    </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem lines="none">
            <IonIcon className="customHomeSnapshot__icon" icon={personOutline} />
            <IonSelect
                placeholder="Select Day Care Resident"
                onIonChange={(e: any) => {
                  setResName(e.detail.value);
                }}
                value={resName}
            >
              {residentNames?.map((rName: any, key: any) => {
                return (
                    <IonSelectOption value={rName} key={key}>
                      {rName}
                    </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
          <IonIcon className="customHomeSnapshot__icon" icon={homeOutline} />
            <IonInput 
              placeholder="Enter Item name"
              onIonChange={(e: any) => {
                setItemName(e.detail.value);
              }}
            >
            </IonInput>
          </IonItem>

          <IonItem>
            <IonInput 
              placeholder="Enter SAC Code"
              onIonChange={(e: any) => {
                setSacCode(e.detail.value);
              }}
            >
            </IonInput>
          </IonItem>

          <IonItem>
            <IonInput 
              placeholder="Enter Gross Price"
              onIonChange={(e: any) => {
                setGrossAmount(e.detail.value);
              }}
            >
            </IonInput>
          </IonItem>

          <IonItem>
            <IonInput 
              placeholder="Enter Discount Percentage"
              onIonChange={(e: any) => {
                setDiscount(e.detail.value);
              }}
            >
            </IonInput>
          </IonItem>
          <IonItem>
            <IonInput 
              disabled
              defaultValue={netAmount}
              placeholder={netAmount.toString()}
            >
            </IonInput>
          </IonItem>
          <IonRow>
          <div className="dc-button">
            <button 
            onClick={() => handleSubmit()}
            >Preview</button>
          </div>
          <div>
            <button 
            onClick={() => handleSubmit()}
            >Generate & Download</button>
          </div>
          <div>
            <button 
            onClick={() => handleSubmit()}
            >Send Over Email</button>
          </div>
          </IonRow>
      </IonCard>
      <IonAlert
        isOpen={successAlert}
        onDidDismiss={() => setSuccessAlert(false)}
        cssClass="my-custom-class"
        // header={"Done"}
        // subHeader={'Subtitle'}
        message={"Success"}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={failureAlert}
        onDidDismiss={() => setFailureAlert(false)}
        cssClass="my-custom-class"
        // header={"Failed"}
        // subHeader={'Subtitle'}
        message={"Failed"}
        buttons={["Dismiss"]}
      />
    </IonContent>
  );
};
