import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonImg,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  checkbox,
  documentTextOutline,
  trophyOutline,
} from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set, clear } from "../../services/storage";
import "./ResidentBills.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const UploadedBills: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [approvedBills, setApprovedBills] = useState<any>([]);
  const [pendingBills, setPendingBills] = useState<any>([]);
  const [switchButton, setSwitchButton] = useState<any>("Pending");
  const [noData, setNoData] = useState<any>(
    <h2 className="customNoData">Nothing to Display...</h2>
  );
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [pendingPages, setPendingPages] = useState<any>();
  const [approvedPages, setApprovedPages] = useState<any>();
  const [totalBalance, setTotalBalance] = useState<any>();
  const [totalExpense, setTotalExpense] = useState<any>();
  const [pendingCount, setPendingCount] = useState<any>();
  const [approvedCount, setApprovedCount] = useState<any>();

  const [isRejected, setIsRejected] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(false);
  const [childData, setChildData] = useState<any>();

  // const [pendingCounter, setPendingCounter] = useState<any>(1);
  // const [approvedCounter, setApprovedCounter] = useState<any>(1);

  const [checkboxState, setCheckboxState] = useState(false);

  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedResidentBillDetails(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/FA/Home");
      });
    }

    getTokenFromLocalStorage();

    if (token) {
      getResidentBillDetails();
    }
  }, [token, switchButton]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      const profile = JSON.parse(res);
      if (profile?.user_type !== "FA") {
        clear().then(() => history.replace("/Login"));
      }
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentBillDetails = async () => {
    const res = await axios.get("/api/fa/get-bills/0", {
      headers: {
        "x-access-token": token,
      },
    });
    setTotalBalance(res?.data?.total_balance);
    setTotalExpense(res?.data?.total_expense);
    setPendingBills(res?.data?.pendingBills);
    setApprovedBills(res?.data?.approvedBills);
    setPendingCount(res?.data?.pendingCount);
    setApprovedCount(res?.data?.approvedCount);
    if (switchButton === "Pending") {
      setBillDetails(res?.data?.pendingBills);
    } else {
      setBillDetails(res?.data?.approvedBills);
    }
    setPendingPages(res?.data?.pending_pages);
    setApprovedPages(res?.data?.approved_pages);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedResidentBillDetails = async (updatePage: Number) => {
    console.log("getPaginatedData");
    const res = await axios.get("/api/fa/get-bills/" + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setDataAccToSwitch(res?.data);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const billStatusFunc = (e: any) => {
    if (e.toLowerCase() === "approved") {
      setSwitchButton("Approved");
      setDisableCheckbox(true);
      setBillDetails(approvedBills);
      setPage(1);
      if (approvedBills?.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    } else if (e.toLowerCase() === "pending") {
      setSwitchButton("Pending");
      setDisableCheckbox(false);
      setBillDetails(pendingBills);
      setPage(1);
      if (pendingBills?.length === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    }
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FRB").then(() => {});
  };

  const setUpdatedPendingBills = (res: any) => {
    setPendingBills(res?.pending);
    setBillDetails(res?.pending);
  };

  const setUpdatedApprovedBills = (res: any) => {
    setApprovedBills(res?.approved);
    setBillDetails(res?.approved);
  };

  const setDataAccToSwitch = (res: any) => {
    if (switchButton === "Pending") {
      setUpdatedPendingBills(res);
      setIsRejected(false);
    } else {
      setUpdatedApprovedBills(res);
      setIsRejected(true);
    }
  };

  checkIfUserDoesntExist();

  const handleCallback = (childData: any) => {
    if (childData?.length === 0) {
      setBillDetails(pendingBills);
      return alert("There are no rejected bills!");
    }
    setBillDetails(childData);
    console.log(childData);
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidentBills__fa"
              : "customResidentBills__fa animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/FA/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Uploaded Bills </IonTitle>
          </IonToolbar>
          <div
          className="totalBalance_wrapper"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "2%",
            }}
          >
            <p
            className="totalBalance_fa"
              style={{
                fontSize: 12,
                color: "#fff",
                background: "#C12625",
                padding: "2%",
                borderRadius: 5,
              }}
            >
              Total Balance: &#8377;
              {totalBalance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
            <p
            className="totalBalance_fa"
              style={{
                fontSize: 12,
                color: "#fff",
                background: "#C12625",
                padding: "2%",
                borderRadius: 5,
              }}
            >
              Total Expense: &#8377;
              {totalExpense?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </div>
          <p
            className="totalBills_fa"
            style={{
              fontSize: 14,
              color: "#fff",
              background: "#C12625",
              padding: "2%",
              borderRadius: 18,
              textAlign: "center",
              width: "50%",
              margin: "0 auto",
            }}
          >
            Total Bills:{" "}
            {switchButton === "Pending" ? pendingCount : approvedCount}
          </p>

          <IonToolbar>
            <IonSegment
              color="danger"
              value={switchButton}
              onIonChange={(e: any) => billStatusFunc(e.detail.value)}
            >
              <IonSegmentButton value="Pending">Pending</IonSegmentButton>
              <IonSegmentButton value="Approved">Approved</IonSegmentButton>
            </IonSegment>
          </IonToolbar>

          <IonCard>
            {billDetails?.length === 0
              ? noData
              : billDetails?.map((bill: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="2">
                            <IonIcon
                              style={{ fontSize: 60 }}
                              icon={documentTextOutline}
                            />
                          </IonCol>
                          <IonCol size="7">
                            <div className="resident-card">
                              <h3> {bill.center_name} </h3>
                              <div className="rb-pTag">
                                <p className="">
                                  {" "}
                                  {moment(billDetails?.bill_date).format(
                                    "MMM DD,YYYY"
                                  )}
                                </p>
                                <p className=""> {bill?.category} </p>
                              </div>
                              <h6>
                                {" "}
                                Rs.{" "}
                                {bill?.amount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </h6>
                            </div>
                          </IonCol>
                          <IonCol size="3" className="customResidentView">
                            <div
                              className="resident-card"
                              style={{ marginTop: 9 }}
                            >
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  setBillId(bill?._id);
                                  history.push("/FA/BillDetails");
                                }}
                              >
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowForwardCircleOutline}
                                ></IonIcon>
                                <p>View Details</p>
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })}
            {(pendingPages > 1 && switchButton === "Pending") ||
            (approvedPages > 1 && switchButton === "Approved") ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={
                    switchButton === "Pending" ? pendingPages : approvedPages
                  }
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
