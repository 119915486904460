import React, { useState, useEffect } from "react";
import { IonContent, IonItem } from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonImg,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { arrowForwardCircleOutline, documentTextOutline, walletOutline } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./PettyCash.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";

export const PettyCash: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [currentBalance, setCurrentBalance] = useState<any>();
  const [requiredBalance, setRequiredBalance] = useState<any>();
  const [totalExpense, setTotalExpense] = useState<any>();
  const [expenses, setExpenses] = useState<any>();
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedExpensesData(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }
  }, []);

  useEffect(() => {
    getTokenFromLocalStorage();

    if (token) {
      getExpensesData();
    }
  }, [token]);

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
    set("whichRoute", "FHPC").then(() => {});
  };

  const getExpensesData = async () => {
    const res = await axios.get("/api/client/petty/0", {
      headers: {
        "x-access-token": token,
      },
    });
    setExpenses(res?.data?.petty);
    setCurrentBalance(res?.data?.balance);
    setRequiredBalance(res?.data?.mBalance);
    setTotalExpense(res?.data?.petty);
    setTotalPages(res?.data?.pages);
    setPage(1);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedExpensesData = async (updatePage: Number) => {
    const res = await axios.get("/api/client/petty/" + updatePage, {
      headers: {
        "x-access-token": token,
      },
    });
    setExpenses(res?.data?.petty);
    setCurrentBalance(res?.data?.balance);
    setRequiredBalance(res?.data?.mBalance);
    setTotalExpense(res?.data?.total_expense);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  // const viewMoreData = () => {
  //   if (totalPages - 1 < apiCallCounter) {
  //     return alert("No More Data to show");
  //   }
  //   setIsDataLoaded(true);
  //   setApiCallCounter(apiCallCounter + 1);
  //   getPaginatedExpensesData();
  // };

  checkIfUserDoesntExist();

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded ? "customPettyCash__cl" : "customPettyCash__cl animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/Client/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Petty Cash </IonTitle>
          </IonToolbar>
          <p className="customPetty__totBalance totalBalance_cl" >
            Current Balance: &#8377;{currentBalance}
          </p>
          <p className="customPetty__totBalance totalBalance_cl" >
            Required Balance: &#8377;{requiredBalance}
          </p>

          <IonCard>
            {expenses?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No data to show</h4>
            ) : (
              expenses?.map((expense: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="2">
                          <IonIcon
                            style={{ fontSize: 50 }}
                            icon={documentTextOutline}
                          />
                        </IonCol>
                        <IonCol size="6">
                          <div className="resident-card">
                            <h3> {expense?.expense_type?.expense} </h3>
                            <div className="rb-pTag">
                              <p className="">
                                {" "}
                                {moment(expense?.bill_date).format(
                                  "MMM DD,YYYY"
                                )}{" "}
                              </p>
                              <p className=""> {expense?.category} </p>
                            </div>
                            <h6>
                              {" "}
                              Rs.{" "}
                              {expense?.amount
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            </h6>
                          </div>
                        </IonCol>
                        <IonCol size="3" className="customResidentView">
                          <div className="resident-card" style={{marginTop: 10}}>
                            <div
                              className="view-details-right"
                              onClick={() => {
                                setBillId(expense?._id);
                                history.push("/Client/BillDetails");
                              }}
                            >
                              <IonIcon
                                style={{ fontSize: 36 }}
                                icon={arrowForwardCircleOutline}
                              ></IonIcon>
                              <p>View Details</p>
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {/* {expenses?.length >= 10 ? <div className="viewMoreBtn__fm">
            {" "}
            <button
              onClick={() => {
                viewMoreData();
              }}
            >
              View More
            </button>
          </div> : <></>} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
