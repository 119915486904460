import Menu from "../Am/components/Menu";
import React, { useState, useEffect } from "react";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
/* Theme variables */
import "../theme/variables.css";
import "../Am/pages/Page.css";
import "../App.css";

/* Pages variables */
import { Login } from "../Login";
import { QuickLinks } from "../Am/pages/Home";
import { BillUpload } from "../Am/pages/BillUpload";
import { BillAction } from "../Am/pages/BillAction";
import { FacilityInvoices } from "../Am/pages/FacilityInvoices";
import { ResidentInvoices } from "../Am/pages/ResidentInvoices";
import { Queries } from "../Am/pages/Queries";
import { RaiseInvoice } from "../Am/pages/RaiseInvoice";
import { HomeSnapshot } from "../Am/pages/HomeSnapshot";
import { Residents } from "../Am/pages/Residents";
import { ResidentDetails } from "../Am/pages/ResidentDetails";
import { ResidentBillDetails } from "../Am/pages/ResidentBillDetails";
import { QueryDetails } from "../Am/pages/QueryDetails";
import { Profile } from "../Am/pages/Profile";
import { TextAreaExamples } from "../Am/pages/test";
import { RejectedBills } from "../Am/pages/RejectedBills";
import { TopUps } from "../Am/pages/TopUpHistory";
import { DayCare } from "../Am/pages/DayCare";
import { ChangePassword } from "../Auth/changePassword";
import { PettyCashSnapshot } from "../Am/pages/PettyCashSnapshot";
import { UploadReciept } from "../Am/pages/UploadReciept";
import { DownloadReciept } from "../Am/pages/DownloadReciept";
import { InvoiceTopups } from "../Am/pages/InvoiceTopups";
import { CreateDayCareInvoice } from "../Am/pages/CreateDayCareInvoice";

const Am: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route path="/Login" component={Login} />
              <Route path="/ChangePassword" component={ChangePassword} />
              <Route path="/Am/BillUpload" component={BillUpload} />
              <Route path="/Am/BillAction" component={BillAction} />
              <Route path="/Am/FacilityInvoices" component={FacilityInvoices} />
              <Route path="/Am/ResidentInvoices" component={ResidentInvoices} />
              <Route path="/Am/Queries" component={Queries} />
              <Route path="/Am/RaiseInvoice" component={RaiseInvoice} />
              <Route path="/Am/HomeSnapshot" component={HomeSnapshot} />
              <Route path="/Am/UploadReciept" component={UploadReciept} />
              <Route
                path="/Am/PettyCashSnapshot"
                component={PettyCashSnapshot}
              />
              <Route path="/Am/Residents" component={Residents} />
              <Route path="/Am/RejectedBills" component={RejectedBills} />
              <Route path="/Am/ResidentDetails" component={ResidentDetails} />
              <Route
                path="/Am/ResidentBillDetails"
                component={ResidentBillDetails}
              />
              <Route
                path="/Am/downloadReciept"
                component={DownloadReciept}
              />
              <Route path="/Am/QueryDetails" component={QueryDetails} />
              <Route path="/Am/Profile" component={Profile} />
              <Route path="/Am/test" component={TextAreaExamples} />
              <Route path="/Am/TopUps" component={TopUps} />
              <Route path="/Am/day-care" component={DayCare} />
              <Route path="/Am/create-day-care-invoice" component={CreateDayCareInvoice} />
              <Route path="/Am/invoiceTopups" component={InvoiceTopups} />
              <Route path="/AM/Home" component={QuickLinks} />
              <Redirect from="/" to="/AM/Home" exact />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default Am;
