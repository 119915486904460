import React, { useState, useEffect } from "react";
import {
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonAlert,
} from "@ionic/react";
import {
  arrowForwardCircleOutline,
  arrowDownCircleOutline,
  personCircleOutline,
  add,
  helpCircleOutline,
} from "ionicons/icons";
import { Login } from "../../Login";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./RaiseInvoice.css";
import { Plugins, Capacitor, FilesystemDirectory } from "@capacitor/core";
import moment from "moment";
import { PDFGenerator } from "@ionic-native/pdf-generator";
import { invoice } from "../../invoice/invoice";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";
import { jsonToExcel } from "../../Functions/conversion";
import { useFilesystem } from "@ionic/react-hooks/filesystem";

export const RaiseInvoice: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  const [home, setHome] = useState<any>();
  const [centerName, setCenterName] = useState(0);
  const [invoices, setInvoices] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [isClicked, setIsClicked] = useState<any>(false);
  // const [apiCallCounter, setApiCallCounter] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>();
  const [counter, setCounter] = useState(0);
  const [month, setMonth] = useState<any>(0);
  const [year, setYear] = useState<any>(0);
  const [totInvoiceId, setTotInvoiceId] = useState<any>([]);
  const [dateChangeCounter, setDateChangeCounter] = useState(0);
  const [date, setDate] = useState<any>();
  const history = useHistory();
  const [page, setPage] = useState(1);

  const [generatedAlert, setGeneratedAlert] = useState(false);
  const [generateFailedAlert, setGenerateFailedAlert] = useState(false);
  const [invalidDateAlert, setInvalidDateAlert] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [isPublishForEveryone, setIsPublishForEveryone] = useState(false);
  const [publishAllAlert, setPublishAllAlert] = useState(false);
  const [publishAllAlertError, setPublishAllAlertError] = useState(false);

  const { writeFile } = useFilesystem();
  const { LocalNotifications } = Plugins;

  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    console.log(updatePage);
    getPaginatedInvoices(updatePage - 1);
  };

  const invoiceIds = {
    invoices: totInvoiceId,
    publishDate: new Date(),
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }

    getProfileFromLocalStorage();
    if (profile) {
      const profileObject = JSON.parse(profile);
      setHome(profileObject?.centers);
    }
    getTokenFromLocalStorage();

    if (token) {
      getInvoices();
    }
  }, [token, counter, centerName, dateChangeCounter]);

  const checkIfUserDoesntExist = () => {
    get("token").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getProfileFromLocalStorage = () => {
    get("profile").then((res) => {
      setProfile(res);
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getInvoices = async () => {
    const res = await axios.get(
      "/api/am/get-invoices/" +
        centerName +
        "/" +
        month +
        "/" +
        year +
        "/" +
        "0",
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    if (res) {
      setInvoices(res?.data?.invoices);
      setTotalPages(res?.data?.pages);
      setPage(1);
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedInvoices = async (updatePage: Number) => {
    const res = await axios.get(
      "/api/am/get-invoices/" +
        centerName +
        "/" +
        month +
        "/" +
        year +
        "/" +
        updatePage,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setInvoices(res?.data?.invoices);

    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const raiseInvoice = async () => {
    setIsClicked(true)
    setIsDataLoaded(true);
    const res = await axios.request({
      method: "POST",
      url: "https://epoch.theflak.in/api/am/publish",
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: invoiceIds,
    });
    if (res.data.done === 1) {
      setTotInvoiceId([]);
      // setApiCallCounter(1);
      setIsDataLoaded(false);
      alert("Invoices raised!");
      setCounter(counter + 1);
    } else {
      setTotInvoiceId([]);
      // setApiCallCounter(1);
      setIsDataLoaded(false);
      alert("Failed!");
      console.log(res)
      setCounter(counter + 1);
    }
    setIsClicked(false)
    // console.log(invoiceIds);
  };

  const downloadInvoice = async (invoiceId: string, name: string, invoiceNo: number, month: number, home: string) => {
    setIsDataLoaded(true);

    if (Capacitor.isNative) {
      const res = await axios.request({
        method: "GET",
        url: "https://epoch.theflak.in/api/am/download-invoice/" + invoiceId,
        headers: {
          "x-access-token": token,
        },
      });

      PDFGenerator.fromData(
        invoice(
          res.data.relativeName,
          res.data.billingAddress,
          res.data.shippingAddress,
          res.data.placeOfSupply,
          res.data.dueDate,
          res.data.preTaxAmount,
          res.data.taxAmount,
          res.data.postTaxAmount,
          res.data.totalTax,
          res.data.totalAmountText,
          res.data.residentName,
          res.data.monthYear,
          res.data.gstin,
          res.data.invoiceNo,
          res.data.roundOff,
          res.data.roundedOff,
          res.data.homeGSTIN,
          res.data.monthYearNote
        ),
        {
          documentSize: "A4",
          type: "share",
          fileName: "Invoice" + new Date().getTime() + ".pdf",
        }
      );

      setIsDataLoaded(false);
    } else {
      const res = await axios.request({
        method: "GET",
        url:
          "https://epoch.theflak.in/api/am/download-invoice-web/" +
          invoiceId +
          "/" +
          new Date().getTime(),
        headers: {
          "x-access-token": token,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${invoiceNo} / ${name} / ${month}` + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
      setIsDataLoaded(false);
    }
  };

  const generateInvoices = async () => {
    setIsDataLoaded(true);
    let a = new Date();
    let currentMonth = a.getMonth() + 1;
    let currentYear = a.getFullYear();

    const res = await axios.request({
      method: "GET",
      url:
        "https://epoch.theflak.in/api/am/generate-invoices/" +
        currentMonth +
        "/" +
        currentYear,
      headers: {
        "x-access-token": token,
      },
    });

    if (res.data.invalid) {
      setIsDataLoaded(false);
      setInvalidDateAlert(true);
    } else if (!res.data.success) {
      setIsDataLoaded(false);
      setGenerateFailedAlert(true);
    } else if (res.data.success) {
      setIsDataLoaded(false);
      setGeneratedAlert(true);
    }
  };

  const downloadReconceliation = async () => {
    setIsClicked(true)
    const res = await axios.request({
      method: "GET",
      url: "https://epoch.theflak.in/api/am/resident-excel",
      headers: {
        "x-access-token": token,
      },
    });
    if (res) {
      console.log(res);
      jsonToExcel(res?.data, "Reconciliation", true).then((res: any) => {
        writeFile({
          path: "Reconciliation" + new Date().getTime() + ".csv",
          data: res,
          directory: FilesystemDirectory.Documents,
        })
          .then(() => {
            LocalNotifications.schedule({
              notifications: [
                {
                  title: "Your file has been downloaded",
                  body: "Please check Document folder in the files.",
                  id: 1,
                  schedule: { at: new Date(Date.now() + 1000 * 2) },
                  actionTypeId: "",
                  extra: null,
                },
              ],
            });
          })
          .catch(() => {
            LocalNotifications.schedule({
              notifications: [
                {
                  title: "There is some error!",
                  body: "Error occured while downloading the file!",
                  id: 1,
                  schedule: { at: new Date(Date.now() + 1000 * 2) },
                  actionTypeId: "",
                  extra: null,
                },
              ],
            });
          });
      });
    }
    setIsClicked(false)
  };

  const publishAll = async () => {
    setIsDataLoaded(true);
    const res = await axios.request({
      method: "GET",
      url: "https://epoch.theflak.in/api/am/publish-all",
      headers: {
        "x-access-token": token,
      },
    });
    if (res.data.done === 1) {
      setIsDataLoaded(false);
      setPublishAllAlert(true);
    }
    if (res.data.done === 0) {
      setIsDataLoaded(false);
      setPublishAllAlertError(true);
    }
  };

  checkIfUserDoesntExist();
  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customRaiseInvoice__am"
              : "customRaiseInvoice__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Raise Invoice </IonTitle>
          </IonToolbar>
          <IonItem lines="none">
            <button
                disabled={isClicked}
                onClick={() => {
                  downloadReconceliation();
                }}
                className="generateInvoice"
            >
              Download Reconciliation Sheet - 1
            </button>
            <button
              onClick={() => {
                setConfirmAlert(true);
              }}
              className="generateInvoice"
            >
              Generate Invoices - 2
            </button>
            <button
              onClick={() => {
                setIsPublishForEveryone(true);
                setConfirmAlert(true);
              }}
              className="generateInvoice"
            >
              Publish Invoices - 3
            </button>
          </IonItem>
          <IonItem lines="none" className="selectItem_am">
            <IonLabel position="stacked">Select Home</IonLabel>
            <IonSelect
              placeholder="Select One"
              value={centerName}
              onIonChange={(e: any) => {
                setCenterName(e.detail.value);
                setIsDataLoaded(true);
                // setApiCallCounter(1);
              }}
            >
              {home?.map((home: string, key: number) => {
                return (
                  <IonSelectOption key={key} value={home}>
                    {home}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <IonItem className="selectItem_am">
            <IonDatetime
              value={date}
              color="danger"
              placeholder="Select Month and Year"
              displayFormat="MMMM, YYYY"
              max={new Date(
                new Date().setMonth(new Date().getMonth())
              ).toISOString()}
              onIonChange={(e: any) => {
                setDate(moment(e.detail.value).format("MMM, YYYY"));
                setIsDataLoaded(true);
                setMonth(moment(e.detail.value).format("M"));
                setYear(moment(e.detail.value).format("YYYY"));
                setDateChangeCounter(dateChangeCounter + 1);
              }}
            />
          </IonItem>
          <IonCard>
            {invoices?.length === 0 ? (
              <h4 style={{ textAlign: "center" }}>No Data to show</h4>
            ) : (
              invoices?.map((invoice: any, key: any) => {
                return (
                  <IonItem key={key} lines="none">
                    <IonGrid>
                      <IonRow>
                        <IonCol
                          size="9"
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <IonCheckbox
                            disabled={invoice?.status ? true : false}
                            style={{ marginRight: "7%" }}
                            // checked={checked}
                            onIonChange={(e: any) => {
                              if (e.detail.checked) {
                                setTotInvoiceId((totInvoiceId: any) => [
                                  ...totInvoiceId,
                                  invoice.invoiceId,
                                ]);
                              } else {
                                setTotInvoiceId(
                                  totInvoiceId.filter(
                                    (data: any) => data !== invoice.invoiceId
                                  )
                                );
                              }
                            }}
                          />
                          {/* <IonIcon style={{fontSize: 36}} icon={personCircleOutline}></IonIcon> */}
                          <div className="resident-card">
                            <h3> {invoice?.name} </h3>
                            {/* <p> {invoice?.resident_id} </p> */}
                            <h3 style={{ color: "#333" }}>
                              {invoice?.status ? "Published" : "Unpublished"}
                            </h3>
                          </div>
                        </IonCol>

                        {invoice?.status ? (
                          <IonCol size="3" className="customResidentView">
                            <div className="resident-card">
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  // setResidentDetails(resident);
                                }}
                              >
                                <button
                                  onClick={() =>
                                    downloadInvoice(invoice?.invoiceId, invoice?.name, invoice?.invoiceNo, invoice?.month, invoice?.home)
                                  }
                                >
                                  <IonIcon
                                    style={{ fontSize: 36 }}
                                    icon={arrowDownCircleOutline}
                                  ></IonIcon>
                                  <p>Download</p>
                                </button>
                              </div>
                            </div>
                          </IonCol>
                        ) : (
                          <IonCol size="3" className="customResidentView">
                            <div
                              className="resident-card"
                              style={{ marginTop: 10 }}
                            >
                              <div
                                className="view-details-right"
                                onClick={() => {
                                  // setResidentDetails(resident);
                                }}
                              >
                                <button
                                  onClick={() =>
                                    downloadInvoice(invoice?.invoiceId, invoice?.name, invoice?.invoiceNo, invoice?.month, invoice?.home)
                                  }
                                >
                                  <IonIcon
                                    style={{ fontSize: 36 }}
                                    icon={helpCircleOutline}
                                  ></IonIcon>
                                  <p>Preview</p>
                                </button>
                              </div>
                            </div>
                          </IonCol>
                        )}
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                );
              })
            )}
            {totInvoiceId.length > 0 ? (
              <div
                className="viewMoreBtn__am"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <button
                  disabled={isClicked}
                  onClick={() => {
                    raiseInvoice();
                  }}
                >
                  Raise Invoice
                </button>
              </div>
            ) : (
              <></>
            )}
            {/* {invoices?.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {totalPages > 1 ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={totalPages}
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
      <IonAlert
        isOpen={generatedAlert}
        onDidDismiss={() => setGeneratedAlert(false)}
        cssClass="my-custom-class"
        // header={"Done"}
        // subHeader={'Subtitle'}
        message={
          "Invoices generated for residents whose invoice was not generated earlier."
        }
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={generateFailedAlert}
        onDidDismiss={() => setGenerateFailedAlert(false)}
        cssClass="my-custom-class"
        // header={"Failed"}
        // subHeader={'Subtitle'}
        message={"Invoices for the current month have already been generated."}
        buttons={["Dismiss"]}
      />
      <IonAlert
        isOpen={invalidDateAlert}
        onDidDismiss={() => setInvalidDateAlert(false)}
        cssClass="my-custom-class"
        header={"Failed"}
        // subHeader={'Subtitle'}
        message={"Date Incorrect"}
        buttons={["Dismiss"]}
      />
      <IonAlert
        isOpen={confirmAlert}
        onDidDismiss={() => setConfirmAlert(false)}
        cssClass="my-custom-class"
        // header={"Confirm"}
        message={
          isPublishForEveryone
            ? "Are you sure?"
            : "Generate invoices for the current month? Please confirm."
        }
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setConfirmAlert(false);
              setIsPublishForEveryone(false);
            },
          },
          {
            text: "Confirm",
            handler: () => {
              if (!isPublishForEveryone) {
                generateInvoices();
              } else {
                publishAll();
                setIsPublishForEveryone(false);
              }
            },
          },
        ]}
      />
      <IonAlert
        isOpen={publishAllAlert}
        onDidDismiss={() => setPublishAllAlert(false)}
        cssClass="my-custom-class"
        header={"Invoices published and email sent for all residents."}
        buttons={["Done"]}
      />
      <IonAlert
        isOpen={publishAllAlertError}
        onDidDismiss={() => setPublishAllAlertError(false)}
        cssClass="my-custom-class"
        header={"Invoices not generated, or have already been published."}
        buttons={["Dismiss"]}
      />
    </IonContent>
  );
};
