import React, { useState, useEffect } from "react";
import {
  IonAlert,
  IonContent,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { arrowForwardCircleOutline, documentTextOutline } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import Loading from "../../constants/loading";
import { useHistory } from "react-router-dom";
import { get, set } from "../../services/storage";
import "./ResidentInvoices.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { Pagination } from "../../services/pagination/pagination";
import "../../Animations/Animation.css";
import { useStateValue } from "../../ContextAPI/StateProvider";

export const ResidentInvoices: React.FC = () => {
  const [token, setToken] = useState<any>();
  const [profile, setProfile] = useState<string>();
  const [home, setHome] = useState<any>();
  // const [centerName, setCenterName] = useState(0);
  const [billDetails, setBillDetails] = useState<any>([]);
  const [approvedBills, setApprovedBills] = useState<any>([]);
  const [pendingBills, setPendingBills] = useState<any>([]);
  const [rejectedBills, setRejectedBills] = useState<any>([]);
  const [switchButton, setSwitchButton] = useState<any>("Pending");
  const [noData, setNoData] = useState<any>(
    <h2 className="customNoData">Nothing to Display...</h2>
  );
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const [pendingPages, setPendingPages] = useState<any>();
  const [approvedPages, setApprovedPages] = useState<any>();
  const [rejectedPages, setRejectedPages] = useState<any>();

  const [residents, setResidents] = useState([]);
  // const [resident, setResident] = useState(0);

  const [approvedCount, setApprovedCount] = useState();
  const [pendingCount, setPendingCount] = useState();
  const [rejectedCount, setRejectedCount] = useState();
  const [showAlert, setShowAlert] = useState(false);

  const [
    { residentExpensesHomeFilter, residentExpensesClientFilter },
    dispatch,
  ] = useStateValue();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const handlePages = (updatePage: number) => {
    setIsDataLoaded(true);
    setPage(updatePage);
    getPaginatedResidentInvoices(updatePage - 1);
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/AM/Home");
      });
    }

    getProfileFromLocalStorage();
    if (profile) {
      const profileObject = JSON.parse(profile);
      setHome(profileObject?.centers);
    }
    getTokenFromLocalStorage();

    if (token) {
      getResidentInvoices();
    }
    if (residentExpensesHomeFilter !== "0") {
      getResidents(residentExpensesHomeFilter);
    }
  }, [
    token,
    residentExpensesHomeFilter,
    switchButton,
    residentExpensesClientFilter,
  ]);

  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  const getProfileFromLocalStorage = () => {
    get("profile").then((res) => {
      setProfile(res);
    });
  };

  const getTokenFromLocalStorage = () => {
    get("token").then((res) => {
      setToken(res);
    });
  };

  const getResidentInvoices = async () => {
    const res = await axios.get(
      "/api/am/resident-invoices/" +
        residentExpensesHomeFilter +
        "/0/" +
        residentExpensesClientFilter,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setPendingBills(res?.data?.pending);
    setApprovedBills(res?.data?.approved);
    setRejectedBills(res?.data?.rejected);
    if (switchButton === "Pending") {
      setBillDetails(res?.data?.pending);
    } else if (switchButton === "Approved") {
      setBillDetails(res?.data?.approved);
    } else if (switchButton === "Rejected") {
      setBillDetails(res?.data?.rejected);
    }
    setPendingPages(res?.data?.pending_pages);
    setApprovedPages(res?.data?.approved_pages);
    setRejectedPages(res?.data?.rejected_pages);
    setApprovedCount(res?.data?.approvedCount);
    setPendingCount(res?.data?.pendingCount);
    setRejectedCount(res?.data?.rejectedCount);
    setPage(1);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const getPaginatedResidentInvoices = async (updatePage: Number) => {
    console.log("getPaginatedData");
    const res = await axios.get(
      "/api/am/resident-invoices/" +
        residentExpensesHomeFilter +
        "/" +
        updatePage +
        "/" +
        residentExpensesClientFilter,
      // (switchButton === "Pending" ? pendingCounter : approvedCounter),
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    setDataAccToSwitch(res?.data);
    if (res) {
      setTimeout(() => {
        setIsDataLoaded(false);
      }, 1000);
    }
  };

  const billStatusFunc = (e: any) => {
    if (e.toLowerCase() === "approved") {
      setSwitchButton("Approved");
      setBillDetails(approvedBills);
      setPage(1);
      if (approvedPages === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    } else if (e.toLowerCase() === "pending") {
      setSwitchButton("Pending");
      setBillDetails(pendingBills);
      setPage(1);
      if (pendingPages === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    }
    else if (e.toLowerCase() === "rejected") {
      setSwitchButton("Rejected");
      setBillDetails(rejectedBills);
      setPage(1);
      if (rejectedPages === 0) {
        setNoData(<h2 className="customNoData">Nothing to Display...</h2>);
      } else {
        setNoData("");
      }
    }
  };

  const setBillId = (e: any) => {
    set("billId", e).then(() => {});
  };

  const setUpdatedPendingBills = (res: any) => {
    setPendingBills(res?.pending);
    setBillDetails(res?.pending);
  };

  const setUpdatedApprovedBills = (res: any) => {
    setApprovedBills(res?.approved);
    setBillDetails(res?.approved);
  };
  const setDataAccToSwitch = (res: any) => {
    if (switchButton === "Pending") {
      setUpdatedPendingBills(res);
    } else {
      setUpdatedApprovedBills(res);
    }
  };

  checkIfUserDoesntExist();

  const getResidents = async (home: string) => {
    const res = await axios.get(`/api/am/residents/${home}`, {
      headers: {
        "x-access-token": token,
      },
    });
    setResidents(res?.data?.residents);
  };

  const sendNotification = () => {
    axios.get("/api/am/raise-notification", {
      headers: {
        "x-access-token": token,
      },
    });
    setShowAlert(true);
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customResidentInvoices__am"
              : "customResidentInvoices__am animated"
          }
        >
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Notification sent to residents of all homes."}
            buttons={["Done"]}
          />
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                routerLink="/AM/Home"
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Resident Expenses </IonTitle>
          </IonToolbar>

          <IonToolbar>
            <IonSegment
              color="danger"
              value={switchButton}
              onIonChange={(e: any) => billStatusFunc(e.detail.value)}
            >
              <IonSegmentButton value="Pending">Pending</IonSegmentButton>
              <IonSegmentButton value="Approved">Approved</IonSegmentButton>
              <IonSegmentButton value="Rejected">Rejected</IonSegmentButton>
            </IonSegment>
            <IonItem lines="none">
              <button
                onClick={() => {
                  sendNotification();
                }}
                className="sendNotification"
              >
                Send Notification to all
              </button>
            </IonItem>
          </IonToolbar>
          <div
            className="selectOption-am"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <IonItem lines="none" style={{ flex: 1 }} className="selectHome_am">
              <IonLabel position="stacked">Select Home</IonLabel>
              <IonSelect
                value={residentExpensesHomeFilter}
                placeholder="Select One"
                onIonChange={(e: any) => {
                  dispatch({
                    type: "SET_RESIDENT_EXPENSE_HOME_FILTER",
                    data: e.detail.value,
                  });
                  dispatch({
                    type: "SET_RESIDENT_EXPENSE_RESIDENT_FILTER",
                    data: "0",
                  });

                  setIsDataLoaded(true);
                  getResidents(e.detail.value);
                }}
              >
                {home?.map((home: string, key: number) => {
                  return (
                    <IonSelectOption key={key} value={home}>
                      {home}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>

            {residents.length !== 0 ? (
              <IonItem lines="none" style={{ flex: 1 }}>
                <IonLabel position="stacked">Select Residents</IonLabel>
                <IonSelect
                  value={residentExpensesClientFilter}
                  placeholder="Select One"
                  onIonChange={(e: any) => {
                    dispatch({
                      type: "SET_RESIDENT_EXPENSE_RESIDENT_FILTER",
                      data: e.detail.value,
                    });
                    setIsDataLoaded(true);
                  }}
                >
                  {residents?.map((home: string, key: number) => {
                    return (
                      <IonSelectOption key={key} value={home}>
                        {home}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              </IonItem>
            ) : null}
          </div>
          <p
            className="totalBills_am"
            style={{
              background: "#c12525",
              padding: "2%",
              width: "60%",
              margin: "0 auto",
              marginTop: "2%",
              color: "#fff",
              textAlign: "center",
              borderRadius: 18,
            }}
          >
            Total Bills:{" "}
            {switchButton === "Pending" ? pendingCount : (switchButton === "Approved" ? approvedCount : rejectedCount)}

          </p>

          <IonCard>
            {billDetails?.length === 0
              ? noData
              : billDetails?.map((bill: any, key: any) => {
                  return (
                    <IonItem key={key} lines="none">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="3">
                            <IonIcon
                              style={{ fontSize: 70 }}
                              icon={documentTextOutline}
                            />
                          </IonCol>
                          <IonCol size="6">
                            <div className="resident-card">
                              <h3> {bill?.bill?.name} </h3>
                              <div className="rb-pTag">
                                <p className="">
                                  {" Bill Date: "}
                                  {moment(bill?.bill?.bill_date).format(
                                    "MMM DD,YYYY"
                                  )}{" "}
                                </p>
                                <p className=""> {bill?.bill?.category} </p>{" "}
                                <p className="">
                                  {" Uploaded On: "}
                                  {moment(bill?.bill?.bill_uploaded_on).format(
                                    "MMM DD, YYYY, hh:mm A"
                                  )}{" "}
                                </p>
                                <p className=""> {bill?.bill?.category} </p>
                              </div>
                              <h6>
                                {" "}
                                Bill No: {bill?.bill?.billNo} for {" "} 
                                Rs.{" "}
                                {bill?.bill?.amount
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} 
                              </h6>
                              
                            </div>
                          </IonCol>
                          <IonCol size="3" className="customResidentView">
                            <div className="resident-card">
                              <div
                                className="view-details-right"
                                style={{ marginTop: 7 }}
                                onClick={() => {
                                  set("whichRoute", "FRI").then(() => {});
                                  if (switchButton === "Pending") {
                                    setBillId(bill.bill?._id);
                                    history.push("/AM/BillAction");
                                  } else {
                                    setBillId(bill.bill?._id);
                                    history.push("/AM/ResidentBillDetails");
                                  }
                                }}
                              >
                                <IonIcon
                                  style={{ fontSize: 36 }}
                                  icon={arrowForwardCircleOutline}
                                ></IonIcon>
                                <p>View Details</p>
                              </div>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonItem>
                  );
                })}

            {/* {billDetails?.length >= 10 ? (
            <div className="viewMoreBtn__am">
              {" "}
              <button
                onClick={() => {
                  viewMoreData();
                }}
              >
                View More
              </button>
            </div>
          ) : (
            <></>
          )} */}
            {(pendingPages > 1 && switchButton === "Pending") ||
            (approvedPages > 1 && switchButton === "Approved") ? (
              <div>
                <Pagination
                  page={page}
                  totalPages={
                    switchButton === "Pending" ? pendingPages : approvedPages
                  }
                  handlePagination={handlePages}
                />
              </div>
            ) : (
              <></>
            )}
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
