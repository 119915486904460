import Menu from "../AFm/components/Menu";
import React, { useState, useEffect } from "react";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

/* Theme variables */
import "../theme/variables.css";
import "../Fm/pages/Page.css";

/* Pages variables */
import { Login } from "../Login";
import { QuickLinks } from "../AFm/pages/Home";
import { BillUpload } from "../AFm/pages/BillUpload";
import { BillDetails } from "../AFm/pages/BillDetails";
import { ResidentBills } from "../AFm/pages/ResidentBills";
import { Profile } from "../AFm/pages/Profile";
import { ChangePassword } from "../Auth/changePassword";
import "../Animations/Animation.css";
import { RejectedBills } from "../AFm/pages/RejectedBills";
import { PettyCashSnapshot } from "../AFm/pages/PettyCashSnapshot";


const AFM: React.FC = () => {
  let location = useLocation();
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Switch>
              <Route className="animation" path="/Login" component={Login} />
              <Route
                className="animation"
                path="/ChangePassword"
                component={ChangePassword}
              />
              <Route
                className="animation"
                path="/AFM/Home"
                component={QuickLinks}
              />
              <Route
                className="animation"
                path="/AFM/ResidentBills"
                component={ResidentBills}
              />
              <Route path="/AFM/RejectedBills" component={RejectedBills} />
              <Route path="/AFM/PettyCashSnapshot" component={PettyCashSnapshot} />
              <Route
                className="animation"
                path="/AFM/BillUpload"
                component={BillUpload}
              />
              <Route
                className="animation"
                path="/AFM/BillDetails"
                component={BillDetails}
              />
              <Route
                className="animation"
                path="/AFM/Home"
                component={QuickLinks}
              />
              <Route
                className="animation"
                path="/AFM/Profile"
                component={Profile}
              />
              <Redirect from="/" to="/AFM/Home" />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default AFM;
