import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonItem,
  IonCard,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonItemDivider,
  IonBackButton,
  IonDatetime,
  IonIcon,
} from "@ionic/react";
import { get } from "../../services/storage";
import axios from "../../constants/axios-instance";
import { homeOutline, calendarOutline } from "ionicons/icons";
import "./PettyCashSnapshot.css";

import { Plugins, Capacitor, FilesystemDirectory } from "@capacitor/core";
import { useHistory } from "react-router";
import "../../Animations/Animation.css";
import moment from "moment";
import { useFilesystem } from "@ionic/react-hooks/filesystem";
import { jsonToExcel } from "../../Functions/conversion";

export const PettyCashSnapshot: React.FC = () => {
  const history = useHistory();
  const [token, setToken] = useState<any>();
  const [epochId, setEpochId] = useState<any>();
  const [rName, setRname] = useState<any>();
  const [date, setDate] = useState<any>();
  const [month, setMonth] = useState<any>();
  const [year, setYear] = useState<any>();
  const [monthMMM, setMonthMMM] = useState<any>();
  const [serverData, setServerData] = useState<any>();
  const currentDate = new Date();

  const { writeFile } = useFilesystem();
  const { LocalNotifications, Share } = Plugins;
  useEffect(() => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e) => {
        history.replace("/CL/Home");
      });
    }
    get("token").then((res) => {
      setToken(res);
    });
    get("profile").then((res) => {
      let id = JSON.parse(res);
      setEpochId(id.epoch_id);
      setRname(id.name);
    });
  }, []);

  const downloadBills = async () => {
    if (!year || !month) {
      alert("Please select all the fields!");
    } else {
      const res = await axios.request({
        method: "GET",
        url:
          "https://epoch.theflak.in/api/client/petty-snapshot/" +
          year +
          "/" +
          month,
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (res?.data?.failed) {
        alert("Not enough bills for chosen duration");
      } else {
        jsonToExcel(
          res?.data,
          `Petty-Cash Ledger of ${rName} for ${monthMMM} ${year}`,
          true
        ).then(async (res: any) => {
          if (Capacitor.getPlatform() === "ios") {
            const result = await writeFile({
              path:
                `Petty-Cash Ledger of ${rName} for ${monthMMM} ${year}` +
                new Date().getTime() +
                ".csv",
              data: res,
              directory: FilesystemDirectory.Documents,
            });
            await Share.share({
              title:
                `Petty-Cash Ledger of ${rName} for ${monthMMM} ${year}` +
                new Date().getTime() +
                ".csv",
              url: result.uri,
              dialogTitle: "Save file",
            });
          } else {
            const result = await writeFile({
              path:
                `Download/Petty-Cash Ledger of ${rName} for ${monthMMM} ${year}` +
                new Date().getTime() +
                ".csv",
              data: res,
              directory: FilesystemDirectory.ExternalStorage,
            })
              .then(() => {
                LocalNotifications.schedule({
                  notifications: [
                    {
                      title: "Your file has been downloaded",
                      body: "Please check Downloads folder in the files.",
                      id: 1,
                      schedule: { at: new Date(Date.now() + 1000 * 2) },
                      actionTypeId: "",
                      extra: null,
                    },
                  ],
                });
                alert("File saved in download folder");
              })
              .catch(() => {
                LocalNotifications.schedule({
                  notifications: [
                    {
                      title: "There is some error!",
                      body: "Error occured while downloading the file!",
                      id: 1,
                      schedule: { at: new Date(Date.now() + 1000 * 2) },
                      actionTypeId: "",
                      extra: null,
                    },
                  ],
                });
              });
          }
        });
      }
    }
  };

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customPettyCashSnapshot__cl animated"
    >
      <IonToolbar className="customHeading">
        <IonButtons slot="start">
          <IonItem
            className="customBackBtn"
            detail={false}
            routerLink="/CL/Home"
            lines="none"
          >
            <IonBackButton defaultHref="" text=""></IonBackButton>
          </IonItem>
        </IonButtons>
        <IonTitle> Petty Cash Ledger </IonTitle>
      </IonToolbar>
      <IonCard>
        <IonItem lines="none">
          <IonIcon
            className="customPettyCashSnapshot__icon"
            icon={calendarOutline}
          />
          <IonDatetime
            max={currentDate.toISOString()}
            placeholder="Select Month and Year"
            onIonChange={(e: any) => {
              setDate(e.detail.value);
              setMonth(moment(e.detail.value).format("M"));
              setMonthMMM(moment(e.detail.value).format("MMM"));
              setYear(moment(e.detail.value).format("YYYY"));
            }}
            displayFormat="MM YYYY"
            value={date}
          ></IonDatetime>
        </IonItem>
        <div className="customPettyCashSnapshot__btn">
          <button onClick={downloadBills}>Download</button>
        </div>
      </IonCard>
      {/* <p style={{ marginTop: "1.5%" }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            "https://epoch.theflak.in/static/img/ledgers/" + epochId + ".xlsx"
          }
        >
          To download the ledger for March 2021, Click Here.
        </a>
      </p> */}
    </IonContent>
  );
};
