import React, { useState } from "react";
import { IonCard, IonImg, IonLoading, IonModal } from "@ionic/react";
import gifLoader from "../assets/appLoader.gif";
import "./constant.css";

const Loading: React.FC = () => {
  const showLoading = true;
  return (
    // <IonLoading
    //   cssClass="my-custom-class"
    //   isOpen={showLoading}
    //   // onDidDismiss={() => setShowLoading(false)}
    //   message={"Please wait..."}
    // />
    <IonCard
      className="customLoader"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        position: "fixed",
        zIndex: 99999,
        top: "0%",
        // left: "-3%",
        borderRadius: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <IonImg
        src={gifLoader}
        alt=""
        style={{
          width: 60,
          height: 60,
          margin: "0 auto",

          flex: 1,
        }}
      />
    </IonCard>
  );
};

export default Loading;
