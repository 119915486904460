import React, { useState, useEffect } from "react";
import { IonContent, IonImg, IonItem } from "@ionic/react";
import { useHistory } from "react-router-dom";
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import { Login } from "../../Login";
import { trashBin } from "ionicons/icons";
import axios from "../../constants/axios-instance";
import { get, set } from "../../services/storage";
import Loading from "../../constants/loading";
import "./ResidentBillDetails.css";
import { Plugins, Capacitor } from "@capacitor/core";
import moment from "moment";
import { PhotoViewer } from "@ionic-native/photo-viewer";
import "../../Animations/Animation.css";

export const ResidentBillDetails: React.FC = () => {
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [billDetails, setBillDetails] = useState<any>([]);
  const [residentId, setResidentId] = useState<any>();
  const [billId, setBillId] = useState<any>();
  const [billImg, setBillImg] = useState<any>();
  const [whichRoute, setWhichRoute] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState<any>(true);
  const imgHeight = 300;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const history = useHistory();

  useEffect(() => {
    get("profile").then((res) => {
      setProfile(res);
    });
    get("token").then((res) => {
      setToken(res);
    });
    get("resident_id").then((res) => {
      setResidentId(res);
    });
    get("billId").then((res) => {
      setBillId(res);
    });
    get("whichRoute").then((res) => {
      setWhichRoute(res);
      if (Capacitor.isNative) {
        Plugins.App.addListener("backButton", (e) => {
          if (res === "FFI") {
            history.replace("/AM/FacilityInvoices");
          } else if (res === "FRI") {
            history.replace("/AM/ResidentInvoices");
          } else if (res === "FRD") {
            history.replace("/AM/ResidentDetails");
          } else if (res === "FRB") {
            history.replace("/AM/RejectedBills");
          }
        });
      }
    });

    const getData = async () => {
      const res = await axios.get("/api/am/bill/" + billId, {
        headers: {
          "x-access-token": token,
        },
      });
      setBillDetails(res.data);
      setBillImg(res?.data?.image_url);
      if (res) {
        setTimeout(() => {
          setIsDataLoaded(false);
        }, 1000);
      }
    };
    if (token && billId) {
      getData();
    }
  }, [token, residentId, billId, profile]);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const goBack = () => {
    if (whichRoute === "FFI") {
      history.replace("/Am/FacilityInvoices");
    } else if (whichRoute === "FRI") {
      history.replace("/Am/ResidentInvoices");
    } else if (whichRoute === "FRD") {
      history.replace("/Am/ResidentDetails");
    } else if (whichRoute === "FRB") {
      history.replace("/Am/RejectedBills");
    }
  };
  const checkIfUserDoesntExist = () => {
    get("profile").then((res) => {
      if (!res) {
        history.replace("/Login");
      }
    });
  };

  checkIfUserDoesntExist();

  const chkImg = () => {
    var img = document.getElementById("billDetails__am")?.offsetHeight;
    console.log(img);
    if (img === imgHeight) {
      setIsImgFullyLoaded(false);
    }
  };

  return (
    <IonContent>
      {isDataLoaded ? (
        <Loading />
      ) : (
        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {}}
          onIonScroll={() => {}}
          onIonScrollEnd={() => {}}
          className={
            isDataLoaded
              ? "customBillDetails__am"
              : "customBillDetails__am animated"
          }
        >
          <IonToolbar className="customHeading">
            <IonButtons slot="start">
              <IonItem
                className="customBackBtn"
                detail={false}
                onClick={goBack}
                lines="none"
              >
                <IonBackButton defaultHref="" text=""></IonBackButton>
              </IonItem>
            </IonButtons>
            <IonTitle> Bill Details </IonTitle>
          </IonToolbar>

          <IonCard>
            <IonGrid>
              {/* <IonRow>
                <a href={billImg} target="_blank" rel="noopener noreferrer">
                  <img
                    id="billDetails__am"
                    className={
                      isImgFullyLoaded
                        ? "dontShowImg customImg"
                        : "showImg customImg fadeIn"
                    }
                    src={billImg}
                    onLoad={chkImg}
                    alt="img"
                  />
                </a>
              </IonRow> */}
              <IonRow>
                <IonSlides pager={true} options={slideOpts}>
                  {billImg?.reverse().map((img: string, key: any) => {
                    return (
                      <IonSlide key={key}>
                        <img
                          onClick={() => {
                            PhotoViewer.show("https://epoch.theflak.in/" + img);
                          }}
                          id="billDetails__am"
                          // className={
                          //   isImgFullyLoaded
                          //     ? "dontShowImg customImg"
                          //     : "showImg customImg fadeIn"
                          // }
                          src={"https://epoch.theflak.in/" + img}
                          // onLoad={chkImg}
                          alt="img"
                          className="customImg"
                        />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
              </IonRow>

              <IonRow>
                <IonCol size="7">
                  <div className="resident-card">
                    <h3> {billDetails?.name} </h3>
                    <p> {billDetails?.center} </p>
                  </div>
                </IonCol>
                <IonCol size="5">
                  <div className="resident-card">
                    <div className="bill-details-right">
                      <p className="amountTag" id="billdetail-balance">
                        {" "}
                        Rs.{" "}
                        {billDetails?.amount
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card display-in-line">
                    <div>
                      <p style={{ fontSize: 14 }}>Date</p>
                      <h6
                        style={{ color: "#333333", fontSize: 16 }}
                        id="resident-card-display-in-line"
                      >
                        {moment(billDetails?.bill_date).format("MMM DD,YYYY")}
                      </h6>
                    </div>
                    <div>
                      <p style={{ fontSize: 14 }}>Category</p>
                      <h6 style={{ color: "#333333", fontSize: 16 }}>
                        {" "}
                        {billDetails?.category}{" "}
                      </h6>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <div className="resident-card">
                    <h6> Bill number </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.billNo}
                    </p>
                    { billDetails?.vendor_name && 
                    <>
                    <h6> Vendor </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.vendor_name}
                    </p>
                    </>
                    }
                    <h6> Description </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.description}{" "}
                    </p>
                    <h6> Status </h6>
                    <p id="zeromargin" className="customBillValue">
                      {" "}
                      {billDetails?.status.charAt(0).toUpperCase() +
                        billDetails?.status.slice(1)}{" "}
                    </p>
                  </div>
                  {billDetails.status === "rejected" ? (
                      <div>
                        {" "}
                        <h6> Reason for rejection </h6>
                        <p id="zeromargin" className="customBillValue">
                          {" "}
                          {billDetails?.reason}{" "}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </IonContent>
      )}
    </IonContent>
  );
};
