import {
  IonAlert,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "../constants/axios-instance";
import { get } from "../services/storage";
import "../Animations/Animation.css";
import "./Auth.css";

export const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();

  useEffect(() => {
    get("token").then((res) => {
      setToken(res);
    });
  }, []);

  const chnagePasswordFunction = async () => {
    const data = { password: password };
    if (password === confirmPassword) {
      const res = await axios.post(
        "/login/change-password",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      );
      if (res.data.success) {
        history.push("Login");
      } else {
        setShowAlert(true);
      }
    } else {
      setShowAlert(true);
    }
  };

  return (
    <IonContent className="animated changePassword">
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        header="Password doesn't match!"
        buttons={["Okay"]}
      />
      <IonHeader
        style={{
          background: "#c12525",
          color: "#fff",
          textAlign: "center",
          padding: " 8%",
          fontSize: 19,
        }}
      >
        Change Password
      </IonHeader>
        <div style={{marginTop: "2%"}}>
      <IonItem lines="none" className="inputField_CP">
        <IonInput
          onIonChange={(e: any) => setPassword(e.target.value)}
          style={{ border: "1.5px solid #a91d2a", borderRadius: 5 }}
          type={"password"}
          required={true}
          placeholder="Password"
        />
      </IonItem>
      <IonItem lines="none" className="inputField_CP">
        <IonInput
          onIonChange={(e: any) => setConfirmPassword(e.target.value)}
          style={{ border: "1.5px solid #a91d2a", borderRadius: 5 }}
          type="password"
          required={true}
          placeholder="Confirm Password"
        />
      </IonItem>
      <IonItem lines="none" className="submitBtn_CP">
        <button
          onClick={() => chnagePasswordFunction()}
          style={{
            background: "#c12525",
            color: "#fff",
            textAlign: "center",
            width: "50%",
            padding: "3%",
            margin: "0 auto",
            marginTop: "4%",
            fontSize: 16,
            borderRadius: 5,
          }}
        >
          Submit
        </button>
      </IonItem>
      {/* <p
        style={{
          background: "rgb(169, 29, 42)",
          color: "#fff",
          textAlign: "center",
          width: "60%",
          padding: "2%",
          margin: "0 auto",
          marginTop: "4%",
        }}
        onClick={() => history.push("Login")}
      >
        Go to Login page
      </p> */}
      <p
      className="goBack_CP"
        style={{
          textAlign: "center",
          marginTop: "2%",
          textDecoration: "underline",
        }}
        onClick={() => history.push("Login")}
      >
        Go Back
      </p>
      </div>
    </IonContent>
  );
};
