import React, { useState, useEffect } from "react";
import { set, get } from "./services/storage";
import {
  IonContent,
  IonCard,
  IonToolbar,
  IonButtons,
  IonItem,
  IonMenuButton,
  IonTitle,
  IonInput,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonTextarea,
  IonIcon,
  IonImg,
  useIonViewWillEnter,
  IonAlert,
} from "@ionic/react";
import axios from "./constants/axios-instance";
import { AnyARecord } from "dns";
import { useHistory, Redirect } from "react-router-dom";
import epochImg from "./assets/epoch.png";
import grandpaImg from "./assets/grandpa.png";
import Loading from "./constants/loading";
import "./Login.css";
import { useIonRouter } from "@ionic/react";
import { add } from "ionicons/icons";
import { Plugins, Capacitor } from "@capacitor/core";
import {
  PushNotificationToken,
} from "@capacitor/core";
import "./Animations/Animation.css";

const { PushNotifications } = Plugins;

export const Login: React.FC = () => {
  const history = useHistory();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [token, setToken] = useState<any>();
  const [btnState, setBtnState] = useState<any>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<any>(false);
  const imgHeight = 130;
  const [isImgFullyLoaded, setIsImgFullyLoaded] = useState<any>(true);
  const [showAlert1, setShowAlert1] = useState<boolean>(false);

  const setFirstTimeUser = () => {
    set("isFirstTimeUser", 1).then((res) => {
      console.log(res);
    });
  };

  useEffect(() => {
    if (Capacitor.isNative) {
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          alert("Notifications disabled");
        }
      });
      PushNotifications.addListener(
        "registration",
        async (token: PushNotificationToken) => {
          // alert(token.value);
          setToken(token.value);
        }
      );

      // Some issue with setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
        // alert("Error on registration: " + JSON.stringify(error));
      });
    }
  }, []);

  const LoginSubmit = async (e: any) => {
    setIsDataLoaded(true);
    setBtnDisabled(true);
    e.preventDefault();
    setBtnState(true);

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    if (Capacitor.isNative) {
      // On success storing user_token in localstorage
      // set("notificationToken", token);
      formData.append("notiToken", token);
      const res = await axios.post("/login/varify", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.token) {
        setIsDataLoaded(false);
        setBtnDisabled(false);
        set("profile", JSON.stringify(res.data)).then(() => {
          set("token", res.data.token).then(() => {
            history.replace(res.data.user_type + "/Home");
          });
        });
      } else {
        setBtnDisabled(false);
        setShowAlert1(true);
        history.replace("/login");
      }
    } else {
      const res = await axios.post("/login/varify", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.token) {
        setIsDataLoaded(false);
        setBtnDisabled(false);
        set("profile", JSON.stringify(res.data)).then(() => {
          set("token", res.data.token).then(() => {
            history.replace(res.data.user_type + "/Home");
          });
        });
      } else {
        setBtnDisabled(false);
        // alert("Please login with correct credentials!");
        setShowAlert1(true);

        history.replace("/login");
      }
    }
  };

  const checkForAlreadyLoggedIn = () => {
    get("profile").then((res) => {
      if (res) {
        history.replace("/" + JSON.parse(res).user_type + "/Home");
      }
    });
  };

  checkForAlreadyLoggedIn();
  setFirstTimeUser();

  const chkImg = () => {
    var img1 = document.getElementById("loginImg1")?.offsetHeight;
    var img2 = document.getElementById("loginImg2")?.offsetHeight;
    if (img1 === imgHeight && img2 === 200) {
      setIsImgFullyLoaded(false);
    }
  };

  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      LoginSubmit(e);
    }
  };

  return (
    <IonContent
      scrollEvents={true}
      onIonScrollStart={() => {}}
      onIonScroll={() => {}}
      onIonScrollEnd={() => {}}
      className="customLogin animated"
    >
      <div className="loginWrapper">
        <img
          id="loginImg1"
          className="logoImg fadeIn"
          src={epochImg}
          onLoad={chkImg}
          alt="img"
        />
        <img
          id="loginImg2"
          className="loginBanner fadeIn"
          src={grandpaImg}
          onLoad={chkImg}
          alt="img"
        />
        <IonAlert
          isOpen={showAlert1}
          onDidDismiss={() => setShowAlert1(false)}
          cssClass="my-custom-class"
          header={"Login Error"}
          message={"Please check the ID or password."}
          buttons={["OK"]}
        />
        {/* {isDataLoaded ? <Loading /> : console.log("Logged In")} */}
        {/* {isImgFullyLoaded ? (
        <Loading />
      ) : ( */}
        <IonCard>
          <form>
            <IonItem lines="none">
              <IonLabel position="stacked"></IonLabel>
              <IonInput
                type="text"
                value={username}
                onIonChange={(e: any) => setUserName(e.target.value)}
                required
                placeholder="Login Id"
              ></IonInput>
              <IonLabel position="stacked"></IonLabel>
              <IonInput
                onKeyPress={handleKeypress}
                type="password"
                value={password}
                onIonChange={(e: any) => setPassword(e.target.value)}
                required
                placeholder="Password"
              ></IonInput>
            </IonItem>
            <p
              onClick={() => history.push("ForgetPassword")}
              style={{
                float: "right",
                color: "#a91d2a",
                fontWeight: 700,
                marginRight: "2.5%",
                cursor: "Pointer",
              }}
            >
              Forgot Password?
            </p>
            <div className="loginBtn-wrapper">
              <button
                onClick={LoginSubmit}
                style={{
                  background: "#c12525",
                  color: "#fff",
                  padding: "3%",
                  width: "35%",
                  borderRadius: 28,
                }}
                disabled={btnDisabled}
              >
                {" "}
                Login{" "}
              </button>
            </div>
          </form>
        </IonCard>
        {/* )} */}
      </div>
    </IonContent>
  );
};
